import { NgModule } from '@angular/core';
import Add16 from '@carbon/icons/es/add/16';
import ArrowDown16 from '@carbon/icons/es/arrow--down/16';
import ArrowLeft16 from '@carbon/icons/es/arrow--left/16';
import ArrowRight16 from '@carbon/icons/es/arrow--right/16';
import ArrowRight32 from '@carbon/icons/es/arrow--right/32';
import CheckboxChecked16 from '@carbon/icons/es/checkbox--checked/16';
import CheckboxChecked24 from '@carbon/icons/es/checkbox--checked/24';
import Checkbox16 from '@carbon/icons/es/checkbox/16';
import Checkbox24 from '@carbon/icons/es/checkbox/24';
import CheckmarkFilledWarning20 from '@carbon/icons/es/checkmark--filled--warning/20';
import CheckmarkFilled16 from '@carbon/icons/es/checkmark--filled/16';
import CheckmarkFilled24 from '@carbon/icons/es/checkmark--filled/24';
import CheckmarkFilled32 from '@carbon/icons/es/checkmark--filled/32';
import CheckmarkOutlineWarning16 from '@carbon/icons/es/checkmark--outline--warning/16';
import CheckmarkOutline16 from '@carbon/icons/es/checkmark--outline/16';
import CheckmarkOutline24 from '@carbon/icons/es/checkmark--outline/24';
import Checkmark16 from '@carbon/icons/es/checkmark/16';
import ChevronDown16 from '@carbon/icons/es/chevron--down/16';
import ChevronLeft16 from '@carbon/icons/es/chevron--left/16';
import ChevronRight16 from '@carbon/icons/es/chevron--right/16';
import CircleFilled16 from '@carbon/icons/es/circle--filled/16';
import CircleFilled32 from '@carbon/icons/es/circle--filled/32';
import CircleDash16 from '@carbon/icons/es/circle-dash/16';
import CircleDash32 from '@carbon/icons/es/circle-dash/32';
import Close16 from '@carbon/icons/es/close/16';
import Close20 from '@carbon/icons/es/close/20';
import ConditionWaitPoint16 from '@carbon/icons/es/condition--wait-point/16';
import CopyLink16 from '@carbon/icons/es/copy--link/16';
import DataViewAlt16 from '@carbon/icons/es/data--view--alt/16';
import DocumentAdd16 from '@carbon/icons/es/document--add/16';
import DocumentTasks16 from '@carbon/icons/es/document--tasks/16';
import DocumentTasks20 from '@carbon/icons/es/document--tasks/20';
import Download16 from '@carbon/icons/es/download/16';
import Edit16 from '@carbon/icons/es/edit/16';
import ErrorFilled16 from '@carbon/icons/es/error--filled/16';
import Identification32 from '@carbon/icons/es/identification/32';
import InProgress16 from '@carbon/icons/es/in-progress/16';
import IncompleteWarning16 from '@carbon/icons/es/incomplete--warning/16';
import Incomplete16 from '@carbon/icons/es/incomplete/16';
import InformationFilled16 from '@carbon/icons/es/information--filled/16';
import Information16 from '@carbon/icons/es/information/16';
import Insert16 from '@carbon/icons/es/insert/16';
import Launch16 from '@carbon/icons/es/launch/16';
import Link16 from '@carbon/icons/es/link/16';
import ListChecked16 from '@carbon/icons/es/list--checked/16';
import Locked16 from '@carbon/icons/es/locked/16';
import Locked24 from '@carbon/icons/es/locked/24';
import Locked32 from '@carbon/icons/es/locked/32';
import Logout16 from '@carbon/icons/es/logout/16';
import MisuseOutline16 from '@carbon/icons/es/misuse--outline/16';
import Misuse20 from '@carbon/icons/es/misuse/20';
import OverflowMenuVertical16 from '@carbon/icons/es/overflow-menu--vertical/16';
import Pending16 from '@carbon/icons/es/pending/16';
import Queued16 from '@carbon/icons/es/queued/16';
import RecentlyViewed16 from '@carbon/icons/es/recently-viewed/16';
import Reset16 from '@carbon/icons/es/reset/16';
import Save16 from '@carbon/icons/es/save/16';
import SearchLocate16 from '@carbon/icons/es/search--locate/16';
import SendAlt16 from '@carbon/icons/es/send--alt/16';
import SendAlt20 from '@carbon/icons/es/send--alt/20';
import Settings16 from '@carbon/icons/es/settings/16';
import StopFilledAlt16 from '@carbon/icons/es/stop--filled--alt/16';
import TaskView16 from '@carbon/icons/es/task--view/16';
import TrashCan16 from '@carbon/icons/es/trash-can/16';
import TriangleSolid16 from '@carbon/icons/es/triangle--solid/16';
import Undo16 from '@carbon/icons/es/undo/16';
import Unlink16 from '@carbon/icons/es/unlink/16';
import Unlocked16 from '@carbon/icons/es/unlocked/16';
import UserAdmin16 from '@carbon/icons/es/user--admin/16';
import UserFollow16 from '@carbon/icons/es/user--follow/16';
import UserFollow20 from '@carbon/icons/es/user--follow/20';
import UserProfile16 from '@carbon/icons/es/user--profile/16';
import UserProfile20 from '@carbon/icons/es/user--profile/20';
import ViewOff16 from '@carbon/icons/es/view--off/16';
import WarningAltFilled16 from '@carbon/icons/es/warning--alt--filled/16';
import WarningFilled16 from '@carbon/icons/es/warning--filled/16';
import WarningHexFilled16 from '@carbon/icons/es/warning--hex--filled/16';
import WarningSquareFilled16 from '@carbon/icons/es/warning-square--filled/16';
import { IconModule, IconService } from 'carbon-components-angular';

@NgModule({
  exports: [IconModule],
  imports: [IconModule],
})
export class IconsModule {
  constructor(protected iconService: IconService) {
    iconService.registerAll([
      Add16,
      ArrowDown16,
      ArrowLeft16,
      ArrowRight16,
      ArrowRight32,
      CopyLink16,
      Checkbox16,
      Checkbox24,
      CheckboxChecked16,
      CheckboxChecked24,
      Checkmark16,
      CheckmarkFilled16,
      CheckmarkFilled24,
      CheckmarkFilled32,
      CheckmarkFilledWarning20,
      CheckmarkOutline16,
      CheckmarkOutline24,
      CheckmarkOutlineWarning16,
      ChevronDown16,
      ChevronLeft16,
      ChevronRight16,
      Close16,
      Close20,
      CircleDash16,
      CircleDash32,
      CircleFilled16,
      CircleFilled32,
      ConditionWaitPoint16,
      DataViewAlt16,
      DocumentAdd16,
      DocumentTasks16,
      DocumentTasks20,
      Download16,
      Edit16,
      ErrorFilled16,
      Identification32,
      Incomplete16,
      IncompleteWarning16,
      Information16,
      InformationFilled16,
      InProgress16,
      Insert16,
      Launch16,
      Link16,
      ListChecked16,
      Locked16,
      Locked24,
      Locked32,
      Logout16,
      Misuse20,
      MisuseOutline16,
      OverflowMenuVertical16,
      Pending16,
      Queued16,
      RecentlyViewed16,
      Reset16,
      Save16,
      SearchLocate16,
      SendAlt16,
      SendAlt20,
      Settings16,
      StopFilledAlt16,
      TaskView16,
      TrashCan16,
      TriangleSolid16,
      Unlink16,
      Unlocked16,
      Undo16,
      UserAdmin16,
      UserFollow16,
      UserFollow20,
      UserProfile16,
      UserProfile20,
      ViewOff16,
      WarningAltFilled16,
      WarningFilled16,
      WarningHexFilled16,
      WarningSquareFilled16,
    ]);
  }
}
