<div class="modal-content border-0">
  <div class="modal-header border-0 pb-0">
    <h5 class="modal-title h4">Project settings</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="closeModal()"
    ></button>
  </div>

  <div class="modal-body pt-4">
    <form [formGroup]="form">
      <div class="mb-4">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="shouldCcOnRespondentEmails"
            formControlName="shouldCcOnRespondentEmails"
          />
          <label class="form-check-label" for="shouldCcOnRespondentEmails">
            <div class="mb-1">CC me on all respondent emails</div>
            <div class="text-muted small">
              You will be added as a default selection to the CC list for all
              respondent notifications — including distribution and change
              notifications after distribution.
            </div>
          </label>
        </div>
      </div>

      <div>
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="shouldNotifyOnRespondentSigning"
            formControlName="shouldNotifyOnRespondentSigning"
          />
          <label class="form-check-label" for="shouldNotifyOnRespondentSigning">
            <div class="mb-1">Notify me when each questionnaire is signed</div>
            <div class="text-muted small">
              You will receive an email notification for each respondent
              questionnaire on completion and signing.
            </div>
          </label>
        </div>
      </div>
    </form>
  </div>
</div>
