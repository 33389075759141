import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, EMPTY, take } from 'rxjs';
import { Resource } from 'src/app/models/interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ResourcesService {
  apiUrl = environment.appLeveragedApiEndpoints.apiUrl;

  constructor(private httpClient: HttpClient) {}

  applyExhibits(
    uploadId: string,
    respondentIds: string[],
    questionVariableName: string,
    questionPrompt?: string,
    pageName?: string,
    answerLabel?: string,
  ) {
    return this.httpClient
      .post(
        `${this.apiUrl}/Resource/ApplyExhibitToRespondents`,
        {
          answerLabel,
          pageName,
          questionPrompt,
          questionVariableName,
          respondentIds,
          uploadId,
        },
        { responseType: 'text' },
      )
      .pipe(take(1));
  }

  downloadResource(uploadId: string) {
    return this.httpClient
      .get(`${this.apiUrl}/Resource/Download/${uploadId}`, {
        responseType: 'blob',
      })
      .pipe(
        take(1),
        catchError((error) => {
          console.error(error);
          return EMPTY;
        }),
      );
  }

  getProjectResources(projectId: string) {
    return this.httpClient
      .get<Resource[]>(`${this.apiUrl}/Resource/List/${projectId}`)
      .pipe(take(1));
  }

  postResource(formData: FormData) {
    return this.httpClient
      .post(`${this.apiUrl}/Resource`, formData, { responseType: 'text' })
      .pipe(
        take(1),
        catchError((error) => {
          console.error(error);
          return EMPTY;
        }),
      );
  }
}
