@for (subfield of field.fieldGroup; track field; let i = $index) {
  <div [class.ms-lg-n3]="getGroupTitleParts(repeatTitles[i])?.groupNumber">
    <div
      class="position-relative ps-lg-5 pt-lg-3"
      [class.border-top]="getGroupTitleParts(repeatTitles[i])?.groupNumber"
      [class.d-none]="isRepeatRowHidden(subfield.fieldGroup)"
    >
      @if (getGroupTitleParts(repeatTitles[i]); as titleParts) {
        @if (showParagraphNumbering && titleParts.groupNumber) {
          <h6 class="position-absolute text-muted top-0 start-0 mt-3">
            {{ titleParts.groupNumber }}
          </h6>
        } @else if (titleParts.groupNumber) {
          <h6 class="mt-3">
            {{ titleParts.groupNumber }}
          </h6>
        }
        @if (titleParts.groupTitle) {
          <h6
            class="mb-3 ms-lg-3"
            style="max-width: 80ch"
            [innerHTML]="titleParts.groupTitle"
          ></h6>
        }
      }

      <div
        [class.ms-lg-3]="getGroupTitleParts(repeatTitles[i])?.groupNumber"
        [class.pt-lg-0]="getGroupTitleParts(repeatTitles[i])?.groupNumber"
        [ngStyle]="{
          paddingTop: getGroupTitleParts(repeatTitles[i])?.groupNumber
            ? '3rem'
            : '',
        }"
      >
        <formly-field [field]="subfield"></formly-field>
      </div>
    </div>
  </div>
}
