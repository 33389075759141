@if (showPreviousValue) {
  <div class="mb-3" style="white-space: break-spaces">
    <del class="text-muted">
      <div [innerHTML]="previousValue"></div>
    </del>
  </div>
}

@if (showCurrentValue) {
  <div style="white-space: break-spaces">
    @if (useIcon && currentValue === true) {
      <svg
        cdsIcon="checkbox--checked"
        class="text-muted"
        fill="currentColor"
        size="24"
      ></svg>
    } @else if (useIcon) {
      <svg
        cdsIcon="checkbox"
        class="text-muted"
        fill="currentColor"
        size="24"
      ></svg>
    } @else {
      <div [innerHTML]="currentValue"></div>
    }
  </div>
}
