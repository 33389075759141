import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { IconsModule } from 'src/app/core/icons.module';
import { Modal } from 'src/app/models/enums';

@Component({
  selector: 'app-confirm-save-changes',
  standalone: true,
  imports: [IconsModule],
  templateUrl: './confirm-save-changes.component.html',
})
export class ConfirmSaveChangesComponent {
  @Input() apply = () => {};
  @Input() discard = () => {};

  constructor(private bsModalService: BsModalService) {}

  closeModal(): void {
    this.bsModalService.hide(Modal.ConfirmSaveChanges);
  }
}
