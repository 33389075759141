import { FormControl } from '@angular/forms';
import { FieldTypeConfig } from '@ngx-formly/core';
import { RadioComponent } from 'src/app/features/questionnaire';
import { QuestionnaireVariable } from 'src/app/models/interfaces';
import { evalExpression } from '../contract-express.functions';

export function getRadioFieldConfig({
  datatype,
  layout,
  otheroption,
  otheroptiontext,
  prescribedoptions,
  repeatPrompt,
  unknownoptiontext,
}: QuestionnaireVariable): Partial<FieldTypeConfig> {
  const formCheck = layout === 'Horizontal' ? 'inline' : 'default';
  const labels = evalExpression<string[]>(prescribedoptions || '') || [];
  const options = labels.map((label, index) => {
    const value = datatype === 'Boolean' ? !index : label;
    return { label, value };
  });
  if (unknownoptiontext) {
    options.unshift({ label: unknownoptiontext, value: null } as any);
  }
  const otherOptionText = otheroption ? otheroptiontext || 'Other' : undefined;
  return {
    type: RadioComponent,
    modelOptions: { updateOn: 'change' },
    props: {
      formCheck,
      options,
      otherOptionText,
      repeatPrompt,
      required: false,
    },
    validators: {
      // Custom "required" validator to account for known empty answers
      isRequired: {
        expression: (control: FormControl) => {
          const hasAnswer = control.value !== undefined;
          return hasAnswer;
        },
      },
    },
  };
}
