import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-glossary-term-modal',
  standalone: true,
  templateUrl: './glossary-term-modal.component.html',
  styles: [':host { display: flex; flex-direction: column; overflow: auto; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlossaryTermModalComponent {
  @Input() description = '';
  @Input() term = '';

  constructor(private bsModalRef: BsModalRef) {}

  close(): void {
    this.bsModalRef.hide();
  }
}
