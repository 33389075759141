import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FieldType, FormlyFieldProps } from '@ngx-formly/bootstrap/form-field';
import { FieldTypeConfig } from '@ngx-formly/core';
import { Subject, takeUntil } from 'rxjs';
import { DropdownOption } from 'src/app/models/interfaces';

interface RadioProps extends FormlyFieldProps {
  formCheck?: 'default' | 'inline';
}

@Component({
  selector: 'app-formly-field-radio',
  templateUrl: './radio.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent
  extends FieldType<FieldTypeConfig<RadioProps>>
  implements OnInit, OnDestroy
{
  override defaultOptions = {
    props: {
      formCheck: 'default' as const,
    },
  };
  destroyed$ = new Subject<boolean>();
  otherOptionForm = new FormGroup({
    toggle: new FormControl<boolean>(false, {
      nonNullable: true,
    }),
    text: new FormControl<string>('', {
      nonNullable: true,
      updateOn: 'blur',
    }),
  });

  get disabledControl() {
    return new FormControl({ value: this.formControl.value, disabled: true });
  }

  get optionValues(): string[] {
    return (this.props.options as DropdownOption[]).map(({ value }) => value);
  }

  get otherOptionLabel(): string {
    return (this.props as any)['otherOptionText'];
  }

  get otherOptionTextControl() {
    return this.otherOptionForm.controls.text;
  }

  get otherOptionToggleControl() {
    return this.otherOptionForm.controls.toggle;
  }

  get repeatPrompt() {
    // If this radio field is part of a form array and it's parent has a "repeatTitles" prop,
    // and it's the only field in the repeat group, override its prompt atribute with a repeatTitle.
    const parent = this.field?.parent;
    if (
      parent?.parent?.props &&
      parent.parent.props['repeatTitles'] &&
      (parent.parent.fieldArray as any)?.fieldGroup?.length === 1
    ) {
      const i = Number(parent.key);
      return parent.parent.props['repeatTitles'][i];
    }
    return '';
  }

  ngOnInit(): void {
    // if there is an extra value that is outside
    // of the current options, turn other option toggle on
    const formValue = this.formControl.value as string;
    if (
      formValue &&
      !this.optionValues.includes(formValue) &&
      !this.otherOptionToggleControl.value
    ) {
      this.otherOptionToggleControl.setValue(true);
      this.otherOptionTextControl.setValue(formValue);
    }

    // Listen to main control value changes.
    this.formControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (
          this.otherOptionToggleControl.value &&
          this.optionValues.includes(value)
        ) {
          this.otherOptionToggleControl.setValue(false);
        }
        this.formControl.markAsTouched();
      });

    // Listen to other option toggle value changes.
    this.otherOptionToggleControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((enabled) => {
        if (enabled) {
          this.formControl.setValue('');
        } else {
          this.otherOptionTextControl.reset('', { emitEvent: false });
        }
      });

    // Listen to other option text value changes.
    this.otherOptionTextControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((text) => {
        this.formControl.markAsDirty();
        this.formControl.patchValue(text);
        this.formControl.markAsTouched();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
