import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { map, takeUntil } from 'rxjs';
import { QuestionnaireService } from 'src/app/core/services';
import { REPEAT_GROUP_SUFFIX } from 'src/app/core/services/contract-express/contract-express.service';
import { Modal } from 'src/app/models/enums';
import { RepeatFormComponent } from '../repeat-form/repeat-form.component';

@Component({
  selector: 'app-repeat-group',
  templateUrl: './repeat-group.component.html',
})
export class RepeatGroupComponent extends FieldArrayType {
  requiredFieldLabel$ = this.route.data.pipe(
    map((data) =>
      data['allowIncompletePage']
        ? 'This answer is missing'
        : 'This field is required',
    ),
  );
  showError$ = this.questionnaireService.showErrors$;

  get compactWidth(): boolean {
    return this.titles.length < 3;
  }

  get fieldArray() {
    return this.field.fieldArray as FormlyFieldConfig;
  }

  get fieldKey() {
    return (this.field.key as string).replace(REPEAT_GROUP_SUFFIX, '');
  }

  get hideGroup() {
    return this.field.fieldGroup?.every(
      (field) => field.hide || field.fieldGroup?.every(({ hide }) => hide),
    );
  }

  get layout(): 'collapsed' | 'expanded' | 'griddown' | 'readonly' {
    return this.field.props['layout'];
  }

  get repeatTitles(): string[] {
    return this.props['repeatTitles'] || [];
  }

  get preTitles(): string[] {
    return this.props['preTitles'] || [];
  }

  get showButtons(): boolean {
    return this.props['includebuttons'] ?? true;
  }

  get titles(): string[] {
    return this.props['titles'] || [];
  }

  constructor(
    private modalService: BsModalService,
    private questionnaireService: QuestionnaireService,
    private route: ActivatedRoute,
  ) {
    super();
  }

  addGroup(): void {
    // Certain CE fields require numeric counter of repeater groups
    // to be updated in real time for usage property to work correctly.
    // Adding a "band-aid" here for conditional fields to work.
    if (
      this.field.parent?.model &&
      !this.field.parent.model.hasOwnProperty(this.fieldKey)
    ) {
      this.field.parent.model[this.fieldKey] = 1;
    }

    this.add();
  }

  keepOrder(): number {
    return 0;
  }

  openRepeatForm(index?: number): void {
    const fields =
      index !== undefined && this.field.fieldGroup
        ? this.field.fieldGroup[index].fieldGroup
        : this.fieldArray.fieldGroup;

    const model =
      index !== undefined
        ? { ...this.formControl.at(index).getRawValue() }
        : {};

    const modalRef = this.modalService.show(RepeatFormComponent, {
      class: 'modal-md modal-dialog-centered',
      id: Modal.RepeaterForm,
      initialState: {
        fields,
        form: new FormGroup({}),
        model,
        operation: index === undefined ? 'add' : 'update',
      },
    });

    modalRef.content?.onSave$
      .pipe(takeUntil(modalRef.content.destroyed$))
      .subscribe((value) => {
        this.formControl.markAsDirty();
        if (index !== undefined) {
          this.remove(index);
          this.add(index, value);
          // We need to notify formly about model changes
          // hence resetModel call below.
          // if (this.options.resetModel) {
          //   this.options.resetModel(this.formControl.root.getRawValue());
          // }
        } else {
          this.add(undefined, value);
        }
      });
  }

  removeGroup(index: number): void {
    this.formControl.markAsTouched();
    if (this.formControl.length === 1) {
      Object.keys(this.formControl.at(0).getRawValue()).forEach((key) => {
        this.formControl.at(0).get(key)?.setValue('', { emitEvent: false });
      });
      this.formControl.markAsDirty();
      this.formControl.updateValueAndValidity();
    }
    this.remove(index, { markAsDirty: true });
    this.formControl.updateValueAndValidity();
  }
}
