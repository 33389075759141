import { AbstractControl } from '@angular/forms';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { QuestionnaireFormModel } from 'src/app/models/aliases';
import { QuestionnaireVariable } from 'src/app/models/interfaces';
import { evalExpression } from '../contract-express.functions';

export function getCheckboxFieldConfig(
  {
    layout,
    name,
    prefillvalueexpression,
    prescribedoptions,
    prompt,
    promptexpression,
  }: QuestionnaireVariable,
  model?: QuestionnaireFormModel,
): Partial<FieldTypeConfig> {
  const defaultValue = prefillvalueexpression
    ? !!evalExpression(prefillvalueexpression, model)
    : false;
  const formCheck = layout === 'Horizontal' ? 'inline' : 'default';
  const [label] =
    evalExpression<string[]>(prescribedoptions || '', model) || [];
  if (promptexpression) {
    prompt = evalExpression<string[]>(promptexpression, model)?.join('') || '';
  }
  const unknown = model && !model.hasOwnProperty(name.toLowerCase());
  return {
    type: 'checkbox',
    defaultValue,
    modelOptions: { updateOn: 'change' },
    props: {
      formCheck,
      label,
      prompt,
      required: false,
    },
    hooks: {
      onInit: ({ formControl, parent }: FormlyFieldConfig) => {
        const isInRepeatGroup = !!parent?.parent?.fieldArray;
        if (unknown && !isInRepeatGroup) {
          formControl?.markAsDirty();
          formControl?.updateValueAndValidity();
        }
        // Checkbox is not marked as touched until the "blur" event (second click).
        // We need to know as soon as the checkbox is clicked,
        // in order to emit "touched" event to the subscribers.
        formControl?.valueChanges.subscribe(() => formControl.markAsTouched());
      },
    },
    validators: {
      verify: {
        expression: (control: AbstractControl) =>
          name.toLowerCase().includes('_verify') ? control.value : true,
      },
    },
  };
}
