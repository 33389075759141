import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { concatMap, delayWhen, of } from 'rxjs';
import {
  ActiveProjectService,
  ActiveProjectUserRoleService,
} from 'src/app/core/services';
import { Modal, Roles } from 'src/app/models/enums';
import { ProjectPerson, UserProjectSettings } from 'src/app/models/interfaces';

@Component({
  selector: 'app-project-settings-modal',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './project-settings-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSettingsModalComponent {
  form = new FormGroup({
    projectPersonId: new FormControl<string>('', { nonNullable: true }),
    shouldCcOnRespondentEmails: new FormControl<boolean>(false, {
      nonNullable: true,
    }),
    shouldNotifyOnRespondentSigning: new FormControl<boolean>(false, {
      nonNullable: true,
    }),
  });

  constructor(
    private activeProjectService: ActiveProjectService,
    private activeProjectUserRoleService: ActiveProjectUserRoleService,
    private bsModalService: BsModalService,
  ) {
    this.activeProjectUserRoleService.projectRoles$
      .pipe(takeUntilDestroyed())
      .subscribe((projectRoles) => {
        if (projectRoles.length) {
          const settings = this.getUserProjectSettings(projectRoles);
          if (settings) {
            this.form.setValue(settings, { emitEvent: false });
          }
        }
      });

    this.form.valueChanges
      .pipe(
        takeUntilDestroyed(),
        concatMap(() =>
          this.activeProjectUserRoleService.updateUserProjectSettings(
            this.form.getRawValue(),
          ),
        ),
        delayWhen(() => {
          const projectid = this.activeProjectService.project?.id;
          return projectid
            ? this.activeProjectService.loadActiveProject(projectid)
            : of(null);
        }),
      )
      .subscribe();
  }

  closeModal(): void {
    this.bsModalService.hide(Modal.ProjectSettings);
  }

  private getUserProjectSettings(
    projectRoles: ProjectPerson[],
  ): UserProjectSettings | null {
    if (projectRoles.length === 1) {
      const {
        id: projectPersonId,
        shouldCcOnRespondentEmails,
        shouldNotifyOnRespondentSigning,
      } = projectRoles[0];
      return {
        projectPersonId,
        shouldCcOnRespondentEmails,
        shouldNotifyOnRespondentSigning,
      };
    } else if (projectRoles.length) {
      // If the current user has multiple project roles,
      // they are a client admin with a secondary role.
      // We need to find their primary client admin role.
      const projectPerson = projectRoles.find(
        ({ role }) => role?.roleName === Roles.ClientAdmin,
      );
      if (projectPerson) {
        const {
          id: projectPersonId,
          shouldCcOnRespondentEmails,
          shouldNotifyOnRespondentSigning,
        } = projectPerson;
        return {
          projectPersonId,
          shouldCcOnRespondentEmails,
          shouldNotifyOnRespondentSigning,
        };
      }
    }

    return null;
  }
}
