<div
  *ngIf="field"
  class="row"
  [ngClass]="{
    'alert alert-info mb-0 px-0': hasChanged,
    'my-4 unchanged-field': !hasChanged,
  }"
>
  <div class="col-8 summary-details">
    @if (props["prompt"]) {
      <div
        appGlossaryTerm
        class="field-label mb-3"
        [innerHTML]="props['prompt'] | safeHtml"
        style="max-width: 80ch; white-space: pre-wrap"
      ></div>
    }

    @if (props.label) {
      <div
        appGlossaryTerm
        [innerHTML]="props.label | safeHtml"
        style="max-width: 80ch; white-space: pre-wrap"
      ></div>
    }

    @if (repeatPrompt) {
      <div
        appGlossaryTerm
        class="field-label my-3"
        [innerHTML]="repeatPrompt | safeHtml"
        style="max-width: 80ch; white-space: pre-wrap"
      ></div>
    }
  </div>

  <div class="col-3 offset-1">
    <ng-container #fieldComponent></ng-container>
  </div>
</div>
