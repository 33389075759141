import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-summary-field-wrapper',
  templateUrl: './summary-field-wrapper.component.html',
})
export class SummaryFieldWrapperComponent extends FieldWrapper {
  get hasChanged(): boolean {
    return this.props['hasChanged'] || false;
  }

  get repeatPrompt() {
    // If this radio field is part of a form array and it's parent has a "repeatTitles" prop,
    // and it's the only field in the repeat group, override its prompt atribute with a repeatTitle,
    // but not for the first one.
    const parent = this.field?.parent;
    if (
      parent &&
      parent.parent?.props &&
      parent.parent.props['repeatTitles'] &&
      (parent.parent.fieldArray as any)?.fieldGroup?.length === 1
    ) {
      const i = Number(parent.key);
      return parent.parent.props['repeatTitles'][i];
    }
    return '';
  }
}
