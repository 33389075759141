import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  delayWhen,
  filter,
  map,
  Observable,
  tap,
  throwError,
} from 'rxjs';
import { ProjectStatus } from 'src/app/models/aliases';
import { Roles, UserType } from 'src/app/models/enums';
import {
  ActivityLog,
  AddTeamMember,
  EditTeamMember,
  Person,
  Project,
  ProjectListItem,
  ProjectPerson,
  ProjectSummary,
  Respondent,
} from 'src/app/models/interfaces';
import {
  ActiveProjectService,
  CurrentUserService,
  OdataBackendService,
} from '..';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  constructor(
    private activeProjectService: ActiveProjectService,
    private currentUserService: CurrentUserService,
    private odataBackendService: OdataBackendService,
  ) {}

  private _myProjects = new BehaviorSubject<Project[]>([]);
  get myProjects$(): Observable<Project[]> {
    return this._myProjects.asObservable();
  }
  get myProjects(): Project[] {
    return this._myProjects.getValue();
  }
  set myProjects(value: Project[]) {
    this._myProjects.next(value);
  }

  get projectHeader$() {
    return combineLatest([
      this.myProjects$,
      this.activeProjectService.project$,
      this.currentUserService.currentUser$,
    ]).pipe(
      map(([myProjects, selectedProject, currentUser]) => {
        if (selectedProject) {
          const { client, projectName } = selectedProject;
          return {
            clientName: client?.clientName || '',
            clientNumber: client?.clientNumber,
            clientUrl: client?.url || '',
            projectName,
          };
        } else if (
          myProjects.length &&
          currentUser?.userType === UserType.external
        ) {
          const [latestProject] = myProjects;
          const { client, projectName } = latestProject;
          return {
            clientName: client?.clientName || '',
            clientNumber: client?.clientNumber,
            clientUrl: client?.url || '',
            projectName,
          };
        } else {
          return null;
        }
      }),
    );
  }

  get sameClientProjects$(): Observable<boolean> {
    return this.myProjects$.pipe(
      map((projects) =>
        projects.every(
          (project) =>
            project.client?.clientName === projects[0].client?.clientName,
        ),
      ),
    );
  }

  get projectList$(): Observable<ProjectListItem[]> {
    return combineLatest([
      this.myProjects$,
      this.currentUserService.currentUser$,
    ]).pipe(
      filter(([projects, currentUser]) => Boolean(currentUser)),
      map(([projects, currentUser]) =>
        this.mapProjectsToProjectList(projects, currentUser!),
      ),
    );
  }

  addProject(project: Partial<Project>): Observable<Project> {
    return this.odataBackendService.postEntity<Project>('Projects', project);
  }

  copyProjectData(fromProjectId: string, toProjectId: string) {
    return this.odataBackendService.postEntity<unknown>(
      'Projects/DuplicateFromProject',
      { fromProjectId, toProjectId },
    );
  }

  fetchMyProjects(userId: string): Observable<Project[]> {
    return this.odataBackendService
      .getEntitySet<Project>('Projects', {
        expand: {
          activityLogs: {
            select: [
              'activityDate',
              'activitySummary',
              'activityType',
              'objectType',
              'respondentId',
            ],
            orderBy: [['id', 'asc']],
          },
          client: {
            select: ['clientName', 'url'],
          },
          projectPeople: {
            expand: {
              delegates: {
                select: ['personId', 'respondentId'],
              },
              person: {
                select: ['name'],
              },
              respondent: {
                select: [
                  'id',
                  'questionnaireStatus',
                  'questionnaireStatusUpdated',
                  'respondentCategory',
                  'respondentEntityName',
                ],
              },
              role: {
                select: ['roleName'],
              },
            },
            filter: {
              personId: { eq: { type: 'guid', value: userId } },
              or: [
                {
                  not: {
                    role: {
                      roleName: {
                        in: [Roles.Respondent, Roles.RespondentDelegate],
                      },
                    },
                  },
                },
                {
                  not: {
                    respondent: {
                      questionnaireStatus: {
                        in: ['New', 'ReadyToDistribute'],
                      },
                    },
                  },
                },
              ],
            },
            orderBy: 'role/roleName asc',
          },
        },
        filter: {
          or: [
            {
              projectPeople: {
                any: {
                  or: [
                    {
                      personId: { eq: { type: 'guid', value: userId } },
                      role: {
                        roleName: Roles.Respondent,
                      },
                      respondent: {
                        questionnaireStatus: {
                          in: [
                            'InProgress-NotYetStarted',
                            'InProgress',
                            'Complete',
                            'CompleteSigned',
                          ],
                        },
                      },
                    },
                    {
                      personId: { eq: { type: 'guid', value: userId } },
                      role: {
                        roleName: {
                          in: [
                            Roles.CooleyUser,
                            Roles.ClientAdmin,
                            Roles.ClientCollaborator,
                          ],
                        },
                      },
                    },
                  ],
                },
              },
            },
            {
              delegates: {
                any: {
                  personId: { eq: { type: 'guid', value: userId } },
                  respondent: {
                    questionnaireStatus: {
                      in: [
                        'InProgress-NotYetStarted',
                        'InProgress',
                        'Complete',
                        'CompleteSigned',
                      ],
                    },
                  },
                },
              },
            },
          ],
        },
        orderBy: [['created', 'desc']],
      })
      .pipe(
        tap((projects) => (this.myProjects = projects)),
        catchError((error) => throwError(error)),
      );
  }

  getClientsCount(startDate: Date): Observable<number> {
    const userId = this.currentUserService.currentUser?.id;
    return this.odataBackendService
      .getEntitySet('Projects', {
        transform: {
          filter: {
            projectPeople: {
              any: {
                personId: { eq: { type: 'guid', value: userId } },
              },
            },
            created: { ge: startDate },
          },
          groupBy: {
            properties: ['client/clientNumber'],
            transform: {
              aggregate: {
                id: {
                  with: 'countdistinct',
                  as: 'total',
                },
              },
            },
          },
        },
      })
      .pipe(map((clients) => clients.length));
  }

  getDocumentsSignedCount(startDate: Date): Observable<number> {
    const userId = this.currentUserService.currentUser?.id;
    return this.odataBackendService.getEntitySetCount('Respondents', {
      filter: {
        questionnaireStatus: 'CompleteSigned',
        project: {
          projectPeople: {
            any: {
              personId: { eq: { type: 'guid', value: userId } },
            },
          },
        },
        modified: { ge: startDate },
      },
    });
  }

  getPagesCompletedCount(startDate: Date): Observable<number> {
    const userId = this.currentUserService.currentUser?.id;
    return this.odataBackendService
      .getEntitySet('Pages', {
        transform: {
          filter: {
            collaborator: {
              personId: { eq: { type: 'guid', value: userId } },
            },
            respondentPages: {
              all: {
                or: [
                  { cePageStatus: 'irrelevant' },
                  { respondentPageStatus: 'Complete' },
                ],
              },
            },
            modified: { ge: startDate },
          },
          groupBy: {
            properties: ['id'],
            transform: {
              aggregate: {
                id: {
                  with: 'countdistinct',
                  as: 'total',
                },
              },
            },
          },
        },
      })
      .pipe(map((pages) => pages.length));
  }

  getPagesTotalCount(startDate: Date): Observable<number> {
    const userId = this.currentUserService.currentUser?.id;
    return this.odataBackendService
      .getEntitySet('Pages', {
        transform: {
          filter: {
            collaborator: {
              personId: { eq: { type: 'guid', value: userId } },
            },
            modified: { ge: startDate },
          },
          groupBy: {
            properties: ['id'],
            transform: {
              aggregate: {
                id: {
                  with: 'countdistinct',
                  as: 'total',
                },
              },
            },
          },
        },
      })
      .pipe(map((pages) => pages.length));
  }

  getProjectChangesCount(projectId: string): Observable<number> {
    return this.odataBackendService.getEntitySetCount(
      `Projects/${projectId}/GetChanges()`,
      {
        filter: {
          isRelevant: true,
          wasPropagationIgnored: false,
          not: {
            respondent: {
              questionnaireStatus: {
                in: ['New', 'ReadyToDistribute'],
              },
            },
          },
        },
      },
    );
  }

  getProjectsCount(startDate: Date): Observable<number> {
    const userId = this.currentUserService.currentUser?.id;
    return this.odataBackendService.getEntitySetCount('Projects', {
      filter: {
        projectPeople: {
          any: {
            personId: { eq: { type: 'guid', value: userId } },
          },
        },
        created: { ge: startDate },
      },
    });
  }

  getProjectsSummary(clientNumber: string): Observable<ProjectSummary[]> {
    return this.odataBackendService.getEntitySet<ProjectSummary>(
      `Projects/Summary(clientNumber='${clientNumber}')`,
      {
        filter: { isTest: false },
        orderBy: [['created', 'desc']],
      },
    );
  }

  hasProjectDuplicate(
    clientNumber: string,
    projectTypeId: string,
  ): Observable<ProjectSummary> {
    return this.odataBackendService
      .getEntitySet<ProjectSummary>(
        `Projects/Summary(clientNumber='${clientNumber}')`,
        {
          filter: {
            isTest: false,
            projectTypeId: { eq: { type: 'guid', value: projectTypeId } },
          },
          orderBy: [['created', 'desc']],
        },
      )
      .pipe(
        map((summary) => {
          const [mostRecentProject] = summary || [];
          return mostRecentProject;
        }),
      );
  }

  saveExistingTeamMember(
    projectId: string,
    clientTeamMember: EditTeamMember,
  ): Observable<Project> {
    return this.odataBackendService
      .postEntity<any>(`projects/${projectId}/EditTeamMember`, {
        teamMember: {
          personId: clientTeamMember.personId,
          roleId: clientTeamMember.roleId,
        },
      })
      .pipe(
        delayWhen(() => this.activeProjectService.loadActiveProject(projectId)),
        catchError((error) => throwError(error)),
      );
  }

  deleteTeamMember(
    projectId: string,
    { personId, roleId }: ProjectPerson,
  ): Observable<null> {
    return this.odataBackendService.postEntity<any>(
      `projects/${projectId}/RemoveTeamMember`,
      { teamMember: { personId, roleId } },
    );
  }

  addTeamMember(
    projectId: string,
    teamMember: AddTeamMember,
  ): Observable<Project> {
    return this.odataBackendService
      .postEntity<any>(`projects/${projectId}/AddTeamMember`, { teamMember })
      .pipe(
        delayWhen(() => this.activeProjectService.loadActiveProject(projectId)),
        catchError((error) => throwError(error)),
      );
  }

  editTeamMember(
    projectId: string,
    teamMember: Partial<ProjectPerson>,
  ): Observable<Project> {
    return this.odataBackendService.postEntity<any>(
      `projects/${projectId}/EditTeamMember`,
      { teamMember },
    );
  }

  private mapProjectsToProjectList(projects: Project[], user: Person) {
    const multirole = this.hasMultipleRoles(projects, user);
    return projects.reduce<ProjectListItem[]>((listItems, project) => {
      project.projectPeople.forEach((projectRole, index) => {
        const { person, respondent, role } = projectRole;
        const lastActivity = this.getLastActivity(
          project.activityLogs,
          project.status,
          projectRole,
        );
        const listItem: ProjectListItem = {
          clientUrl: project.client?.url?.trim() || '',
          clientName: project.client?.clientName || '',
          isAdditionalRole: Boolean(index),
          isTest: project.isTest || false,
          lastActivity,
          projectId: project.id,
          projectName: project.projectName,
          projectTypeId: project.projectTypeId,
          respondentId: '',
          role: role?.roleName
            ? this.formatRoleName(role.roleName, multirole)
            : '',
          status: project.status,
        };

        if (role?.roleName === Roles.Respondent) {
          if (respondent) {
            this.appendRespondentInfo(listItem, respondent, person);
          }
        } else if (role?.roleName === Roles.RespondentDelegate) {
          user.projectPeople
            .find(({ id }) => id === projectRole.id)
            ?.delegates.filter(({ respondent }) =>
              this.isQuestionnaireDistributed(respondent),
            )
            .forEach(({ respondent }) => {
              if (respondent) {
                this.appendRespondentInfo(
                  listItem,
                  respondent,
                  respondent.person,
                );
                listItem.lastActivity = this.getLastActivity(
                  project.activityLogs,
                  project.status,
                  { ...projectRole, respondent },
                );
              }
              listItems.push({ ...listItem });
            });
          return;
        }

        listItems.push({ ...listItem });
      });

      return listItems;
    }, []);
  }

  private formatRoleName(role: Roles, multirole: boolean): string {
    if (role === Roles.Respondent && !multirole) {
      return '';
    }

    switch (role) {
      case Roles.CooleyUser:
        return '';
      case Roles.ClientAdmin:
        return 'Admin';
      case Roles.ClientCollaborator:
        return 'Collaborator';
      case Roles.Respondent:
        return 'Respondent';
      case Roles.RespondentDelegate:
        return 'Delegate';
      default:
        return role;
    }
  }

  private getLastActivity(
    activityLogs: ActivityLog[],
    projectStatus: ProjectStatus,
    { created: projectRoleCreated, respondent, role }: ProjectPerson,
  ): string {
    //Cooley user or client admin
    if (
      role?.roleName === Roles.CooleyUser ||
      role?.roleName === Roles.ClientAdmin
    ) {
      // For client admins show their invite date when project status is collaborating.
      if (
        role.roleName === Roles.ClientAdmin &&
        projectStatus === 'Collaborating'
      ) {
        return `Invited ${format(new Date(projectRoleCreated || ''), 'MMM d, yyyy')}`;
      }

      switch (projectStatus) {
        case 'Collaborating':
          const invited = activityLogs.find(
            ({ activityType }) => activityType === 'Collaborating',
          )?.activityDate;
          return invited
            ? `Client invited ${format(new Date(invited), 'MMM d, yyyy')}`
            : '';
        case 'Distributed':
          const distributed = activityLogs.find(
            ({ activityType }) => activityType === 'Distributed',
          )?.activityDate;
          return distributed
            ? `Distributed ${format(new Date(distributed), 'MMM d, yyyy')}`
            : '';
        case 'Complete':
          const completed = activityLogs.find(
            ({ activityType, objectType }) =>
              activityType === 'Completed' && objectType === 'Project',
          )?.activityDate;
          return completed
            ? `Completed ${format(new Date(completed), 'MMM d, yyyy')}`
            : '';
        default:
          const created = activityLogs.find(
            ({ activityType }) => activityType === 'Created',
          )?.activityDate;
          return created
            ? `Created ${format(new Date(created || ''), 'MMM d, yyyy')}`
            : '';
      }
    }
    // Client collaborator
    else if (role?.roleName === 'Client collaborator') {
      return projectRoleCreated
        ? `Invited ${format(new Date(projectRoleCreated), 'MMM d, yyyy')}`
        : '';
    }
    // Respondent or Delegate
    else if (
      role?.roleName === 'Respondent' ||
      role?.roleName === 'Respondent delegate'
    ) {
      if (respondent?.questionnaireStatus === 'CompleteSigned') {
        const completed = activityLogs.find(
          ({ activityType, objectType, respondentId }) =>
            objectType === 'Respondent' &&
            activityType === 'Completed' &&
            respondentId === respondent?.id,
        )?.activityDate;
        return completed
          ? `Complete ${format(new Date(completed), 'MMM d, yyyy')}`
          : '';
      } else {
        const received = activityLogs.find(
          ({ activityType, respondentId }) =>
            activityType === 'Distributed' && respondentId === respondent?.id,
        )?.activityDate;
        return received
          ? `Received ${format(new Date(received), 'MMM d, yyyy')}`
          : '';
      }
    }

    return '';
  }

  private hasMultipleRoles(projects: Project[], user: Person | null): boolean {
    const projectSet = new Set();
    for (const project of projects) {
      const projectRoles = project.projectPeople.filter(
        ({ personId, respondent, role }) =>
          personId === user?.id &&
          ((role?.roleName !== Roles.Respondent &&
            role?.roleName !== Roles.RespondentDelegate) ||
            (respondent?.questionnaireStatus !== 'New' &&
              respondent?.questionnaireStatus !== 'ReadyToDistribute')),
      );
      for (const projectRole of projectRoles) {
        if (projectSet.has(project.id)) {
          return true;
        }
        projectSet.add(projectRole.projectId);
      }
    }

    return false;
  }

  private isQuestionnaireDistributed(respondent?: Respondent): boolean {
    return (
      respondent?.questionnaireStatus === 'InProgress-NotYetStarted' ||
      respondent?.questionnaireStatus === 'InProgress' ||
      respondent?.questionnaireStatus === 'Complete' ||
      respondent?.questionnaireStatus === 'CompleteSigned'
    );
  }

  private appendRespondentInfo(
    listItem: ProjectListItem,
    {
      id,
      respondentCategory,
      respondentEntityName,
      questionnaireStatus,
    }: Respondent,
    person?: Person,
  ) {
    listItem.respondentCategory = respondentCategory || '';
    listItem.respondentId = id || '';
    listItem.respondentName = respondentEntityName || person?.name;
    if (questionnaireStatus === 'InProgress-NotYetStarted') {
      listItem.status = 'New';
    } else if (questionnaireStatus === 'InProgress') {
      listItem.status = 'Pending';
    } else if (questionnaireStatus === 'CompleteSigned') {
      listItem.status = 'Complete';
    }
  }
}
