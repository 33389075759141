@if (useIcon && formControl.value === true) {
  <svg
    cdsIcon="checkbox--checked"
    class="text-muted"
    fill="currentColor"
    size="24"
  ></svg>
} @else if (useIcon) {
  <svg
    cdsIcon="checkbox"
    class="text-muted"
    fill="currentColor"
    size="24"
  ></svg>
} @else {
  @if (formControl.value | answer: multivalue : format; as value) {
    <div [innerHTML]="value" style="white-space: break-spaces"></div>
  } @else {
    <div class="text-muted">Not answered</div>
  }
}
