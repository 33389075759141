@for (row of field.fieldGroup; track row; let i = $index) {
  @if (editingMap.get(i)) {
    <div class="d-flex gap-5 align-items-center my-2">
      <div class="fw-bold">{{ i + 1 }} of {{ field.fieldGroup?.length }}</div>
      <button
        class="btn btn-danger cx-btn cx-btn--ghost ms-2"
        type="button"
        (click)="removeGroup(i)"
      >
        Delete
        <svg cdsIcon="trash-can" fill="currentColor" size="16"></svg>
      </button>
    </div>

    <div class="row g-2">
      @if (getField("signatory_do_awardtype", i); as subfield) {
        <div class="col">
          <formly-field [field]="subfield"></formly-field>
        </div>
      }
      @if (getField("signatory_do_stockclass", i); as subfield) {
        <div class="col">
          <formly-field [field]="subfield"></formly-field>
        </div>
      }
      @if (getField("signatory_do_grantdate", i); as subfield) {
        <div class="col-auto">
          <formly-field [field]="subfield"></formly-field>
        </div>
      }
    </div>
    <div class="row g-2">
      <div class="col-auto">
        <formly-field
          *ngIf="getField('signatory_do_totalshares', i) as subfield"
          [field]="subfield"
        ></formly-field>
      </div>
      <div class="col-auto">
        <formly-field
          *ngIf="getField('signatory_do_vestedshares', i) as subfield"
          [field]="subfield"
        ></formly-field>
      </div>
      <div class="col-auto">
        <formly-field
          *ngIf="getField('signatory_do_unvestedshares', i) as subfield"
          [field]="subfield"
        ></formly-field>
      </div>
    </div>
    <div class="row g-2">
      <div class="col-2">
        <formly-field
          *ngIf="getField('signatory_do_hasexerciseprice', i) as subfield"
          [field]="subfield"
        ></formly-field>
      </div>
      <div class="col-auto">
        <formly-field
          *ngIf="getField('signatory_do_exerciseprice', i) as subfield"
          [field]="subfield"
        ></formly-field>
      </div>
    </div>
    <div class="row g-2">
      <div class="col-2">
        <formly-field
          *ngIf="getField('signatory_do_hasexpirationdate', i) as subfield"
          [field]="subfield"
        ></formly-field>
      </div>
      <div class="col-auto">
        <formly-field
          *ngIf="getField('signatory_do_expirationdate', i) as subfield"
          [field]="subfield"
        ></formly-field>
      </div>
    </div>
    <div class="row g-2">
      <div class="col-2">
        <formly-field
          *ngIf="getField('signatory_do_hasvestingdate', i) as subfield"
          [field]="subfield"
        ></formly-field>
      </div>
      <div class="col-3">
        <formly-field
          *ngIf="getField('signatory_do_vestingdate', i) as subfield"
          [field]="subfield"
        ></formly-field>
      </div>
      <div class="col-3">
        <formly-field
          *ngIf="getField('signatory_do_vestingschedule', i) as subfield"
          [field]="subfield"
        ></formly-field>
      </div>
    </div>
    <div class="row g-2">
      <div class="col-2">
        <formly-field
          *ngIf="getField('signatory_do_modified', i) as subfield"
          [field]="subfield"
        ></formly-field>
      </div>
      <div class="col">
        <formly-field
          *ngIf="getField('signatory_do_modifieddetails', i) as subfield"
          [field]="subfield"
        ></formly-field>
      </div>
    </div>
    <div class="d-flex gap-2">
      <button
        class="btn btn-outline-primary cx-btn"
        type="button"
        (click)="toggleEdit(i)"
      >
        Save changes
        <svg cdsIcon="save" fill="currentColor" size="16"></svg>
      </button>
      <button
        class="btn cx-btn cx-btn--ghost"
        type="button"
        (click)="toggleEdit(i)"
      >
        Cancel
      </button>
    </div>
    <hr />
  } @else {
    <div class="bg-light my-2 px-3">
      <div class="d-flex gap-3 align-items-center">
        <div class="fw-bold">{{ i + 1 }} of {{ field.fieldGroup?.length }}</div>
        <button
          class="btn btn-primary cx-btn cx-btn--ghost ms-2"
          type="button"
          (click)="toggleEdit(i)"
        >
          Edit
          <svg cdsIcon="edit" fill="currentColor" size="16"></svg>
        </button>
        <button
          class="btn btn-danger cx-btn cx-btn--ghost cx-btn--icon ms-2"
          type="button"
          (click)="removeGroup(i)"
        >
          <svg cdsIcon="trash-can" fill="currentColor" size="16"></svg>
        </button>
      </div>
      <div class="row py-3">
        <div class="col-3">
          <div class="fw-bold mb-2">
            {{ formControl.value[i]["signatory_do_awardtype"] }}
          </div>
          <div>{{ formControl.value[i]["signatory_do_stockclass"] }}</div>
        </div>
        <div class="col-3">
          <div class="row gy-2">
            <div class="col-7">Total Shares</div>
            <div class="col-5">
              @if (
                formControl.value[i]["signatory_do_totalshares"];
                as signatory_do_totalshares
              ) {
                {{ signatory_do_totalshares | number }}
              }
            </div>
            <div class="col-7">Vested &#64; FYE</div>
            <div class="col-5">
              @if (
                formControl.value[i]["signatory_do_vestedshares"];
                as signatory_do_vestedshares
              ) {
                {{ signatory_do_vestedshares | number }}
              }
            </div>
            <div class="col-7">Unvested &#64; Shares</div>
            <div class="col-5">
              @if (
                formControl.value[i]["signatory_do_unvestedshares"];
                as signatory_do_unvestedshares
              ) {
                {{ signatory_do_unvestedshares | number }}
              }
            </div>
            <div class="col-7">Exercise Price</div>
            <div class="col-5">
              {{
                formControl.value[i]["signatory_do_exerciseprice"]
                  | currency: "USD" : "symbol" : "1.0-4"
              }}
            </div>
            <div class="col-7">Grant Date</div>
            <div class="col-5">
              {{
                formControl.value[i]["signatory_do_grantdate"]
                  | date: "shortDate"
              }}
            </div>
            <div class="col-7">Expiration Date</div>
            <div class="col-5">
              {{
                formControl.value[i]["signatory_do_expirationdate"]
                  | date: "shortDate"
              }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row gy-2">
            <div class="col-4">Vesting Date</div>
            <div class="col-8">
              {{
                formControl.value[i]["signatory_do_vestingdate"]
                  | date: "shortDate"
              }}
            </div>
            <div class="col-4">Vesting Schedule</div>
            <div class="col-8">
              {{ formControl.value[i]["signatory_do_vestingschedule"] }}
            </div>
            <div class="col-4">Modified in 2024?</div>
            <div class="col-8">
              {{ formControl.value[i]["signatory_do_modifieddetails"] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

<div class="mb-3">
  <button
    class="btn btn-outline-primary cx-btn"
    [class.w-100]="!field.fieldGroup?.length"
    [ngStyle]="field.fieldGroup?.length ? {} : { borderStyle: 'dashed' }"
    type="button"
    (click)="addGroup()"
  >
    {{
      field.fieldGroup?.length
        ? "Add another stock option"
        : "Add one or more stock options"
    }}
    <svg cdsIcon="add" fill="currentColor" size="16"></svg>
  </button>

  @if ((showError$ | async) && field.formControl.value.length === 0) {
    <div class="missing-answer d-flex gap-2 py-2 text-danger">
      <svg cdsIcon="warning--filled" fill="currentColor" size="16"></svg>
      This field is required
    </div>
  }
</div>
