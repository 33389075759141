<div
  class="px-5"
  [class.d-none]="hidePage"
  [class.hide-unchanged]="hideUnchanged"
>
  <h5 class="mb-5">{{ title }}</h5>

  <form autocomplete="off">
    <formly-form [fields]="fields" [model]="model"></formly-form>
  </form>
</div>
