import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { QuestionnaireFormModel } from 'src/app/models/aliases';

@Component({
  selector: 'app-change-report-page',
  templateUrl: './change-report-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeReportPageComponent {
  @Input() fields: FormlyFieldConfig[] = [];
  @Input() hideUnchanged: boolean | null = false;
  @Input() model: QuestionnaireFormModel = {};
  @Input() title: string | null = '';

  get hidePage(): boolean {
    return (
      Boolean(this.hideUnchanged) &&
      this.fields.every(({ fieldGroup }) =>
        fieldGroup
          ? fieldGroup.every(
              ({ props }) => props && (props.hidden || !props['hasChanged']),
            )
          : true,
      )
    );
  }
}
