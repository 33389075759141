<div class="modal-header border-0 p-3">
  <h4 class="mb-0">Review changes</h4>
  <button
    aria-label="Close"
    class="btn-close"
    (click)="closeModal()"
    type="button"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>

<div class="modal-body p-3">
  <div class="w-75 mb-4">
    Review the upstream changes below, and update the questionnaire. The system
    will notify the respondent via email that their questionnaire has changed,
    and items that require their attention will be indicated.
  </div>

  <div class="d-flex align-items-center gap-4 mb-4">
    <div class="text-muted">Respondent</div>
    <div class="fw-bold">{{ respondent.person?.name }}</div>
    <ng-container [ngTemplateOutlet]="primaryStatus"></ng-container>
  </div>

  <div class="table-responsive" [formGroup]="form">
    <table class="table mb-0">
      <thead>
        <tr class="bg-white" style="border-bottom: 1px solid #e8e8e8">
          <th class="text-muted fw-normal">Page</th>
          <th class="text-muted fw-normal">Question</th>
          <th class="text-muted fw-normal">Previous&nbsp;Info</th>
          <th class="text-muted fw-normal">New&nbsp;Info</th>
          <th class="text-muted fw-normal" style="width: 84px">Update</th>
          <th class="text-muted fw-normal" style="width: 84px">Ignore</th>
        </tr>
      </thead>
      <tbody>
        @for (
          change of changesMap | keyvalue: originalOrder;
          track change.key
        ) {
          @if (change.value.repeatGroup?.rows; as rows) {
            @for (
              row of rows | keyvalue: originalOrder;
              track row.key;
              let firstRow = $first;
              let lastRow = $last
            ) {
              <tr>
                <!-- Page name column -->
                @if (firstRow) {
                  <td
                    class="align-top h-auto"
                    [class.pt-3]="firstRow"
                    [class.pt-4]="!firstRow"
                    [rowSpan]="change.value.repeatGroup?.count"
                    [innerHtml]="change.value.pageName"
                  ></td>
                }
                <!-- End of page name column -->

                <!-- Question prompt column-->
                <td
                  class="align-top border-0 h-auto"
                  [class.pb-2]="row.key"
                  [class.pt-3]="firstRow"
                  [class.pt-4]="!firstRow"
                >
                  {{ row.key | stripHtml }}
                </td>
                <!-- End of question prompt column-->

                <!-- Previous info column -->
                <td
                  class="align-top border-0 h-auto"
                  [class.pb-2]="row.key"
                  [class.pt-3]="firstRow"
                  [class.pt-4]="!firstRow"
                ></td>
                <!-- End of previous info column -->

                <!-- New info -->
                <td
                  class="align-top border-0 h-auto"
                  [class.pb-2]="row.key"
                  [class.pt-3]="firstRow"
                  [class.pt-4]="!firstRow"
                ></td>
                <!-- End of new info -->

                <!-- Form controls -->
                @if (firstRow) {
                  <td
                    class="align-top h-auto text-center"
                    [class.pt-3]="firstRow"
                    [class.pt-4]="!firstRow"
                    [rowSpan]="change.value.repeatGroup?.count"
                  >
                    <div class="form-check d-flex justify-content-center">
                      <input
                        type="radio"
                        class="form-check-input"
                        [formControlName]="change.key"
                        [value]="'update'"
                      />
                    </div>
                  </td>
                  <td
                    class="align-top h-auto text-center"
                    [class.pt-3]="firstRow"
                    [class.pt-4]="!firstRow"
                    [rowSpan]="change.value.repeatGroup?.count"
                  >
                    <div class="form-check d-flex justify-content-center">
                      <input
                        type="radio"
                        class="form-check-input"
                        [formControlName]="change.key"
                        [value]="'ignore'"
                      />
                    </div>
                  </td>
                }
                <!-- End of form controls -->
              </tr>
              @for (
                answer of row.value;
                track answer.variableName;
                let count = $count;
                let first = $first;
                let last = $last
              ) {
                <tr>
                  <!-- Question prompt column-->
                  <td
                    class="align-top h-auto"
                    [class.border-0]="!(lastRow && last)"
                    [class.pb-3]="lastRow && last"
                  >
                    <ul class="mb-0 ps-4">
                      @if (answer.questionPrompt) {
                        <li>{{ answer.questionPrompt }}</li>
                      }
                    </ul>
                  </td>
                  <!-- End of question prompt column-->

                  <!-- Previous info column -->
                  <td
                    class="align-top h-auto"
                    [class.border-0]="!(lastRow && last)"
                    [class.pb-3]="lastRow && last"
                  >
                    <ul class="mb-0 ps-4 text-decoration-line-through">
                      <li>
                        <ng-container
                          *ngTemplateOutlet="
                            answerTemplate;
                            context: {
                              value: answer.previousValue,
                            }
                          "
                        ></ng-container>
                      </li>
                    </ul>
                  </td>
                  <!-- End of previous info column -->

                  <!-- New info -->
                  <td
                    class="align-top h-auto"
                    [class.border-0]="!(lastRow && last)"
                    [class.pb-3]="lastRow && last"
                  >
                    <ul class="mb-0 ps-4">
                      <li>
                        <ng-container
                          *ngTemplateOutlet="
                            answerTemplate;
                            context: { value: answer.newValue }
                          "
                        ></ng-container>
                      </li>
                    </ul>
                  </td>
                  <!-- End of new info -->
                </tr>
              }
            }
          } @else if (change.value.answer) {
            <tr>
              <!-- Page name column -->
              <td
                class="align-top py-3"
                [innerHtml]="change.value.pageName"
              ></td>
              <!-- End of page name column -->

              <!-- Question prompt column-->
              <td class="align-top py-3">
                <div
                  appGlossaryTerm
                  [innerHtml]="
                    change.value.answer.questionPrompt || '' | safeHtml
                  "
                ></div>
              </td>
              <!-- End of question prompt column-->

              <!-- Previous info column -->
              <td class="align-top py-3">
                <ng-container
                  *ngTemplateOutlet="
                    answerTemplate;
                    context: {
                      lineThrough: true,
                      value: change.value.answer.previousValue,
                    }
                  "
                ></ng-container>
              </td>
              <!-- End of previous info column -->

              <!-- New info -->
              <td class="align-top py-3">
                <ng-container
                  *ngTemplateOutlet="
                    answerTemplate;
                    context: { value: change.value.answer.newValue }
                  "
                ></ng-container>
              </td>
              <!-- End of new info -->

              <!-- Form controls -->
              <td class="align-top pt-3 text-center">
                <div class="form-check d-flex justify-content-center">
                  <input
                    type="radio"
                    class="form-check-input"
                    [formControlName]="change.key"
                    [value]="'update'"
                  />
                </div>
              </td>
              <td class="align-top pt-3 text-center">
                <div class="form-check d-flex justify-content-center">
                  <input
                    type="radio"
                    class="form-check-input"
                    [formControlName]="change.key"
                    [value]="'ignore'"
                  />
                </div>
              </td>
              <!-- End of form controls -->
            </tr>
          }
        }
        <tr>
          <td class="h-auto" colspan="6"></td>
        </tr>
      </tbody>
    </table>
  </div>

  @if (hasIgnoredChanges$ | async) {
    <div class="alert alert-info my-2 d-flex align-items-center gap-3">
      <svg cdsIcon="information" fill="currentColor" size="16"></svg>
      <span class="fw-bold">Ignored changes</span> will persist and can be
      applied later if needed from the questionnaire menu.
    </div>
  }
</div>

<div class="modal-footer border-0 p-3">
  <div class="row g-2 w-100">
    <div class="col">
      <button
        class="btn cx-btn cx-btn--ghost w-100"
        type="button"
        (click)="closeModal()"
      >
        Cancel
      </button>
    </div>
    <div class="col">
      @if (submitting$ | async) {
        <button
          class="btn btn-primary justify-content-between w-100"
          disabled
          type="button"
        >
          Please wait...
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </button>
      } @else {
        @if (buttonState$ | async; as state) {
          <button
            class="btn cx-btn w-100"
            [class]="state.class"
            [disabled]="form.invalid"
            type="button"
            (click)="submit()"
          >
            {{ state.label }}
            <svg cdsIcon="{{ state.icon }}" fill="currentColor" size="16"></svg>
          </button>
        }
      }
    </div>
  </div>
</div>

<ng-template #answerTemplate let-lineThrough="lineThrough" let-value="value">
  @if (value === "true") {
    <svg
      cdsIcon="checkbox--checked"
      class="text-muted"
      fill="currentColor"
      size="24"
    ></svg>
  } @else if (value === "false") {
    <svg
      cdsIcon="checkbox"
      class="text-muted"
      fill="currentColor"
      size="24"
    ></svg>
  } @else {
    <span [class.text-decoration-line-through]="lineThrough">
      @if (value?.length === 0) {
        -
      } @else {
        {{ value ?? "-" }}
      }
    </span>
  }
</ng-template>

<ng-template #primaryStatus>
  @switch (distributionStatus) {
    @case (status.Ready) {
      <div>
        <svg
          id="icon"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 32 32"
          class="me-1 text-muted"
          fill="currentColor"
        >
          <circle cx="16" cy="16" r="14" />
        </svg>
        Ready
      </div>
    }
    @case (status.Distributed) {
      <div
        class="badge rounded-pill"
        style="color: rgb(122 78 1); background-color: rgb(255 213 115)"
      >
        <svg cdsIcon="pending" class="me-1" fill="currentColor" size="16"></svg>
        Distributed
      </div>
    }
    @case (status.Complete) {
      <div
        class="badge rounded-pill text-success"
        style="background-color: rgb(161 230 209)"
      >
        <svg
          cdsIcon="checkmark--filled"
          class="me-1"
          fill="currentColor"
          size="16"
        ></svg>
        Complete
      </div>
    }
  }
</ng-template>
