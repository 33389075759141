<div class="position-fixed bottom-0 start-0 px-3" [class.d-none]="closed()">
  <div
    class="alert"
    [ngClass]="{
      'alert-primary': data.type === 'info',
      'alert-success': data.type === 'success',
    }"
    role="alert"
    style="border-left-width: 4px; width: 17.5rem"
  >
    <button
      type="button"
      class="btn-close position-absolute top-0 end-0"
      aria-label="Close"
      (click)="close()"
    ></button>
    <div class="d-flex pe-3">
      <div class="me-2">
        <svg
          *ngIf="data.type === 'info'"
          cdsIcon="information--filled"
          class="text-primary me-1"
          fill="currentColor"
          size="16"
        ></svg>
        <svg
          *ngIf="data.type === 'success'"
          cdsIcon="checkmark--filled"
          class="text-success me-1"
          fill="currentColor"
          size="16"
        ></svg>
      </div>
      <div class="d-flex flex-column gap-3 text-break">
        <div class="fw-bold fs-5">{{ data.header }}</div>
        <div class="fs-5" [innerHTML]="data.text"></div>
        @if (data.button; as button) {
          <button
            class="btn btn-outline-{{ data.type }} cx-btn"
            type="button"
            (click)="button.action(); close()"
          >
            {{ button.label }}
            <svg [cdsIcon]="button.icon" fill="currentColor" size="16"></svg>
          </button>
        }
      </div>
    </div>
  </div>
</div>
