<cx1ui-headertopuser
  *ngIf="currentUserName$ | async as userName"
  [hasUserActions]="true"
  [userName]="userName"
>
  @if (isAppAdmin$ | async) {
    <div role="menuitem">
      <a class="dropdown-item" [routerLink]="['/admin']">Admin</a>
    </div>
  }
  @if (showProjectSettings$ | async) {
    <div role="menuitem">
      <button
        class="dropdown-item py-2 px-3 d-flex justify-content-between align-items-center gap-4"
        type="button"
        (click)="openSettings()"
      >
        <span>Settings</span>
        <svg
          cdsIcon="settings"
          class="text-secondary flex-shrink-0"
          fill="currentColor"
          size="16"
        ></svg>
      </button>
    </div>
  }
  <div role="menuitem">
    <button
      class="dropdown-item py-2 px-3 d-flex justify-content-between align-items-center gap-4"
      type="button"
      (click)="logout()"
    >
      <span>Logout</span>
      <svg
        cdsIcon="logout"
        class="text-secondary flex-shrink-0"
        fill="currentColor"
        size="16"
      ></svg>
    </button>
  </div>
</cx1ui-headertopuser>
