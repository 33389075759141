import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml',
  pure: true,
  standalone: true,
})
export class StripHtmlPipe implements PipeTransform {
  transform(html: string | number | string[]): string {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html.toString();
    return tmp.textContent || tmp.innerText || '';
  }
}
