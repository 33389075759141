import { Injectable } from '@angular/core';
import { groupBy } from 'lodash';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  delayWhen,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { RespondentQuestionnaireStatus } from 'src/app/models/aliases';
import { RESPONDENT_CATEGORY_ORDER } from 'src/app/models/enums';
import {
  ChangesStats,
  ContactInfo,
  DelegateItem,
  NonRespondent,
  Person,
  Respondent,
  RespondentAnswerChange,
} from 'src/app/models/interfaces';
import {
  ActiveProjectService,
  ActiveProjectUserRoleService,
  OdataBackendService,
} from '..';

@Injectable({
  providedIn: 'root',
})
export class RespondentsService {
  private _activeRespondent = new BehaviorSubject<Respondent | null>(null);
  private _nonRespondents$ = new BehaviorSubject<NonRespondent[]>([]);
  private _respondents$ = new BehaviorSubject<Respondent[]>([]);

  get activeRespondent$(): Observable<Respondent | null> {
    return this._activeRespondent.asObservable();
  }
  get activeRespondent(): Respondent | null {
    return this._activeRespondent.getValue();
  }
  set activeRespondent(value: Respondent | null) {
    this._activeRespondent.next(value);
  }

  get groupedRespondents$() {
    return this.respondents$.pipe(
      map((respondents) => groupBy(respondents, 'respondentCategory')),
    );
  }

  get groupedDistributedRespondents$() {
    return this.respondents$.pipe(
      map((respondents) =>
        groupBy(
          respondents.filter(
            ({ questionnaireStatus }) =>
              questionnaireStatus !== 'New' &&
              questionnaireStatus !== 'ReadyToDistribute',
          ),
          'respondentCategory',
        ),
      ),
    );
  }

  get groupedRespondentsForCollaborator$() {
    return combineLatest([
      this.respondents$,
      this.activeProjectUserRoleService.projectPersonId$,
    ]).pipe(
      map(([respondents, projectPersonId]) =>
        groupBy(
          respondents.filter((respondent) =>
            respondent.respondentPages?.some(
              ({ cePageStatus, page }) =>
                cePageStatus !== 'irrelevant' &&
                page.collaboratorProjectPersonId === projectPersonId,
            ),
          ),
          'respondentCategory',
        ),
      ),
    );
  }

  get nonRespondents$() {
    return this._nonRespondents$.asObservable();
  }
  get nonRespondents() {
    return this._nonRespondents$.getValue();
  }
  set nonRespondents(value: NonRespondent[]) {
    this._nonRespondents$.next(value);
  }

  get respondents$() {
    return this._respondents$.asObservable();
  }
  get respondents() {
    return this._respondents$.getValue();
  }
  set respondents(value: Respondent[]) {
    this._respondents$.next(value);
  }

  get respondentsCount$() {
    return this.respondents$.pipe(map((respondents) => respondents.length));
  }

  get respondentsSortedByCategory() {
    return this.sortByCategory(this._respondents$.getValue());
  }

  get respondentsComplete$(): Observable<Respondent[]> {
    return this.respondents$.pipe(
      map((respondents) =>
        respondents.filter(
          ({ questionnaireStatus }) =>
            questionnaireStatus === 'Complete' ||
            questionnaireStatus === 'CompleteSigned',
        ),
      ),
    );
  }

  get respondentsCompleteSignedCount$(): Observable<number> {
    return this.respondents$.pipe(
      map(
        (respondents) =>
          respondents.filter(
            ({ questionnaireStatus }) =>
              questionnaireStatus === 'CompleteSigned',
          ).length,
      ),
    );
  }

  get respondentsPendingResponse$(): Observable<Respondent[]> {
    return this.respondents$.pipe(
      map((respondents) =>
        respondents.filter(
          ({ questionnaireStatus }) =>
            questionnaireStatus === 'InProgress-NotYetStarted' ||
            questionnaireStatus === 'InProgress',
        ),
      ),
    );
  }

  get respondentsQueue$(): Observable<Respondent[]> {
    return this.respondents$.pipe(
      map((respondents) =>
        respondents.filter(
          ({ questionnaireStatus }) => questionnaireStatus === 'New',
        ),
      ),
    );
  }

  get respondentsReadyToDistribute$(): Observable<Respondent[]> {
    return this.respondents$.pipe(
      map((respondents) =>
        respondents.filter(
          ({ questionnaireStatus }) =>
            questionnaireStatus === 'New' ||
            questionnaireStatus === 'ReadyToDistribute',
        ),
      ),
    );
  }

  get respondentsReadyToDistributeCount$(): Observable<number> {
    return this.respondentsReadyToDistribute$.pipe(
      map((respondents) => respondents.length),
    );
  }

  get respondentsDistributed$(): Observable<Respondent[]> {
    return this.respondents$.pipe(
      map((respondents) =>
        respondents.filter(
          ({ questionnaireStatus }) =>
            questionnaireStatus !== 'New' &&
            questionnaireStatus !== 'ReadyToDistribute' &&
            questionnaireStatus !== 'CompleteSigned',
        ),
      ),
    );
  }

  get respondentsDistributedCount$(): Observable<number> {
    return this.respondentsDistributed$.pipe(
      map((respondents) => respondents.length),
    );
  }

  get respondentsDistributedOrComplete$(): Observable<Respondent[]> {
    return this.respondents$.pipe(
      map((respondents) =>
        respondents.filter(
          ({ questionnaireStatus }) =>
            questionnaireStatus !== 'New' &&
            questionnaireStatus !== 'ReadyToDistribute',
        ),
      ),
    );
  }

  get respondentsDistributedOrCompleteCount$(): Observable<number> {
    return this.respondentsDistributedOrComplete$.pipe(
      map((respondents) => respondents.length),
    );
  }

  constructor(
    private activeProjectService: ActiveProjectService,
    private activeProjectUserRoleService: ActiveProjectUserRoleService,
    private odataBackendService: OdataBackendService,
  ) {}

  addDelegate(
    respondentId: string,
    person: Pick<Person, 'name' | 'primaryEmail' | 'userType'>,
  ): Observable<unknown> {
    return this.odataBackendService
      .postEntity<Person>('People', person)
      .pipe(
        switchMap((person) =>
          this.odataBackendService.postEntity<unknown>(
            `respondents/${respondentId}/AddDelegate`,
            { delegate: { personId: person.id } },
          ),
        ),
      );
  }

  addRespondent(respondent: Partial<Respondent>, person: Partial<Person>) {
    const createOrReusePerson$ = person.id
      ? of({ id: person.id })
      : this.odataBackendService.postEntity<Person>('People', person);
    return createOrReusePerson$.pipe(
      switchMap(({ id: personId }) =>
        this.odataBackendService.postEntity<Respondent>('Respondents', {
          ...respondent,
          personId,
        }),
      ),
    );
  }

  addNonRespondent(nonRespondent: Partial<NonRespondent>) {
    return this.odataBackendService
      .postEntity<NonRespondent>('NonRespondents', nonRespondent)
      .pipe(
        delayWhen(() =>
          this.fetchProjectNonRespondents(nonRespondent.projectId!),
        ),
      );
  }

  reassignQuestionnaire(
    respondent: Partial<Respondent>,
    person: Partial<Person>,
  ): Observable<Partial<Respondent>> {
    if (!person || !respondent?.id) {
      return of(respondent);
    }

    const deleteDelegatesIfNeeded$ = respondent.delegates?.length
      ? forkJoin(
          respondent.delegates.map((delegate) => this.deleteDelegate(delegate)),
        )
      : of([]);

    return deleteDelegatesIfNeeded$.pipe(
      switchMap(() =>
        this.odataBackendService.postEntity<Person>('People', person),
      ),
      switchMap((person) =>
        this.updateRespondent(respondent.id!, { personId: person.id }),
      ),
      switchMap(({ projectId }) =>
        this.activeProjectService.loadActiveProject(projectId),
      ),
      switchMap((project) => {
        const newProjectPerson = project?.projectPeople.find(
          ({ respondent: projectPersonRespondent }) =>
            projectPersonRespondent?.id === respondent?.id,
        );
        if (newProjectPerson) {
          return this.activeProjectService.resendPersonInvite(
            newProjectPerson.id,
          );
        } else {
          return of({});
        }
      }),
    );
  }

  deleteDelegate(delegate: DelegateItem): Observable<unknown> {
    return this.odataBackendService.postEntity<unknown>(
      `respondents/${delegate.respondentId}/DeleteDelegate`,
      { delegate: { delegateId: delegate.id } },
    );
  }

  deleteRespondent(respondentId: string): Observable<void> {
    return this.odataBackendService
      .deleteEntity('Respondents', respondentId)
      .pipe(catchError((error) => throwError(error)));
  }

  deleteNonRespondent(nonRespondentId: string): Observable<void> {
    return this.odataBackendService
      .deleteEntity('NonRespondents', nonRespondentId)
      .pipe(catchError((error) => throwError(error)));
  }

  distribute(
    respondentId: string,
    notifyPeopleIds?: string[],
  ): Observable<Respondent> {
    return this.odataBackendService
      .postEntity<Respondent>(
        `Respondents/${respondentId}/Distribute`,
        { notifyPeopleIds } as any,
        { expand: ['person'] },
      )
      .pipe(catchError((error) => throwError(error)));
  }

  fetchActiveProjectRespondents(): Observable<Respondent[]> {
    // TODO: Remove this function in favor of "fetchProjectRespondents" below.
    const project = this.activeProjectService.project;
    return project
      ? this.odataBackendService
          .getEntitySet<Respondent>('Respondents', {
            filter: { projectId: { eq: { type: 'guid', value: project.id } } },
            expand: [
              'delegates',
              'delegates/p/person',
              'person',
              'respondentPages/page',
            ],
          })
          .pipe(
            tap((respondents) => (this.respondents = respondents)),
            catchError((error) => throwError(error)),
          )
      : of([]);
  }

  fetchProjectRespondents(projectId: string): Observable<Respondent[]> {
    return this.odataBackendService
      .getEntitySet<Required<Respondent>>('Respondents', {
        filter: { projectId: { eq: { type: 'guid', value: projectId } } },
        expand: {
          delegates: {
            expand: ['p/person'],
          },
          person: {},
          respondentPages: {
            select: ['cePageStatus', 'respondentPageStatus'],
            filter: {
              page: {
                pageGroup: {
                  tabName: 'Respondent details',
                },
              },
            },
          },
        },
        orderBy: 'person/name',
      })
      .pipe(
        tap((respondents) => (this.respondents = respondents)),
        catchError((error) => throwError(error)),
      );
  }

  fetchProjectNonRespondents(projectId: string): Observable<NonRespondent[]> {
    return this.odataBackendService
      .getEntitySet<NonRespondent>('NonRespondents', {
        filter: { projectId: { eq: { type: 'guid', value: projectId } } },
        orderBy: 'fullName',
      })
      .pipe(
        tap((nonRespondents) => (this.nonRespondents = nonRespondents)),
        catchError((error) => {
          return throwError(error);
        }),
      );
  }

  getAnswerChanges(respondentId: string): Observable<RespondentAnswerChange[]> {
    return this.odataBackendService
      .getEntitySet<RespondentAnswerChange>(
        `Respondents/${respondentId}/GetChanges()`,
        {
          select: [
            'isRelevant',
            'previousRespondentInfoAnswerValue',
            'respondentInfoValue',
            'variableName',
          ],
          expand: {
            previousRespondentInfoAnswer: {
              select: ['answerLabel'],
            },
            respondentInfoAnswer: {
              select: [
                'answerLabel',
                'pageName',
                'questionPrompt',
                'repeatContext',
                'repeatGroupName',
                'repeatGroupTitle',
              ],
            },
          },
          orderBy: [
            ['pageGroupSortOrder', 'asc'],
            ['pageSortOrder', 'asc'],
            ['questionGroupSortOrder', 'asc'],
            ['questionSortOrder', 'asc'],
            ['respondentInfoAnswer/repeatContext' as any, 'asc'],
          ],
        },
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching changes:', error);
          return of([]);
        }),
      );
  }

  getAnswerChangesCount(respondentId: string): Observable<ChangesStats> {
    return this.odataBackendService
      .getEntitySet<RespondentAnswerChange>(
        `Respondents/${respondentId}/GetChanges()`,
        {
          select: ['wasPropagationIgnored'],
          filter: { isRelevant: true },
        },
      )
      .pipe(
        map((changes) => ({
          ignored: changes.filter(
            ({ wasPropagationIgnored }) => wasPropagationIgnored,
          ).length,
          total: changes.length,
        })),
      );
  }

  getRespondentName(respondentId: string): string | null {
    const respondent = this.respondents.find(({ id }) => id === respondentId);
    return respondent
      ? respondent.respondentEntityName || respondent.person?.name || null
      : null;
  }

  loadPerson(personId: string): Observable<Person> {
    return this.odataBackendService.getEntity<Person>('People', personId);
  }

  loadRespondent(respondentId: string): Observable<Respondent> {
    return this.odataBackendService
      .getEntity<any>('Respondents', respondentId, {
        expand: {
          person: {},
          delegates: {
            expand: {
              p: {
                expand: ['person'],
              },
            },
          },
        },
      })
      .pipe(tap((respondent) => (this.activeRespondent = respondent)));
  }

  notifyRespondent(respondentId: string) {
    return this.odataBackendService
      .postEntity(
        `/Respondents/${respondentId}/NotifyDocumentReady`,
        {},
        // { notifyPeopleIds: ['ccPersonId'] },
      )
      .pipe(
        take(1),
        catchError((err) => {
          console.error(`Error sending notification: ${err}`);
          return of(false);
        }),
      );
  }

  previewDistribute(respondentId: string): Observable<Respondent> {
    return this.odataBackendService.postEntity<Respondent>(
      `Respondents/${respondentId}/PreviewDistribute`,
      {},
    );
  }

  rollBackToStatus(
    respondentId: string,
    questionnaireStatus: RespondentQuestionnaireStatus,
  ): Observable<Respondent> {
    return this.odataBackendService.postEntity<Respondent>(
      `Respondents/${respondentId}/RollBackToStatus`,
      { questionnaireStatus },
    );
  }

  updatePerson(personId: string, body: Partial<Person>): Observable<Person> {
    return this.odataBackendService.patchEntity<Person>(
      'People',
      personId,
      body,
    );
  }

  updateRespondent(
    respondentId: string,
    body: Partial<Respondent>,
  ): Observable<Respondent> {
    return this.odataBackendService.patchEntity<Respondent>(
      'Respondents',
      respondentId,
      body,
      { expand: ['person', 'delegates', 'delegates/p/person'] },
    );
  }

  updateNonRespondent(
    nonRespondentId: string,
    body: Partial<NonRespondent>,
  ): Observable<NonRespondent> {
    return this.odataBackendService.patchEntity<NonRespondent>(
      'NonRespondents',
      nonRespondentId,
      body,
    );
  }

  updateRespondentContactInfo(respondentId: string, contactInfo: ContactInfo) {
    return this.odataBackendService.postEntity(
      `Respondents/${respondentId}/EditRespondentContactInfo`,
      { contactInfo },
    );
  }

  private sortByCategory(respondents: Respondent[]) {
    return [...respondents].sort((a, b) => {
      const categoryA = a.respondentCategory!;
      const categoryB = b.respondentCategory!;

      const indexA = RESPONDENT_CATEGORY_ORDER.indexOf(categoryA);
      const indexB = RESPONDENT_CATEGORY_ORDER.indexOf(categoryB);

      if (indexA !== indexB) {
        return indexA - indexB;
      }

      const nameA = a.person?.name?.toLowerCase() || '';
      const nameB = b.person?.name?.toLowerCase() || '';
      return nameA.localeCompare(nameB);
    });
  }
}
