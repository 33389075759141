import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, take } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  apiUrl = environment.appLeveragedApiEndpoints.apiUrl;

  constructor(private httpClient: HttpClient) {}

  trackLogin(): Observable<unknown> {
    return this.httpClient.post(`${this.apiUrl}/Events/TrackLogin`, {}).pipe(
      take(1),
      catchError((error) => {
        console.error(error);
        return of(null);
      }),
    );
  }
}
