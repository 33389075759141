import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { QuestionnaireService } from 'src/app/core/services';

@Component({
  selector: 'app-ipasa',
  templateUrl: './ipasa.component.html',
})
export class IpasaComponent extends FieldArrayType {
  editingMap = new Map<number, boolean>();
  showError$ = this.questionnaireService.showErrors$;

  constructor(private questionnaireService: QuestionnaireService) {
    super();
  }

  addGroup() {
    this.add();
    const i = this.field.fieldGroup?.length || 1;
    this.editingMap.set(i - 1, true);
  }

  toggleEdit(i: number) {
    const flag = this.editingMap.get(i);
    this.editingMap.set(i, !flag);
  }

  getField(name: string, index: number) {
    if (Array.isArray(this.field.fieldGroup)) {
      const row = this.field.fieldGroup[index];
      const fieldConfig = row.fieldGroup?.find(
        ({ key }) => key === name.toLowerCase(),
      );
      return fieldConfig;
    }

    return null;
  }

  removeGroup(index: number): void {
    if (this.formControl.length === 1) {
      Object.keys(this.formControl.at(0).getRawValue()).forEach((key) => {
        this.formControl.at(0).get(key)?.setValue('', { emitEvent: false });
      });
      this.formControl.markAsDirty();
      this.formControl.updateValueAndValidity();
    }
    this.remove(index, { markAsDirty: true });
    this.formControl.updateValueAndValidity();
  }
}
