import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pageTitle',
  pure: true,
  standalone: true,
})
export class PageTitlePipe implements PipeTransform {
  transform(value: string, withPrefix = true, withTitle = true) {
    const parts = value.split('.').reverse();
    const newTitle: string[] = [];

    if (withPrefix && parts[1]) {
      newTitle.push(parts[1], '.');
    }

    if (withTitle) {
      newTitle.push(parts[0]);
    }

    return newTitle.join('');
  }
}
