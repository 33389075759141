<ng-container [formGroup]="formGroup">
  <select
    class="form-select"
    formControlName="select"
    [compareWith]="props.compareWith"
    [formlyAttributes]="field"
  >
    <option hidden value="">{{ props.placeholder }}</option>

    @if (props.options | formlySelectOptions: field | async; as options) {
      @for (option of options; track option.value) {
        <option
          [disabled]="option.disabled"
          [title]="option.label"
          [ngValue]="option.value"
        >
          {{ option.label }}
        </option>
      }
    }

    @if (otherOptionLabel) {
      <option [ngValue]="otherOptionLabel">{{ otherOptionLabel }}</option>
    }
  </select>

  @if (showOtherOptionText) {
    <div class="mt-3">
      <textarea
        autocomplete="off"
        class="form-control"
        formControlName="text"
        rows="1"
      ></textarea>
    </div>
  }
</ng-container>
