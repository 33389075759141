export interface CardConfig {
  columns: ColumnConfig[];
  customLayout?: 'directorshipcount' | 'executivedirectorcount';
  formLabel?: string;
  labels?: {
    add_first: string;
    add_more: string;
  };
}

interface ColumnConfig {
  cssClass: string;
  primary?: FieldConfig;
  secondary?: FieldConfig[];
}

interface FieldConfig {
  customLayout?: boolean;
  extraSpace?: boolean;
  format?: 'boolean' | 'currency' | 'number' | 'mediumDate' | 'shortDate';
  gatingVariable?: string;
  label: 'top' | 'left' | 'none';
  labelOverride?: string;
  suffix?: string;
  variable: string;
}

export const summaryCardConfigs: Record<string, CardConfig> = {
  signatory_companysecuritiesnumber: {
    columns: [
      {
        cssClass: 'col-lg-4',
        primary: {
          label: 'none',
          variable: 'signatory_companysecuritiesstockclass',
        },
      },
      {
        cssClass: 'col-lg-5',
        secondary: [
          {
            format: 'number',
            label: 'left',
            variable: 'signatory_companysecuritiesnoshares',
          },
          {
            label: 'left',
            variable: 'signatory_companysecuritiesregisteredname',
          },
          {
            label: 'left',
            variable: 'signatory_companysecuritiesrelationship',
          },
        ],
      },
      {
        cssClass: 'col-lg-3',
        secondary: [
          {
            format: 'boolean',
            label: 'left',
            variable: 'signatory_companysecuritiessharedvoting',
          },
          {
            format: 'boolean',
            label: 'left',
            variable: 'signatory_companysecuritiessharedinvestment',
          },
          {
            format: 'boolean',
            label: 'left',
            variable: 'signatory_companysecuritiessecuritiespledged',
          },
        ],
      },
    ],
    formLabel: 'Security Ownership',
    labels: {
      add_first: 'Add one or more security',
      add_more: 'Add another security',
    },
  },
  signatory_companysecuritiesrtanumber: {
    columns: [
      {
        cssClass: 'col-lg-4',
        primary: {
          label: 'none',
          variable: 'signatory_companysecuritiesrtastockclass',
        },
        secondary: [
          {
            label: 'none',
            variable: 'signatory_companysecuritiesrtaawardtype',
          },
        ],
      },
      {
        cssClass: 'col-lg-4',
        secondary: [
          {
            format: 'number',
            label: 'left',
            variable: 'signatory_companysecuritiesrtaexoptionwarrantright',
          },
          {
            format: 'number',
            label: 'left',
            variable: 'signatory_companysecuritiesrtasecurityconversion',
          },
          {
            format: 'number',
            label: 'left',
            variable: 'signatory_companysecuritiesrtaterminationtrustacct',
          },
        ],
      },
      {
        cssClass: 'col-lg-4',
        secondary: [
          {
            format: 'number',
            label: 'left',
            variable: 'signatory_companysecuritiesrtasettlement',
          },
          {
            format: 'number',
            label: 'left',
            variable: 'signatory_companysecuritiesrtaother',
          },
        ],
      },
    ],
    formLabel: 'Right to Acquire Security',
    labels: {
      add_first: 'Add one or more security',
      add_more: 'Add another security',
    },
  },
  signatory_directorshipcount: {
    columns: [],
    customLayout: 'directorshipcount',
    formLabel: 'Directorship',
    labels: {
      add_first: 'Add one or more directorships',
      add_more: 'Add directorship',
    },
  },
  signatory_directorshipcountentity: {
    columns: [
      {
        cssClass: 'col-lg-6',
        primary: {
          label: 'none',
          variable: 'signatory_directorshipcompanyentity',
        },
      },
      {
        cssClass: 'col-lg-6',
        secondary: [
          {
            format: 'boolean',
            label: 'left',
            variable: 'signatory_directorshipcompanycompcommittee',
          },
          {
            format: 'boolean',
            label: 'left',
            variable: 'signatory_directorshipcompanyauditcommittee',
          },
          {
            format: 'boolean',
            label: 'left',
            variable: 'signatory_directorshipcompanylid',
          },
          {
            format: 'boolean',
            label: 'left',
            labelOverride: 'Competitor:',
            variable: 'signatory_directorshipcompanycompetitor',
          },
        ],
      },
    ],
    formLabel: 'Committee Membership',
    labels: {
      add_first: 'Add one or more entities',
      add_more: 'Add another entity',
    },
  },
  signatory_donumber: {
    columns: [
      {
        cssClass: 'col-lg-4',
        primary: {
          label: 'none',
          variable: 'signatory_do_awardtype',
        },
        secondary: [
          {
            label: 'none',
            variable: 'signatory_do_stockclass',
          },
        ],
      },
      {
        cssClass: 'col-lg-4',
        secondary: [
          {
            format: 'number',
            label: 'left',
            variable: 'signatory_do_totalshares',
          },
          {
            format: 'number',
            label: 'left',
            variable: 'signatory_do_vestedshares',
          },
          {
            format: 'number',
            label: 'left',
            variable: 'signatory_do_unvestedshares',
          },
          {
            format: 'currency',
            gatingVariable: 'signatory_do_hasexerciseprice',
            label: 'left',
            variable: 'signatory_do_exerciseprice',
          },
          {
            format: 'shortDate',
            label: 'left',
            variable: 'signatory_do_grantdate',
          },
          {
            format: 'shortDate',
            gatingVariable: 'signatory_do_hasexpirationdate',
            label: 'left',
            variable: 'signatory_do_expirationdate',
          },
        ],
      },
      {
        cssClass: 'col-lg-4',
        secondary: [
          {
            format: 'shortDate',
            gatingVariable: 'signatory_do_hasvestingdate',
            label: 'left',
            variable: 'signatory_do_vestingdate',
          },
          {
            extraSpace: true,
            gatingVariable: 'signatory_do_hasvestingdate',
            label: 'top',
            variable: 'signatory_do_vestingschedule',
          },
          {
            extraSpace: true,
            gatingVariable: 'signatory_do_modified',
            label: 'top',
            labelOverride: 'Modified in 2024',
            variable: 'signatory_do_modifieddetails',
          },
        ],
      },
    ],
    formLabel: 'Stock Award',
    labels: {
      add_first: 'Add one or more awards',
      add_more: 'Add another award',
    },
  },
  signatory_executivedirectorcount: {
    columns: [],
    customLayout: 'executivedirectorcount',
    formLabel: 'Executive Officer Position',
    labels: {
      add_first: 'Add one or more positions',
      add_more: 'Add position',
    },
  },
  signatory_form5transactionnumber: {
    columns: [
      {
        cssClass: 'col-lg-4',
        primary: {
          format: 'mediumDate',
          label: 'none',
          variable: 'signatory_form5transactiondate',
        },
        secondary: [
          {
            format: 'number',
            label: 'none',
            suffix: 'Shares',
            variable: 'signatory_form5transactionshares',
          },
        ],
      },
      {
        cssClass: 'col-lg-6',
        secondary: [
          {
            label: 'none',
            variable: 'signatory_form5transactiondesc',
          },
        ],
      },
    ],
    formLabel: 'Form 5 Transaction',
    labels: {
      add_first: 'Add one or more transactions',
      add_more: 'Add another transaction',
    },
  },
  signatory_ipasanumber: {
    columns: [
      {
        cssClass: 'col-lg-3',
        primary: {
          label: 'none',
          variable: 'signatory_ipasa_awardtype',
        },
        secondary: [
          {
            label: 'none',
            variable: 'signatory_ipasa_planname',
          },
          {
            label: 'none',
            variable: 'signatory_ipasa_stockclass',
          },
        ],
      },
      {
        cssClass: 'col-lg-3',
        secondary: [
          {
            format: 'number',
            label: 'left',
            variable: 'signatory_ipasa_totalshares',
          },
          {
            format: 'number',
            label: 'left',
            variable: 'signatory_ipasa_vestedshares',
          },
          {
            format: 'number',
            label: 'left',
            variable: 'signatory_ipasa_unvestedshares',
          },
          {
            format: 'currency',
            gatingVariable: 'signatory_ipasa_hasexerciseprice',
            label: 'left',
            variable: 'signatory_ipasa_exerciseprice',
          },
          {
            format: 'shortDate',
            label: 'left',
            variable: 'signatory_ipasa_grantdate',
          },
          {
            format: 'shortDate',
            gatingVariable: 'signatory_ipasa_hasexpirationdate',
            label: 'left',
            variable: 'signatory_ipasa_expirationdate',
          },
        ],
      },
      {
        cssClass: 'col-lg-6',
        secondary: [
          {
            customLayout: true,
            label: 'left',
            variable: 'signatory_ipasa_hasvestingdate',
          },
          {
            customLayout: true,
            label: 'left',
            variable: 'signatory_ipasa_grantedeip',
          },
          {
            extraSpace: true,
            gatingVariable: 'signatory_ipasa_modified',
            label: 'left',
            labelOverride: 'Modified in 2024?',
            variable: 'signatory_ipasa_modifieddetails',
          },
        ],
      },
    ],
    formLabel: 'Stock Award',
    labels: {
      add_first: 'Add one or more awards',
      add_more: 'Add another award',
    },
  },
  signatory_ipasavestednumber: {
    columns: [
      {
        cssClass: 'col-lg-4',
        primary: {
          label: 'none',
          variable: 'signatory_ipasavested_awardtype',
        },
        secondary: [
          {
            label: 'none',
            variable: 'signatory_ipasavested_stockclass',
          },
        ],
      },
      {
        cssClass: 'col-lg-4',
        secondary: [
          {
            format: 'number',
            label: 'left',
            variable: 'signatory_ipasavested_sharesacquiredonvesting',
          },
          {
            format: 'shortDate',
            label: 'left',
            variable: 'signatory_ipasavested_grantdate',
          },
          {
            format: 'shortDate',
            label: 'left',
            variable: 'signatory_ipasavested_vestingdate',
          },
        ],
      },
    ],
    formLabel: 'Stock Award',
    labels: {
      add_first: 'Add one or more awards',
      add_more: 'Add another award',
    },
  },
  signatory_nqdefcompnumber: {
    columns: [
      {
        cssClass: 'col-lg-6',
        secondary: [
          {
            format: 'currency',
            label: 'left',
            variable: 'signatory_nqdefcompexeccontributionslfy',
          },
          {
            format: 'currency',
            label: 'left',
            variable: 'signatory_nqdefcompaggearningslfy',
          },
          {
            format: 'currency',
            label: 'left',
            variable: 'signatory_nqdefcompaggdistributions',
          },
        ],
      },
      {
        cssClass: 'col-lg-6',
        secondary: [
          {
            format: 'currency',
            label: 'left',
            variable: 'signatory_nqdefcompaggbalancefye',
          },
          {
            format: 'currency',
            label: 'left',
            variable: 'signatory_nqdefcompabovemkprefearningslfy',
          },
        ],
      },
    ],
    formLabel: 'Non-Qual. Deferred Comp.',
  },
  signatory_pensionplannumber: {
    columns: [
      {
        cssClass: 'col-lg-4',
        primary: {
          label: 'none',
          variable: 'signatory_pensionplanname',
        },
      },
      {
        cssClass: 'col-lg-4',
        secondary: [
          {
            format: 'number',
            label: 'left',
            variable: 'signatory_pensionplancreditedserviceyears',
          },
          {
            format: 'currency',
            label: 'left',
            variable: 'signatory_pensionplanaccumbenefitsvalue',
          },
        ],
      },
      {
        cssClass: 'col-lg-4',
        secondary: [
          {
            format: 'currency',
            label: 'left',
            variable: 'signatory_pensionplanpymtsduringlfy',
          },
          {
            format: 'currency',
            label: 'left',
            variable: 'signatory_pensionplanchangeaccumbenefitsvalue',
          },
        ],
      },
    ],
    formLabel: 'Pension Plan',
    labels: {
      add_first: 'Add one or more pension plan',
      add_more: 'Add another pension plan',
    },
  },
  signatory_stockoptionsexercisednumber: {
    columns: [
      {
        cssClass: 'col-lg-4',
        primary: {
          label: 'none',
          variable: 'signatory_stockoptionsexercisedstockclass',
        },
      },
      {
        cssClass: 'col-lg-4',
        secondary: [
          {
            format: 'shortDate',
            label: 'left',
            variable: 'signatory_stockoptionsexercisedgrantdate',
          },
          {
            format: 'shortDate',
            label: 'left',
            variable: 'signatory_stockoptionsexercisedexercisedate',
          },
        ],
      },
      {
        cssClass: 'col-lg-4',
        secondary: [
          {
            format: 'currency',
            label: 'left',
            variable: 'signatory_stockoptionsexercisedexerciseprice',
          },
          {
            format: 'number',
            label: 'left',
            variable: 'signatory_stockoptionsexercisednosharesacquired',
          },
        ],
      },
    ],
    formLabel: 'Stock Options',
    labels: {
      add_first: 'Add one or more stock options',
      add_more: 'Add another stock option',
    },
  },
};
