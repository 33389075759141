<div class="modal-content border-0">
  <div class="modal-header border-0">
    <h5 class="modal-title">Mark questionnaire complete</h5>
    <button type="button" class="btn-close" (click)="onCancel()"></button>
  </div>
  <div class="modal-body d-flex flex-column gap-3">
    <p>
      You can manually mark this questionnaire complete if the respondent has
      opted for offline signing, or this questionnaire is no longer needed.
    </p>
    <label class="fw-bold">Upload the signed document</label>
    <input
      #fileInput
      type="file"
      class="d-none"
      accept=".doc, .docx, .xls, .xlsx, .pdf"
      (change)="onFileSelected($event)"
    />
    <div class="d-flex gap-3 align-items-center">
      <button
        class="select-file-btn btn cx-btn btn-outline-primary text-primary"
        (click)="fileInput.click()"
      >
        Select a file...
        <svg
          cdsIcon="file-storage"
          fill="currentColor"
          size="32"
          class="ms-2"
        ></svg>
      </button>
      @if (selectedFileName()) {
        <span class="text-muted">{{ selectedFileName() }}</span>
      }
    </div>

    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="skipUpload"
        [(ngModel)]="skipDocumentUpload"
      />
      <label class="form-check-label" for="skipUpload">
        Skip document upload
      </label>
    </div>
  </div>
  <div class="modal-footer row border-0">
    <div class="col">
      <button
        type="button"
        class="btn cx-btn cx-btn--ghost w-100"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
    <div class="col">
      <button
        type="button"
        [ngClass]="canComplete() ? uploadEnabledClasses : uploadDisabledClasses"
        [disabled]="!canComplete()"
        (click)="onComplete()"
      >
        @if (isSubmitting()) {
          Please wait...
          <div class="spinner-border spinner-border-sm" role="status"></div>
        } @else {
          Mark complete
          <svg cdsIcon="checkmark" fill="currentColor" size="16"></svg>
        }
      </button>
    </div>
  </div>
</div>
