<div
  *ngIf="field"
  class="row"
  [ngClass]="{
    'alert alert-info mb-0 px-0': hasChanged,
    'my-4 unchanged-field': !hasChanged,
  }"
>
  <div class="col-8 summary-details">
    <div
      class="d-flex flex-column gap-3"
      style="max-width: 80ch; white-space: pre-wrap"
    >
      @if (prompt) {
        <div appGlossaryTerm [innerHTML]="prompt | safeHtml"></div>
      }

      @if (label) {
        <div appGlossaryTerm [innerHTML]="label | safeHtml"></div>
      }

      @if (repeatPrompt) {
        <div appGlossaryTerm [innerHTML]="repeatPrompt | safeHtml"></div>
      }
    </div>
  </div>

  <div class="col-3 offset-1">
    <ng-container #fieldComponent></ng-container>
  </div>
</div>
