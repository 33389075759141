import { FieldTypeConfig } from '@ngx-formly/core';
import { DateFieldInputComponent } from 'src/app/features/questionnaire';
import { QuestionnaireVariable } from 'src/app/models/interfaces';

export function getDateFieldConfig({
  inputmethod,
}: QuestionnaireVariable): Partial<FieldTypeConfig> {
  return {
    type: DateFieldInputComponent,
    props: { inputmethod },
  };
}
