import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  Subject,
  tap,
} from 'rxjs';
import { InvalidAnswerModalComponent } from 'src/app/features/modals';
import { GlobalPageStatus, PageWithPageJs } from 'src/app/models/aliases';
import { Modal, RESPONDENT_CATEGORY_ORDER } from 'src/app/models/enums';
import {
  QuestionnaireAnswer,
  RespondentPage,
  ValidationErrorResponse,
} from 'src/app/models/interfaces';
import { ActiveProjectService, OdataBackendService } from '..';

@Injectable({
  providedIn: 'root',
})
export class ActivePageService {
  private _page = new BehaviorSubject<PageWithPageJs | null>(null);
  get page$(): Observable<PageWithPageJs | null> {
    return this._page.asObservable();
  }
  get page(): PageWithPageJs | null {
    return this._page.getValue();
  }
  set page(value: PageWithPageJs | null) {
    this._page.next(value);
  }

  get respondentPages$(): Observable<RespondentPage[]> {
    return this.page$.pipe(map((page) => page?.respondentPages || []));
  }

  private _savingAnswers = new BehaviorSubject<boolean>(false);
  get savingAnswers$() {
    return this._savingAnswers.asObservable();
  }
  get savingAnswers() {
    return this._savingAnswers.getValue();
  }
  set savingAnswers(value: boolean) {
    this._savingAnswers.next(value);
  }

  validationError$ = new Subject<string>();
  validationFix$ = new Subject<[string, string]>();

  constructor(
    private activeProjectService: ActiveProjectService,
    private modalService: BsModalService,
    private odataBackend: OdataBackendService,
  ) {}

  loadPage(pageId: string): Observable<PageWithPageJs> {
    const project = this.activeProjectService.project;
    return this.odataBackend
      .getEntity<any>('Pages', pageId, {
        expand: {
          companyInfoAnswerChanges: {
            select: ['respondentInfoAnswer', 'variableName'],
            expand: {
              respondentInfoAnswer: {
                select: ['created', 'createdByUser'],
              },
            },
            filter: {
              ...(project
                ? { projectId: { eq: { type: 'guid', value: project.id } } }
                : null),
              not: {
                respondent: {
                  questionnaireStatus: {
                    in: ['New', 'ReadyToDistribute'],
                  },
                },
              },
            },
          },
          pageJs: {},
          respondentPages: {
            expand: {
              answerChanges: {
                select: ['respondentInfoAnswer', 'variableName'],
                expand: {
                  respondentInfoAnswer: {
                    select: ['created', 'createdByUser'],
                  },
                },
                filter: {
                  ...(project
                    ? { projectId: { eq: { type: 'guid', value: project.id } } }
                    : null),
                  not: {
                    respondent: {
                      questionnaireStatus: {
                        in: ['New', 'ReadyToDistribute'],
                      },
                    },
                  },
                },
              },
              pageJs: {},
              respondent: {
                expand: ['person'],
              },
            },
            orderBy: 'respondent/person/name',
          },
        },
      })
      .pipe(
        map((page) => {
          const sortedRespondentPages = (
            page as PageWithPageJs
          ).respondentPages.sort((a, b) => {
            const categoryA = a.respondent.respondentCategory!;
            const categoryB = b.respondent.respondentCategory!;

            const indexA = RESPONDENT_CATEGORY_ORDER.indexOf(categoryA);
            const indexB = RESPONDENT_CATEGORY_ORDER.indexOf(categoryB);

            if (indexA !== indexB) {
              return indexA - indexB;
            }

            const nameA = a.respondent.person?.name?.toLowerCase() || '';
            const nameB = b.respondent.person?.name?.toLowerCase() || '';
            return nameA.localeCompare(nameB);
          });

          page = { ...page, respondentPages: sortedRespondentPages };

          this.page = page;
          return page;
        }),
      );
  }

  saveAnswers(
    pageId: string,
    answers: QuestionnaireAnswer[],
    respondentId: string | null = null,
  ): Observable<boolean> {
    return this.odataBackend
      .postEntity(`Pages/${pageId}/SaveAnswers`, { answers, respondentId })
      .pipe(
        map(() => true),
        catchError(({ status, error }: HttpErrorResponse) => {
          console.error(error);
          if (status === 400 && error.hasOwnProperty('validationErrors')) {
            this.showValidationErrors(error.validationErrors);
          }
          throw error;
        }),
      );
  }

  updatePageStatus(
    pageId: string,
    globalPageStatus: GlobalPageStatus,
  ): Observable<PageWithPageJs> {
    return this.odataBackend
      .patchEntity<PageWithPageJs>(
        'Pages',
        pageId,
        { globalPageStatus },
        { expand: ['pageJs'] },
      )
      .pipe(tap((page) => (this.page = page)));
  }

  updateRespondentPageStatus(
    pageId: string,
    respondentPageStatus: GlobalPageStatus,
    isManualCompletion?: boolean,
  ): Observable<RespondentPage> {
    return this.odataBackend.patchEntity<RespondentPage>(
      'RespondentPages',
      pageId,
      { isManualCompletion, respondentPageStatus },
    );
  }

  private showValidationErrors(errors: ValidationErrorResponse[]): void {
    const [error] = errors;
    const { question, sanitizedAnswer } = error;
    this.validationError$.next(question);
    this.modalService.show(InvalidAnswerModalComponent, {
      id: Modal.InvalidAnswer,
      initialState: {
        question,
        sanitizedAnswer,
      },
    });
  }
}
