<div class="modal-header">
  <h5 class="modal-title">{{ modalTitle }}</h5>
  <button class="btn-close" type="button" (click)="closeModal()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body pb-2">
  <formly-form
    [fields]="fields"
    [form]="form"
    [model]="model"
    [options]="options"
  ></formly-form>
</div>
<div class="modal-footer">
  <div class="row gx-2 w-100">
    <div class="col">
      <button
        class="btn cx-btn cx-btn--ghost w-100"
        type="button"
        (click)="closeModal()"
      >
        Cancel
      </button>
    </div>
    <div class="col">
      <button
        class="btn btn-primary cx-btn w-100"
        type="button"
        (click)="save()"
      >
        Save
        <svg cdsIcon="save" fill="currentColor" size="16"></svg>
      </button>
    </div>
  </div>
</div>
