import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterComponent } from 'src/app/core/components/footer/footer.component';
import { IconsModule } from 'src/app/core/icons.module';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  standalone: true,
  imports: [CommonModule, FooterComponent, IconsModule, RouterModule],
})
export class PageNotFoundComponent {}
