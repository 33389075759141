import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormControlStatus, FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Subject, auditTime, startWith, takeUntil } from 'rxjs';
import {
  ActivePageService,
  QuestionnaireService,
  REPEAT_GROUP_SUFFIX,
} from 'src/app/core/services';
import {
  QuestionnaireFormAnswer,
  QuestionnaireFormModel,
} from 'src/app/models/aliases';
import { PageProgress, QuestionnaireAnswer } from 'src/app/models/interfaces';

@Component({
  selector: 'app-questionnaire-page',
  templateUrl: './questionnaire-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnairePageComponent
  implements OnInit, OnChanges, OnDestroy
{
  title = input<string>('');
  showTitlePrefix = input<boolean>(false);
  showErrors = toSignal(this.questionnaireService.showErrors$, {
    requireSync: true,
  });

  @Input() fields: FormlyFieldConfig[] = [];
  @Input() guidance? = '';
  @Input() model: QuestionnaireFormModel = {};
  @Input() subtitle: string | null = '';
  @Input() category: string | null = '';
  @Output() progressChanges = new EventEmitter<PageProgress>();
  @Output() statusChanges = new EventEmitter<FormControlStatus>();
  @Output() touchedChanges = new EventEmitter<boolean>();
  @Output() valueChanges = new EventEmitter<QuestionnaireAnswer[]>();

  destroyed$ = new Subject<boolean>();
  form = new FormGroup({});
  options: FormlyFormOptions = {
    showError: (field) => field.formControl.invalid && this.showErrors(),
  };

  constructor(
    private activePageService: ActivePageService,
    private questionnaireService: QuestionnaireService,
  ) {}

  ngOnInit(): void {
    this.listenToStatusChanges();
    this.listenToValueChanges();
    this.listenToValidationErrors();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fields']) {
      // Ensure the form gets cleared from obsolete controls.
      Object.keys(this.form.controls).forEach((field: string) =>
        this.form.removeControl(field, { emitEvent: false }),
      );
      // If fields params change, mark the form as pristine
      // in order to avoid extra value changes emmissions.
      this.form.markAsPristine();
      this.form.markAsUntouched();
      this.form.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private getAnswerLabel(
    config: FormlyFieldConfig,
    value: QuestionnaireFormAnswer,
  ): string | undefined {
    if (Array.isArray(config.props?.options) && typeof value === 'boolean') {
      return value
        ? config.props.options[0].label
        : config.props.options[1].label;
    }

    return undefined;
  }

  private getAnswerMetadata(
    question: string,
    answer: QuestionnaireFormAnswer,
  ): QuestionnaireAnswer[] {
    const answers: QuestionnaireAnswer[] = [];

    const metadata: Omit<QuestionnaireAnswer, 'answer'> = {
      answerLabel: undefined,
      question,
      questionPrompt: '',
    };

    const fieldConfig = this.getFieldConfig(question);
    if (fieldConfig) {
      metadata.answerLabel = this.getAnswerLabel(fieldConfig, answer);
      metadata.questionPrompt = this.getQuestionPrompt(fieldConfig);
    }

    if (Array.isArray(answer)) {
      const repeatGroupGuidance =
        fieldConfig?.props && fieldConfig.props['groupGuidance'];
      const repeatGroupTitle =
        (fieldConfig?.props && fieldConfig.props['groupTitle']) ||
        repeatGroupGuidance;
      const repeatTitles: string[] =
        (fieldConfig?.fieldArray as any)?.fieldGroup?.length === 1
          ? (fieldConfig?.props && fieldConfig.props['repeatTitles']) || []
          : [];
      answer.forEach((group, index) => {
        const repeatContext = index + 1;
        const repeatTitle = repeatTitles[index];
        if (group === null) {
          answers.push({
            ...metadata,
            answer: '',
            known: false,
            repeatContext,
          });
        } else if (typeof group === 'string') {
          // It's an array of string - multivalued field.
          answers.push({
            ...metadata,
            answer: group,
            repeatContext,
          });
        } else {
          Object.entries(group || {}).forEach(([subQuestion, subAnswer]) => {
            const subFieldConfig = (
              fieldConfig?.fieldArray as any
            )?.fieldGroup?.find((config: any) => config.key === subQuestion);
            const answerLabel = this.getAnswerLabel(subFieldConfig, subAnswer);
            const prompt = this.getQuestionPrompt(subFieldConfig);
            const questionPrompt = repeatTitle
              ? `${prompt}\n${repeatTitle}`
              : prompt;
            answers.push({
              answer: subAnswer?.toString(),
              answerLabel,
              question: subQuestion,
              questionPrompt,
              repeatContext,
              repeatGroupName: question,
              repeatGroupTitle,
            });
          });
        }
      });
      if (question.includes(REPEAT_GROUP_SUFFIX)) {
        // Update corresponding repeater group counter that CE requires.
        answers.push({
          question: question.replace(REPEAT_GROUP_SUFFIX, ''),
          questionPrompt: repeatGroupTitle ? `${repeatGroupTitle} rows` : '',
          answer: answer.length.toString(),
          repeatGroupTitle,
        });
      } else if (answer.length === 0) {
        // If it's not a repeat group and an empty array,
        // set it's value to empty string.
        answers.push({
          ...metadata,
          answer: '',
          known: true,
        });
      }
    } else {
      const known = answer === null ? false : undefined;
      answers.push({
        ...metadata,
        answer: answer?.toString(),
        known,
      });
    }

    return answers;
  }

  private getQuestionPrompt(config: FormlyFieldConfig): string {
    const { props, type } = config;
    if (type === 'checkbox' && props) {
      return `${props['prompt']}\n${props.label}`;
    }

    return props?.label || '';
  }

  private getFieldConfig(key: string): FormlyFieldConfig | null {
    for (const field of this.fields) {
      const config = field.fieldGroup?.find(
        (fieldConfig) => fieldConfig.key === key,
      );
      if (config) {
        return config;
      }
    }

    return null;
  }

  private listenToStatusChanges(): void {
    this.form.statusChanges
      .pipe(
        takeUntil(this.destroyed$),
        startWith(this.form.status),
        auditTime(1000),
      )
      .subscribe((status) => this.statusChanges.emit(status));
  }

  private listenToValueChanges(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const entries = Object.entries<FormControl>(this.form.controls);

      // Detect changed (dirty) controls and emit their values.
      const changes = entries.reduce<QuestionnaireAnswer[]>(
        (answers, [key, control]) =>
          control.dirty
            ? answers.concat(this.getAnswerMetadata(key, control.getRawValue()))
            : answers,
        [],
      );
      this.valueChanges.emit(changes);

      // Recalculate progress (complete vs. total controls).
      const complete = entries.filter(([_, control]) => control.valid).length;
      const total = entries.filter(([_, control]) => control.enabled).length;
      this.progressChanges.emit({ complete, total });

      // Check if any controls were touched and emit that value.
      setTimeout(
        () =>
          this.touchedChanges.emit(
            Object.values<FormControl>(this.form.controls).some(
              (control) => control.touched,
            ),
          ),
        0,
      );

      setTimeout(() => this.form.markAsPristine(), 0);
    });
  }

  private listenToValidationErrors(): void {
    this.activePageService.validationError$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((field) => this.form.get(field)?.setValue(''));

    this.activePageService.validationFix$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([field, value]) => {
        this.form.get(field)?.markAsDirty();
        this.form.get(field)?.setValue(value);
      });
  }
}
