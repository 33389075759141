<ul class="navbar-nav ms-md-auto">
  @if (displaySupportIcon) {
    <li class="nav-item">
      @if (intercomEnabled) {
        <button aria-label="Help" class="btn nav-link" (click)="openIntercom()">
          <svg
            ibmIconHelp=""
            fill="currentColor"
            size="24"
            ng-reflect-size="24"
            xmlns="http://www.w3.org/2000/svg"
            focusable="false"
            preserveAspectRatio="xMidYMid meet"
            aria-hidden="true"
            width="24"
            height="24"
            viewBox="0 0 32 32"
          >
            <path
              d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z"
            ></path>
            <circle cx="16" cy="23.5" r="1.5"></circle>
            <path
              d="M17,8H15.5A4.49,4.49,0,0,0,11,12.5V13h2v-.5A2.5,2.5,0,0,1,15.5,10H17a2.5,2.5,0,0,1,0,5H15v4.5h2V17a4.5,4.5,0,0,0,0-9Z"
            ></path>
          </svg>
        </button>
      } @else {
        <a
          aria-label="Help"
          class="nav-link"
          href="https://connect.cooley.com/support"
          target="_blank"
          title="Support site"
        >
          <svg
            ibmIconHelp=""
            fill="currentColor"
            size="24"
            ng-reflect-size="24"
            xmlns="http://www.w3.org/2000/svg"
            focusable="false"
            preserveAspectRatio="xMidYMid meet"
            aria-hidden="true"
            width="24"
            height="24"
            viewBox="0 0 32 32"
          >
            <path
              d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z"
            ></path>
            <circle cx="16" cy="23.5" r="1.5"></circle>
            <path
              d="M17,8H15.5A4.49,4.49,0,0,0,11,12.5V13h2v-.5A2.5,2.5,0,0,1,15.5,10H17a2.5,2.5,0,0,1,0,5H15v4.5h2V17a4.5,4.5,0,0,0,0-9Z"
            ></path>
          </svg>
        </a>
      }
    </li>
  }
</ul>
