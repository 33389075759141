import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, take, tap } from 'rxjs';
import { Roles } from 'src/app/models/enums';
import { Role } from 'src/app/models/interfaces';
import { OdataBackendService } from '../odata-backend/odata-backend.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private _roles$ = new BehaviorSubject<Role[]>([]);
  get roles$(): Observable<Role[]> {
    return this._roles$.asObservable();
  }
  set roles(value: Role[]) {
    this._roles$.next(value);
  }

  get clientAdminRoleId$(): Observable<string | undefined> {
    return this.roles$.pipe(
      map((roles) => roles.find((role) => this.isClientAdmin(role))?.id),
    );
  }

  get clientCollaboratorRoleId$(): Observable<string | undefined> {
    return this.roles$.pipe(
      map((roles) => roles.find((role) => this.isClientCollaborator(role))?.id),
    );
  }

  get cooleyUserRoleId(): string | undefined {
    return this._roles$.value.find((role) => this.isCooleyUser(role))?.id;
  }

  constructor(private odataBackendService: OdataBackendService) {}

  fetchRoles(): Observable<Role[]> {
    return this.odataBackendService.getEntitySet<Role>('Roles').pipe(
      take(1),
      tap((roles) => (this.roles = roles)),
    );
  }

  private isClientAdmin({ roleName }: Role): boolean {
    return roleName === Roles.ClientAdmin;
  }

  private isClientCollaborator({ roleName }: Role): boolean {
    return roleName === Roles.ClientCollaborator;
  }

  private isCooleyUser({ roleName }: Role): boolean {
    return roleName === Roles.CooleyUser;
  }
}
