import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BsModalService } from 'ngx-bootstrap/modal';
import { map, take } from 'rxjs';
import { clearAccessToken } from 'src/app/core/auth/auth.helpers';
import {
  ActiveProjectUserRoleService,
  CurrentUserService,
} from 'src/app/core/services';
import { ProjectSettingsModalComponent } from 'src/app/features/modals';
import { Modal, Roles } from 'src/app/models/enums';
import { templateUserProfileServiceService } from '../_services/template-user-profile-service.service';

@Component({
  selector: 'app-template-header-user',
  templateUrl: './template-header-user.component.html',
  styleUrls: ['./template-header-user.component.css'],
})
export class TemplateHeaderUserComponent {
  currentUserName$ = this.userProfileService.currentUserName$;
  isAppAdmin$ = this.currentUserService.isAppAdmin$;
  showProjectSettings$ = this.activeProjectUserRoleService.roles$.pipe(
    map((roles) =>
      roles.some((role) =>
        [Roles.CooleyUser, Roles.ClientAdmin].includes(role),
      ),
    ),
  );

  constructor(
    private authService: MsalService,
    private activeProjectUserRoleService: ActiveProjectUserRoleService,
    private bsModalService: BsModalService,
    private currentUserService: CurrentUserService,
    private userProfileService: templateUserProfileServiceService,
  ) {}

  logout() {
    // If a user is authenticated with a magic link, clear its toekn.
    clearAccessToken();

    // If a user is authenticated with MSAL, call logout.
    this.authService.logout().pipe(take(1)).subscribe();
  }

  openSettings(): void {
    this.bsModalService.show(ProjectSettingsModalComponent, {
      class: 'modal-dialog-centered',
      id: Modal.ProjectSettings,
    });
  }
}
