import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {
  private _showErrors = new BehaviorSubject<boolean>(false);
  get showErrors$(): Observable<boolean> {
    return this._showErrors.asObservable();
  }
  get showErrors(): boolean {
    return this._showErrors.getValue();
  }
  set showErrors(value: boolean) {
    this._showErrors.next(value);
  }

  constructor() {}
}
