<div class="modal-header border-0 p-3">
  <h5>Reset questionnaire</h5>
  <button
    aria-label="Close"
    class="btn-close"
    (click)="closeModal()"
    type="button"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body d-flex flex-column gap-4 p-3" [formGroup]="form">
  <div *ngIf="failed$ | async" class="alert alert-danger m-0">
    Something went wrong. Please try again or contact your system administrator.
  </div>

  <div>
    This action will clear any existing Company Info answers, list of
    Respondents and non-respondents, and Respondent Info answers. The users
    listed under Cooley and Company teams will remain.
  </div>

  @if (projects$ | async; as projects) {
    @if (projects.length) {
      <div>
        You can select an existing data set, if available, to populate the
        questionnaire after the reset.
      </div>

      <div class="form-group">
        <label class="form-label">Import existing data set</label>
        <select class="form-select" formControlName="projectId">
          <option value="">-</option>
          @for (project of projects; track project.id) {
            <option [value]="project.id">
              {{ project.projectName }} &mdash; Created
              {{ project.created | date }}
            </option>
          }
        </select>
      </div>
    }
  }

  <div class="alert alert-danger mb-0 text-dark" role="alert">
    <svg cdsIcon="warning--hex--filled" fill="currentColor" size="16"></svg>
    <span class="fw-bold px-3">Warning</span>
    This action cannot be undone.
  </div>

  <div class="form-group">
    To proceed with this reset, enter “Confirm and reset” into the text input
    below.
    <input class="form-control mt-3" formControlName="confirm" type="text" />
  </div>
</div>
<div class="row g-2 p-3">
  <div class="col">
    <button
      class="btn cx-btn cx-btn--ghost w-100"
      type="button"
      (click)="closeModal()"
    >
      Cancel
    </button>
  </div>
  <div class="col">
    @if (submitting$ | async) {
      <button
        class="btn btn-primary justify-content-between w-100"
        disabled
        type="button"
      >
        Please wait...
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </button>
    } @else {
      <button
        class="btn btn-primary cx-btn w-100"
        [disabled]="form.invalid"
        type="button"
        (click)="submit()"
      >
        Confirm and reset
        <svg cdsIcon="undo" fill="currentColor" size="16"></svg>
      </button>
    }
  </div>
</div>
