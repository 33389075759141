import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import {
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  b2cPolicies,
  loginRequest,
  msalConfig,
  protectedResources,
} from './auth-config';
import { getActiveAccount } from './auth.helpers';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(protectedResources.api.endpoint, [
    ...protectedResources.api.scopes.read,
  ]);
  protectedResourceMap.set(protectedResources.collaborativeContracts.endpoint, [
    ...protectedResources.collaborativeContracts.scopes.read,
  ]);
  protectedResourceMap.set(protectedResources.events.endpoint, [
    ...protectedResources.events.scopes.read,
  ]);
  protectedResourceMap.set(protectedResources.respondentContracts.endpoint, [
    ...protectedResources.respondentContracts.scopes.read,
  ]);
  protectedResourceMap.set(protectedResources.fileUpload.endpoint, [
    ...protectedResources.fileUpload.scopes.read,
  ]);

  return {
    authRequest: (msalService, req, originalAuthRequest) => {
      // If there is already an active account, we should use
      // the authority from that account, instead of a default one.
      const account = getActiveAccount(msalService.instance);

      if (!account) {
        return originalAuthRequest;
      }

      let authority = b2cPolicies.authorities.signUpSignIn.authority;
      const tfp = account?.idTokenClaims ? account?.idTokenClaims['tfp'] : '';

      if (tfp === b2cPolicies.names.signInLocal) {
        authority = b2cPolicies.authorities.signInLocal.authority;
      } else if (tfp === b2cPolicies.names.setPassword) {
        authority = b2cPolicies.authorities.setPassword.authority;
      }
      return { ...originalAuthRequest, authority };
    },
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
  };
}
