<div class="mb-3">
  @for (record of formControl.value; track record; let i = $index) {
    <div
      class="card position-relative"
      [ngClass]="
        (showError$ | async) && !isRowValid(i)
          ? 'border border-danger missing-answer'
          : 'border-0'
      "
      style="margin-top: 2px"
    >
      @if (cardConfig?.customLayout) {
        <div
          class="card-body"
          style="height: 3rem"
          type="button"
          (click)="openRepeatForm(i)"
        >
          <div class="row gy-2">
            <div class="col-4">
              <div class="h6">
                {{ record["signatory_directorshipcompany"] }}
                {{ record["signatory_executiveofficercompany"] }}
              </div>
            </div>
            <div class="col">
              @if (record["signatory_directorshipappointmentdate"]) {
                {{ record["signatory_directorshipappointmentdate"] }} -
                {{
                  record["signatory_directorshipcurrent"]
                    ? "Current"
                    : record["signatory_directorshipresignationdate"]
                }}
              }
              {{ record["signatory_executiveofficercompanytitle"] }}
              @if (
                hasField("signatory_directorshipcompetitor") ||
                hasField("signatory_executiveofficercompetitor")
              ) {
                &bull; Competitor:
                <ng-container
                  *ngTemplateOutlet="
                    display;
                    context: {
                      format: 'boolean',
                      value:
                        record['signatory_directorshipcompetitor'] ||
                        record['signatory_executiveofficercompetitor'],
                    }
                  "
                ></ng-container>
              }
            </div>
          </div>
        </div>
        <div class="position-absolute top-0 end-0 bg-white">
          <button
            class="btn cx-btn cx-btn--ghost cx-btn--icon text-danger"
            type="button"
            (click)="removeGroup(i)"
          >
            <svg cdsIcon="trash-can" fill="currentColor" size="16"></svg>
          </button>
        </div>
      } @else if (cardConfig) {
        <div class="card-body p-3">
          <div class="mb-3">{{ i + 1 }} of {{ formControl.value.length }}</div>
          <div class="row gy-2">
            @for (column of cardConfig.columns; track column) {
              <div [class]="column.cssClass">
                @if (column.primary; as field) {
                  <div class="h6 mb-2">
                    <ng-container
                      *ngTemplateOutlet="
                        display;
                        context: {
                          format: field.format,
                          suffix: field.suffix,
                          value: record[field.variable],
                        }
                      "
                    ></ng-container>
                  </div>
                }
                @if (column.secondary) {
                  <table class="table table-borderless mb-0">
                    @for (
                      field of column.secondary;
                      track field.variable;
                      let last = $last
                    ) {
                      @if (
                        field.customLayout &&
                        field.variable === "signatory_ipasa_grantedeip"
                      ) {
                        <tr>
                          <td
                            class="align-top h-auto text-muted py-2"
                            style="width: 150px"
                          >
                            {{ getFieldLabel("signatory_ipasa_grantedeip") }}
                          </td>
                          <td class="align-top h-auto py-2">
                            @if (record["signatory_ipasa_grantedeip"]) {
                              <div
                                class="d-flex flex-nowrap gap-3 justify-content-between"
                              >
                                <div>
                                  <div class="text-muted">
                                    {{
                                      getFieldLabel(
                                        "signatory_ipasa_eipthreshold"
                                      )
                                    }}
                                  </div>
                                  <ng-container
                                    *ngTemplateOutlet="
                                      display;
                                      context: {
                                        format: 'currency',
                                        value:
                                          record[
                                            'signatory_ipasa_eipthreshold'
                                          ] ?? '-',
                                      }
                                    "
                                  ></ng-container>
                                </div>
                                <div>
                                  <div class="text-muted">
                                    {{
                                      getFieldLabel("signatory_ipasa_eiptarget")
                                    }}
                                  </div>
                                  <ng-container
                                    *ngTemplateOutlet="
                                      display;
                                      context: {
                                        format: 'currency',
                                        value:
                                          record['signatory_ipasa_eiptarget'] ??
                                          '-',
                                      }
                                    "
                                  ></ng-container>
                                </div>
                                <div>
                                  <div class="text-muted">
                                    {{
                                      getFieldLabel(
                                        "signatory_ipasa_eipmaximum"
                                      )
                                    }}
                                  </div>
                                  <ng-container
                                    *ngTemplateOutlet="
                                      display;
                                      context: {
                                        format: 'currency',
                                        value:
                                          record[
                                            'signatory_ipasa_eipmaximum'
                                          ] ?? '-',
                                      }
                                    "
                                  ></ng-container>
                                </div>
                              </div>
                              @if (
                                record["signatory_ipasa_materialterms"];
                                as materialterms
                              ) {
                                <div class="mt-2">
                                  <ng-container
                                    *ngTemplateOutlet="
                                      display;
                                      context: {
                                        value: materialterms,
                                      }
                                    "
                                  ></ng-container>
                                </div>
                              }
                            } @else {
                              <div>No</div>
                            }
                          </td>
                        </tr>
                      } @else if (
                        field.customLayout &&
                        field.variable === "signatory_ipasa_hasvestingdate"
                      ) {
                        @if (record["signatory_ipasa_hasvestingdate"]) {
                          <tr>
                            <td class="align-top h-auto text-muted pb-2">
                              {{ getFieldLabel("signatory_ipasa_vestingdate") }}
                            </td>
                            <td class="align-top h-auto pb-2">
                              <div>
                                <ng-container
                                  *ngTemplateOutlet="
                                    display;
                                    context: {
                                      format: 'shortDate',
                                      suffix: field.suffix,
                                      value:
                                        record['signatory_ipasa_vestingdate'] ??
                                        '-',
                                    }
                                  "
                                ></ng-container>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td class="align-top h-auto text-muted py-2">
                              {{
                                getFieldLabel("signatory_ipasa_vestingschedule")
                              }}
                            </td>
                            <td class="align-top h-auto py-2">
                              <div>
                                <ng-container
                                  *ngTemplateOutlet="
                                    display;
                                    context: {
                                      format: field.format,
                                      suffix: field.suffix,
                                      value:
                                        record[
                                          'signatory_ipasa_vestingschedule'
                                        ] ?? '-',
                                    }
                                  "
                                ></ng-container>
                              </div>
                            </td>
                          </tr>
                        } @else {
                          <tr>
                            <td class="align-top h-auto text-muted pb-2">
                              {{ getFieldLabel("signatory_ipasa_vestingdate") }}
                            </td>
                            <td class="align-top h-auto pb-2">
                              <div>No</div>
                            </td>
                          </tr>
                        }
                      } @else {
                        <tr>
                          @if (field.label === "left") {
                            <td
                              class="align-top h-auto text-muted"
                              [class.pb-2]="!last"
                              [class.pt-2]="field.extraSpace"
                            >
                              {{
                                field.labelOverride ||
                                  getFieldLabel(field.variable)
                              }}
                            </td>
                          }
                          <td
                            class="align-top h-auto"
                            [class.pb-2]="!last"
                            [class.pt-2]="field.extraSpace"
                            [colSpan]="field.label !== 'left' ? 2 : undefined"
                          >
                            @if (field.label === "top") {
                              <div class="mb-1 px-0 text-muted">
                                {{
                                  field.labelOverride ||
                                    getFieldLabel(field.variable)
                                }}
                              </div>
                            }
                            <div [class.px-0]="field.label !== 'left'">
                              @if (
                                !field.gatingVariable ||
                                record[field.gatingVariable]
                              ) {
                                <ng-container
                                  *ngTemplateOutlet="
                                    display;
                                    context: {
                                      format: field.format,
                                      suffix: field.suffix,
                                      value: record[field.variable] ?? '-',
                                    }
                                  "
                                ></ng-container>
                              } @else {
                                No
                              }
                            </div>
                          </td>
                        </tr>
                      }
                    }
                  </table>
                }
              </div>
            }
          </div>
        </div>
        <div class="position-absolute top-0 end-0">
          <button
            class="btn btn-primary cx-btn cx-btn--ghost ms-2"
            type="button"
            (click)="openRepeatForm(i)"
          >
            Edit
            <svg cdsIcon="edit" fill="currentColor" size="16"></svg>
          </button>
          <button
            class="btn cx-btn cx-btn--ghost cx-btn--icon text-danger"
            type="button"
            (click)="removeGroup(i)"
          >
            <svg cdsIcon="trash-can" fill="currentColor" size="16"></svg>
          </button>
        </div>
      } @else {
        <div class="card-body p-3">
          <div class="mb-3">{{ i + 1 }} of {{ formControl.value.length }}</div>
          <div class="row gy-2">
            @for (column of getFields(); track $index) {
              <div class="col-lg-6">
                <table class="table table-borderless mb-0">
                  @for (field of column; track field; let last = $last) {
                    <tr>
                      <td
                        class="align-top h-auto text-muted"
                        [class.pb-2]="!last"
                      >
                        {{ getFieldLabel(field) }}
                      </td>
                      <td class="align-top h-auto" [class.pb-2]="!last">
                        <div>
                          <ng-container
                            *ngTemplateOutlet="
                              display;
                              context: {
                                value: record[field] ?? '-',
                              }
                            "
                          ></ng-container>
                        </div>
                      </td>
                    </tr>
                  }
                </table>
              </div>
            }
          </div>
        </div>
        <div class="position-absolute top-0 end-0">
          <button
            class="btn btn-primary cx-btn cx-btn--ghost ms-2"
            type="button"
            (click)="openRepeatForm(i)"
          >
            Edit
            <svg cdsIcon="edit" fill="currentColor" size="16"></svg>
          </button>
          <button
            class="btn cx-btn cx-btn--ghost cx-btn--icon text-danger"
            type="button"
            (click)="removeGroup(i)"
          >
            <svg cdsIcon="trash-can" fill="currentColor" size="16"></svg>
          </button>
        </div>
      }
    </div>
  }

  <button
    class="btn btn-outline-primary cx-btn my-3 w-100"
    [class.w-100]="!field.fieldGroup?.length"
    [ngStyle]="field.fieldGroup?.length ? {} : { borderStyle: 'dashed' }"
    type="button"
    (click)="openRepeatForm()"
  >
    {{ field.fieldGroup?.length ? labels.add_more : labels.add_first }}
    <svg cdsIcon="add" fill="currentColor" size="16"></svg>
  </button>

  @if ((showError$ | async) && field.formControl.value.length === 0) {
    <div class="missing-answer d-flex gap-2 py-2 text-danger">
      <svg cdsIcon="warning--filled" fill="currentColor" size="16"></svg>
      This field is required
    </div>
  }
</div>

<ng-template #display let-format="format" let-suffix="suffix" let-value="value">
  @if (value === "-") {
    {{ value }}
  } @else {
    @switch (format) {
      @case ("boolean") {
        {{ value ? "Yes" : "No" }}
      }
      @case ("currency") {
        {{ value | currency: "USD" : "symbol" : "1.0-4" }} {{ suffix }}
      }
      @case ("number") {
        {{ value | number }} {{ suffix }}
      }
      @case ("mediumDate") {
        {{ value | date: "mediumDate" }}
      }
      @case ("shortDate") {
        {{ value | date: "shortDate" }}
      }
      @default {
        {{ value }} {{ suffix }}
      }
    }
  }
</ng-template>
