import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { evalExpression } from 'src/app/core/services/contract-express/contract-express.functions';
import { QuestionnaireVariable } from 'src/app/models/interfaces';

@Component({
  selector: 'app-summary-field-wrapper',
  templateUrl: './summary-field-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryFieldWrapperComponent extends FieldWrapper {
  get ceConfig(): QuestionnaireVariable | undefined {
    return this.props['ceConfig'];
  }

  get hasChanged(): boolean {
    return this.props['hasChanged'] || false;
  }

  get label(): string {
    const { inputmethod, prescribedoptions } = this.ceConfig || {};

    if (inputmethod === 'Checkbox') {
      const [option] = evalExpression<string[]>(prescribedoptions || '') || [];
      return option;
    }

    return '';
  }

  get prompt(): string {
    const { prompt, promptexpression } = this.ceConfig || {};

    if (promptexpression) {
      return (
        evalExpression<string[]>(promptexpression, this.model, this.rpt)?.join(
          '',
        ) || ''
      );
    }

    return prompt || '';
  }

  get repeatPrompt() {
    // If this radio field is part of a form array and it's parent has a "repeatTitles" prop,
    // and it's the only field in the repeat group, override its prompt atribute with a repeatTitle,
    // but not for the first one.
    const parent = this.field?.parent;
    if (
      parent &&
      parent.parent?.props &&
      parent.parent.props['repeatTitles'] &&
      (parent.parent.fieldArray as any)?.fieldGroup?.length === 1
    ) {
      const i = Number(parent.key);
      return parent.parent.props['repeatTitles'][i];
    }
    return '';
  }

  get rpt(): number {
    const parent = this.field?.parent;
    return parent?.parent ? Number(parent.key) : 0;
  }
}
