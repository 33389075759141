<div *ngIf="field" class="mb-4 position-relative">
  <div
    *ngIf="props['preamble']"
    class="mb-3"
    [innerHTML]="props['preamble'] | safeHtml"
    style="max-width: 80ch; white-space: pre-wrap"
  ></div>

  @if (label$ | async; as label) {
    <div
      appGlossaryTerm
      class="field-label mb-3"
      [class.d-none]="hideLabel"
      [innerHTML]="label | safeHtml"
      style="max-width: 80ch; white-space: pre-wrap"
    ></div>
  }

  <ng-container #fieldComponent></ng-container>

  @if ((showError$ | async) && field.formControl?.invalid) {
    <div class="missing-answer d-flex gap-2 py-2 text-danger">
      <svg cdsIcon="warning--filled" fill="currentColor" size="16"></svg>
      {{ requiredFieldLabel$ | async }}
    </div>
  }

  @if (updatedBy$ | async; as updatedBy) {
    <div
      class="alert alert-warning mt-2"
      [innerHTML]="updatedBy"
      style="max-width: 640px"
    ></div>
  }

  <div
    *ngIf="props['guidance']"
    appGlossaryTerm
    class="text-muted my-2"
    [innerHTML]="props['guidance'] | safeHtml"
    style="max-width: 80ch; white-space: pre-wrap"
  ></div>

  <details class="d-none text-success mt-2">
    <summary>Field config</summary>
    <pre class="text-wrap">{{ props["ceConfig"] | json }}</pre>
  </details>
</div>
