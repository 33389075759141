@for (subfield of field.fieldGroup; track field; let i = $index) {
  @if (!isRepeatRowHidden(subfield.fieldGroup)) {
    <div
      class="position-relative ms-n5 ps-lg-5 pt-lg-3"
      [class.border-top]="getGroupTitleParts(repeatTitles[i])"
    >
      @if (getGroupTitleParts(repeatTitles[i]); as titleParts) {
        @if (titleParts.groupNumber) {
          <h6 class="position-absolute text-muted top-0 start-0 mt-3">
            {{ titleParts.groupNumber }}
          </h6>
        }
        @if (titleParts.groupTitle) {
          <h6
            class="mb-3 ms-lg-3"
            style="max-width: 80ch"
            [innerHTML]="titleParts.groupTitle"
          ></h6>
        }
      }

      <div [class.ms-lg-3]="getGroupTitleParts(repeatTitles[i])">
        <formly-field [field]="subfield"></formly-field>
      </div>
    </div>
  }
}
