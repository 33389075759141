<div
  class="position-relative"
  [ngClass]="{
    'border-top': groupVisible && groupTitleParts?.groupNumber,
    'pt-lg-3': groupVisible && groupTitleParts?.groupNumber,
    'ps-lg-5': showParagraphNumbering,
  }"
  style="margin-bottom: 3rem"
  [ngStyle]="{
    paddingTop: groupTitleParts?.groupNumber ? '3rem' : '',
  }"
>
  @if (groupVisible) {
    @if (groupTitleParts) {
      @if (groupTitleParts.groupNumber) {
        <h6 class="position-absolute text-muted top-0 start-0 mt-3">
          {{ groupTitleParts.groupNumber }}
        </h6>
      }
      @if (groupTitleParts.groupTitle) {
        <h6
          class="mb-3 ms-lg-3"
          style="max-width: 80ch"
          [innerHTML]="groupTitleParts.groupTitle"
        ></h6>
      }
    } @else if (props.label) {
      <h6
        class="mb-3"
        [class.ms-lg-3]="showParagraphNumbering"
        style="max-width: 80ch"
        [innerHTML]="props.label"
      ></h6>
    }

    @if (guidance) {
      <div
        appGlossaryTerm
        class="text-muted mb-2 summary-details"
        [class.ms-lg-3]="showParagraphNumbering"
        [innerHTML]="guidance | safeHtml"
        style="max-width: 80ch; white-space: pre-wrap"
      ></div>
    }
  }

  <div [class.ms-lg-3]="groupVisible && showParagraphNumbering">
    <ng-container #fieldComponent></ng-container>
  </div>
</div>
