import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

interface GroupTitleParts {
  groupNumber: string;
  groupTitle: string;
}

@Component({
  selector: 'app-questionnaire-group-wrapper',
  templateUrl: './questionnaire-group-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnaireGroupWrapper extends FieldWrapper {
  get guidance(): string {
    return this.props['guidance'];
  }

  get groupTitleParts(): GroupTitleParts | null {
    if (!this.showParagraphNumbering) {
      return null;
    }

    // Regular expression to match the pattern:
    // - one or two characters ^([a-zA-Z]{1,2}) at the start of the string
    // - followed by a dot (\.)
    // - followed by a number (\d+)
    // - followed by a space (\s)
    // - followed by any text (.+)
    const regex = /^([a-zA-Z]{1,2}\.\d+)(\s(.+))?/;
    const match = this.props.label?.match(regex);

    if (match) {
      // Extract the two groups: the initial characters with dot and number, and the following text
      const groupNumber = match[1]; // initial characters with a dot and number
      const groupTitle = match[3]; // text after the number

      return { groupNumber, groupTitle };
    }

    return null;
  }

  get groupVisible(): boolean {
    return this.field.fieldGroup?.length
      ? this.field.fieldGroup?.some(
          // Previous version prior to fixing #28078
          // ({ fieldGroup, props }) => !props?.hidden || fieldGroup?.length
          // -----
          // Previous version prior to fixing #34314
          // ({ fieldGroup, props }) => !props?.hidden
          // -----
          (group) =>
            !group.props?.hidden &&
            (group.fieldGroup?.length === 0 ||
              !group.fieldGroup?.every(({ fieldGroup }) =>
                fieldGroup?.every(({ props }) => props?.hidden),
              )),
        ) ?? true
      : true;
  }

  get showParagraphNumbering(): boolean {
    return this.props['showParagraphNumbering'] || false;
  }
}
