import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { authGuard } from './core/auth/auth.guard';
import { PageNotFoundComponent } from './features/landing/page-not-found/page-not-found.component';
import { AppRoutes } from './models/enums';

const routes: Routes = [
  {
    path: AppRoutes.EMPTY,
    loadChildren: () =>
      import('./features/landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: AppRoutes.LOGIN,
    loadChildren: () =>
      import('./features/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: AppRoutes.WELCOME,
    loadChildren: () =>
      import('./features/welcome/welcome.module').then((m) => m.WelcomeModule),
    canActivate: [authGuard],
  },
  {
    path: AppRoutes.PROJECT,
    loadChildren: () =>
      import('./features/project/project.module').then((m) => m.ProjectModule),
    canActivate: [authGuard],
  },
  {
    path: AppRoutes.RESPONDENT,
    loadChildren: () =>
      import('./features/respondent/respondent.module').then(
        (m) => m.RespondentModule,
      ),
    canActivate: [authGuard],
  },
  {
    path: AppRoutes.ADMIN,
    loadChildren: () =>
      import('./features/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [authGuard],
  },
  {
    path: AppRoutes.AUTH, // Needed for handling redirect after login
    component: MsalRedirectComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

// Don't perform initial navigation in iframes or popups. Needed for MSAL
const initialNavigation =
  !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
    ? 'enabledNonBlocking'
    : 'disabled';

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false, // OPTIONAL: edit as per environment constraints. Default is true.
      initialNavigation,
      paramsInheritanceStrategy: 'always', // Required - do not remove or edit
      scrollPositionRestoration: 'enabled', // Required - do not remove or edit
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
