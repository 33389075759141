import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import {
  FlatRepeatGroupComponent,
  PlainTextInputComponent,
  QuestionnaireGroupWrapper,
  RepeatGroupComponent,
  SummaryFieldWrapperComponent,
} from 'src/app/features/questionnaire';
import { QuestionnaireFormModel } from 'src/app/models/aliases';
import { AnswerFormat } from 'src/app/models/enums';
import {
  Questionnaire,
  QuestionnaireGroup,
  QuestionnaireVariable,
} from 'src/app/models/interfaces';
import { evalExpression } from './contract-express.functions';
import { REPEAT_GROUP_SUFFIX } from './contract-express.service';

function getAnswerFormat(variable: QuestionnaireVariable): AnswerFormat {
  const { datatype, defaultformat, inputmethod } = variable;

  if (defaultformat === 'currencydigits') {
    return AnswerFormat.CURRENCY;
  } else if (datatype === 'Date' && inputmethod !== 'Calendar') {
    return AnswerFormat.YEAR_ONLY;
  }

  return AnswerFormat.DEFAULT;
}

export function getPageConfig(
  model?: QuestionnaireFormModel,
): FormlyFieldConfig[] {
  const [pageData] = (window as any).cePage || [];

  if (pageData) {
    const { activatedpage, questionnairepages } = pageData as Questionnaire;
    const questionnairePage = questionnairepages[activatedpage];
    const { questionnairegroups, usage } = questionnairePage || {};
    return Object.values(questionnairegroups || {}).map((group) => {
      const [usageKey] = Object.keys(group.usage || {}).map(Number);
      if (usage[usageKey]) {
        const [reference] = usage[usageKey].references;
        if (reference) {
          const [grouprepeat] = reference;
          group.grouprepeat = grouprepeat;
        }
        const [repeatcontext] = usage[usageKey].repeatcontext;
        const context = repeatcontext
          ? (repeatcontext(model) as number[])
          : undefined;
        if (context) {
          group.repeatcontext = Math.max(...context);
        }
      }
      return mapToFieldGroupConfig(group, model);
    });
  }

  return [];
}

function mapToFieldConfig(
  key: string,
  variable: QuestionnaireVariable,
  formModel?: QuestionnaireFormModel,
): FieldTypeConfig {
  const multivalue = variable.datatype === 'StringList';
  const defaultValue =
    variable.inputmethod === 'Checkbox'
      ? variable.prefillvalueexpression
        ? !!evalExpression(variable.prefillvalueexpression, formModel)
        : false
      : undefined;

  const useIconDisplay = variable.inputmethod === 'Checkbox';
  const format = getAnswerFormat(variable);
  const fieldConfig = {
    key,
    type: PlainTextInputComponent,
    defaultValue,
    props: {
      ceConfig: variable,
      format,
      multivalue,
      useIconDisplay,
    },
    expressions: {
      hide: ({ model }: FormlyFieldConfig) =>
        Object.values(variable.usage).some(
          (usage) =>
            !evalExpression<boolean | null>(usage, {
              ...formModel,
              ...model,
            }),
        ),
    },
    wrappers: [SummaryFieldWrapperComponent],
  } as any as FieldTypeConfig;

  return fieldConfig;
}

function mapToFieldGroupConfig(
  group: QuestionnaireGroup,
  model?: QuestionnaireFormModel,
): FormlyFieldConfig {
  const {
    questionnairevariableorder,
    questionnairevariables,
    title,
    titleexpression,
  } = group;
  const guidance = group.guidanceexpression
    ? evalExpression<string[]>(group.guidanceexpression, model)?.join('')
    : group.guidance;
  const label = titleexpression
    ? evalExpression<string[]>(titleexpression)?.join('')
    : title;
  const variables = questionnairevariableorder || [];
  const fieldGroup = variables.reduce<FormlyFieldConfig[]>(
    (groups, key, i, fields) => {
      const variable = questionnairevariables[key];
      if (variable.inputmethod === 'GroupRepeat') {
        const children = fields
          .splice(i + 1)
          .reduce<Record<string, QuestionnaireVariable>>(
            (all, field) => ({
              ...all,
              [field]: questionnairevariables[field],
            }),
            {},
          );
        groups.push(mapToRepeaterFieldArray(group, variable, children, model));
      } else if (group.repeatcontext && model) {
        // if there is a repeatcontext, prepare a new type of a repeater group.
        const children = fields
          .splice(0)
          .reduce<Record<string, QuestionnaireVariable>>(
            (all, field) => ({
              ...all,
              [field]: questionnairevariables[field],
            }),
            {},
          );
        groups.push(mapToRepeaterFlatFieldArray(group, children, model));
      } else {
        groups.push(mapToFieldConfig(key, variable, model));
      }
      return groups;
    },
    [],
  );

  return {
    fieldGroup,
    props: { guidance, label: label?.replace('_ListVerify', '') },
    wrappers: [QuestionnaireGroupWrapper],
  };
}

function mapToRepeaterFieldArray(
  { repeatcontext, repeattitleexpression }: QuestionnaireGroup,
  parent: QuestionnaireVariable,
  children: Record<string, QuestionnaireVariable>,
  formModel?: QuestionnaireFormModel,
): FormlyFieldConfig {
  const parentKey = parent.name.toLowerCase();
  const repeatRows = repeatcontext || 0;
  const fieldArrayKey = parentKey.concat(REPEAT_GROUP_SUFFIX);
  const fieldGroup = Object.entries(children).map(([key, variable]) =>
    mapToFieldConfig(key, variable, formModel),
  );
  const childrenVariables = Object.values(children);
  const [firstVariable] = childrenVariables;
  const label = firstVariable?.promptexpression
    ? evalExpression<string[]>(firstVariable.promptexpression, formModel)?.join(
        '',
      )
    : firstVariable?.prompt;
  const repeatTitles: Array<string | string[]> = [];
  if (repeattitleexpression) {
    for (let i = 1; i <= repeatRows; i++) {
      const title =
        evalExpression<string[]>(repeattitleexpression, formModel, i) || [];
      repeatTitles.push(title.join(''));
    }
  }
  const titles = childrenVariables.map(
    ({ inputmethod, prescribedoptions, prompt, promptexpression }) => {
      if (inputmethod === 'Checkbox') {
        const [title] =
          evalExpression<string[]>(prescribedoptions || '', formModel) || [];
        return title;
      }

      return promptexpression
        ? evalExpression<string[]>(promptexpression, formModel)?.join('')
        : prompt;
    },
  );

  const preTitles = childrenVariables.map(
    ({ inputmethod, prompt, promptexpression }) => {
      if (inputmethod === 'Checkbox') {
        return promptexpression
          ? evalExpression<string[]>(promptexpression, formModel)?.join('')
          : prompt;
      }

      return '';
    },
    [],
  );

  return {
    key: fieldArrayKey,
    type: RepeatGroupComponent,
    fieldArray: {
      fieldGroup,
      fieldGroupClassName: 'row',
    },
    expressions: {
      // For field arrays the model is accessible through a parent property.
      // See https://github.com/ngx-formly/ngx-formly/issues/704#issuecomment-364661614
      hide: (fieldConfig: FormlyFieldConfig) =>
        Object.values(parent.usage).some(
          (usage) =>
            !evalExpression<boolean | null>(usage, fieldConfig.parent?.model),
        ),
    },
    props: {
      label,
      layout: 'readonly',
      preTitles,
      repeatTitles,
      titles,
    },
  };
}

function mapToRepeaterFlatFieldArray(
  { grouprepeat, repeatcontext, repeattitleexpression }: QuestionnaireGroup,
  children: Record<string, QuestionnaireVariable>,
  formModel: QuestionnaireFormModel,
): FormlyFieldConfig {
  const fieldArrayKey = grouprepeat!.concat(REPEAT_GROUP_SUFFIX);
  const repeatRows = repeatcontext || 0;

  const repeatTitles: Array<string | string[]> = [];
  if (repeattitleexpression) {
    for (let i = 1; i <= repeatRows; i++) {
      const title =
        evalExpression<string[]>(repeattitleexpression, formModel, i) || [];
      repeatTitles.push(title.join(''));
    }
  }

  const fieldGroup = Object.entries(children).map(([key, variable]) =>
    mapToFieldConfig(key, variable, formModel),
  );

  // If there are more answers than there are repeat rows,
  // reduce that list to match the number of rows.
  if (fieldArrayKey && formModel?.hasOwnProperty(fieldArrayKey)) {
    const knownAnswers = formModel[fieldArrayKey] as Record<string, string>[];
    if (knownAnswers?.length > repeatRows) {
      formModel[fieldArrayKey] = knownAnswers.slice(0, repeatcontext);
    }
  }

  return {
    key: fieldArrayKey,
    type: FlatRepeatGroupComponent,
    fieldArray: { fieldGroup },
    props: { repeatTitles },
  };
}
