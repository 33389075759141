import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/** CommonUI */
import { Cx1uiModule } from '@cooley/cx1ui';
/** Template routing */
import { CommonUITemplateRoutingModule } from './_template.routing.module';
/** CommonUI: Template Required */
/** Config */
import { templateHttpInterceptorProviders } from './_http-interceptors/index';
/** Structure */
import { TemplateStartComponent } from './_template-start/template-start.component';
// Header
import { TemplateHeaderComponent } from './_template-header/template-header.component';
// Header: Menu
import { BaseTopnavMenuComponent } from '../base/base-topnav-menu/base-topnav-menu.component';
// Header: Extra
import { BaseTopnavExtraComponent } from '../base/base-topnav-extra/base-topnav-extra.component';
// Header: search
import { BaseSearchLinksComponent } from '../base/base-search-links/base-search-links.component';
import { TemplateHeaderSearchDisplaylinksComponent } from './_template-header-search-displaylinks/template-header-search-displaylinks.component';
import { TemplateHeaderSearchComponent } from './_template-header-search/template-header-search.component';
// Header: Help
import { TemplateHeaderHelpComponent } from './_template-header-help/template-header-help.component';
// Header: User display
import { TemplateHeaderUserComponent } from './_template-header-user/template-header-user.component';
// Header Secondary
import { BaseHeaderSecondaryComponent } from '../base/base-header-secondary/base-header-secondary.component';
import { TemplateHeaderSecondaryComponent } from './_template-header-secondary/template-header-secondary.component';
// Left nav
import { BaseLeftnavMenuComponent } from '../base/base-leftnav-menu/base-leftnav-menu.component';
import { TemplateLeftnavComponent } from './_template-leftnav/template-leftnav.component';
// Content
import { BaseHomeComponent } from '../base/base-home/base-home.component';
import { TemplateBodyComponent } from './_template-body/template-body.component';
// Search in body
import { BaseSearchBodyComponent } from '../base/base-search-body/base-search-body.component';
import { TemplateBodySearchComponent } from './_template-body-search/template-body-search.component';
// Required routes content
// Help in body
import { BaseHelpComponent } from '../base/base-help/base-help.component';
import { TemplateBodyHelpComponent } from './_template-body-help/template-body-help.component';
// Home
import { TemplateHomeComponent } from './_template-route-home/template-home.component';
// About
import { TemplateAboutComponent } from './_template-route-about/template-about.component';
// Theme demo
import { TemplateThemeDemoHtmlComponent } from './_template-theme-demo-html/template-theme-demo-html.component';
// Footer
import { BaseFooterAppmenuComponent } from '../base/base-footer-appmenu/base-footer-appmenu.component';
import { TemplateFooterComponent } from './_template-footer/template-footer.component';
// 404
import { IconsModule } from '../core/icons.module';
import { Template404Component } from './_template-route-404/template404.component';
// Work product import
// import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
// import { NgxMatomoRouterModule } from '@ngx-matomo/router';

@NgModule({
  declarations: [
    TemplateStartComponent,
    TemplateHeaderComponent,
    BaseTopnavMenuComponent,
    BaseTopnavExtraComponent,
    TemplateBodyComponent,
    BaseHomeComponent,
    TemplateHomeComponent,
    TemplateAboutComponent,
    BaseFooterAppmenuComponent,
    TemplateFooterComponent,
    TemplateHeaderSearchComponent,
    TemplateHeaderUserComponent,
    BaseHelpComponent,
    TemplateHeaderHelpComponent,
    BaseLeftnavMenuComponent,
    TemplateLeftnavComponent,
    TemplateHeaderSearchDisplaylinksComponent,
    TemplateBodySearchComponent,
    BaseSearchBodyComponent,
    TemplateBodyHelpComponent,
    TemplateThemeDemoHtmlComponent,
    Template404Component,
    BaseSearchLinksComponent,
    BaseHeaderSecondaryComponent,
    TemplateHeaderSecondaryComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    Cx1uiModule,
    CommonUITemplateRoutingModule,
    IconsModule,
  ],
  exports: [
    TemplateStartComponent,
    TemplateHeaderComponent,
    BaseTopnavMenuComponent,
    BaseTopnavExtraComponent,
    TemplateBodyComponent,
    TemplateHomeComponent,
    BaseHomeComponent,
    TemplateAboutComponent,
    BaseFooterAppmenuComponent,
    TemplateFooterComponent,
    TemplateHeaderSearchComponent,
    TemplateHeaderUserComponent,
    BaseHelpComponent,
    TemplateHeaderHelpComponent,
    BaseLeftnavMenuComponent,
    TemplateLeftnavComponent,
    TemplateHeaderSearchDisplaylinksComponent,
    TemplateBodySearchComponent,
    BaseSearchBodyComponent,
    TemplateBodyHelpComponent,
    TemplateThemeDemoHtmlComponent,
    Template404Component,
    BaseSearchLinksComponent,
    BaseHeaderSecondaryComponent,
    TemplateHeaderSecondaryComponent,
  ],
  providers: [templateHttpInterceptorProviders, Meta],
})
export class CommonUITemplateModule {}
