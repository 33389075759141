import { Pipe, PipeTransform } from '@angular/core';
import { AnswerFormat } from 'src/app/models/enums';

@Pipe({
  name: 'answer',
  pure: true,
  standalone: true,
})
export class AnswerPipe implements PipeTransform {
  transform(
    value: boolean | string | string[],
    multivalued = false,
    format?: AnswerFormat,
  ) {
    if (value === true) {
      return 'Yes';
    } else if (value === false) {
      return 'No';
    } else if (
      multivalued &&
      ((Array.isArray(value) && value.length === 0) || !value)
    ) {
      return 'None';
    } else if (Array.isArray(value) && value.length) {
      return `<ul><li>${value.join('</li><li>')}</li></ul>`;
    } else if (format === AnswerFormat.CURRENCY && value) {
      return Intl.NumberFormat('en-US', {
        currency: 'USD',
        minimumFractionDigits: 0,
        style: 'currency',
      }).format(Number(value));
    } else if (
      format === AnswerFormat.YEAR_ONLY &&
      value &&
      !Array.isArray(value)
    ) {
      return new Date(value).getFullYear();
    }

    return value;
  }
}
