import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-base-topnav-extra',
  templateUrl: './base-topnav-extra.component.html',
  styleUrls: ['./base-topnav-extra.component.css'],
})
export class BaseTopnavExtraComponent {
  displaySupportIcon = environment.appSpecifics.displaySupportIcon;
  intercomEnabled = environment.appCustomConfig.intercom.app_id ? true : false;

  openIntercom(): void {
    if (window.Intercom) {
      window.Intercom('show');
    }
  }
}
