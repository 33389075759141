import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, map } from 'rxjs';
import { AppRoutes } from 'src/app/models/enums';
import { getAccessToken } from './auth.helpers';

export const authGuard: CanActivateFn = () => {
  const msalBroadcastService = inject(MsalBroadcastService);
  const msalService = inject(MsalService);
  const router = inject(Router);

  // First check if a user has a magic link token.
  // If they do, they are authenticated.
  const token = getAccessToken();
  if (token) {
    return true;
  }

  // Otherwise, subscribe to MSAL broadcast.
  return msalBroadcastService.inProgress$.pipe(
    filter((status) => status === InteractionStatus.None),
    map(
      () =>
        // Check if user is authenticated (has at least one account).
        msalService.instance.getAllAccounts().length > 0
          ? true
          : router.parseUrl(AppRoutes.LOGIN), // Otherwise, redirect user to login page.
    ),
  );
};
