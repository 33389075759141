import { Component } from '@angular/core';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';

interface GroupTitleParts {
  groupNumber: string;
  groupTitle: string;
}

@Component({
  selector: 'app-flat-repeat-group',
  templateUrl: './flat-repeat-group.component.html',
})
export class FlatRepeatGroupComponent extends FieldArrayType {
  get repeatTitles(): string[] {
    return this.props['repeatTitles'] || [];
  }

  get showParagraphNumbering(): boolean {
    return (
      this.field.parent?.props &&
      this.field.parent?.props['showParagraphNumbering']
    );
  }

  getGroupTitleParts(label?: string): GroupTitleParts | null {
    if (!this.showParagraphNumbering) {
      return null;
    }

    // Regular expression to match the pattern:
    // - one or two characters ^([a-zA-Z]{1,2}) at the start of the string
    // - followed by a dot (\.)
    // - followed by a number (\d+)
    // - followed by a space (\s)
    // - followed by any text (.+)
    const regex = /^([a-zA-Z]{1,2}\.\d+)(\s(.+))?/;
    const match = label?.match(regex);

    if (match) {
      // Extract the two groups: the initial characters with dot and number, and the following text
      const groupNumber = match[1]; // initial characters with a dot and number
      const groupTitle = match[3]; // text after the number

      return { groupNumber, groupTitle };
    }

    return null;
  }

  isRepeatRowHidden(fields: FormlyFieldConfig[] = []): boolean {
    return fields?.every(
      (field) => field.hide || field.fieldGroup?.every(({ hide }) => hide),
    );
  }
}
