import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';

@Pipe({
  name: 'timeAgo',
  pure: false,
  standalone: true,
})
export class TimeAgoPipe implements PipeTransform {
  transform(date: string | null): string {
    return date
      ? formatDistanceToNow(new Date(date), { addSuffix: true })
      : '-';
  }
}
