export enum ProjectRoutes {
  PROJECT_ID = ':projectId',
  OVERVIEW = 'overview',
  COMPANY_INFO = 'company-info',
  COMPANY_INFO_PAGE = 'page/:pageId',
  COMPANY_INFO_ANSWER_SUMMARY = 'answer-summary',
  LOCKED = 'locked',
  RESPONDENTS = 'respondents',
  RESPONDENTS_SUMMARY = 'respondents/summary',
  RESPONDENT_INFO = 'respondent-info',
  RESPONDENT_INFO_PAGE = 'page/:pageId',
  RESPONDENT_INFO_RESPONDENT_PAGE = 'respondent/:respondentId',
  RESPONDENT_INFO_ANSWER_SUMMARY = 'answer-summary/respondent/:respondentId',
  COLLABORATIVE_ANSWER_SUMMARY = 'collaborative-answer-summary/respondent/:respondentId',
  SIGNATORY_ANSWER_SUMMARY = 'signatory-answer-summary/respondent/:respondentId',
  DISTRIBUTE_COLLECT = 'distribute-collect',
  CHANGE_SUMMARY = 'change-summary/respondent/:respondentId',
}
