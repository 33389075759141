import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { RespondentDocumentAvailability } from 'src/app/models/interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContractsService {
  apiUrl = environment.appLeveragedApiEndpoints.apiUrl;

  constructor(private http: HttpClient) {}

  getCollaborativeContract(respondentId: string) {
    const url = `${this.apiUrl}/CollaborativeContracts/${respondentId}`;
    return this.http.get(url, { responseType: 'blob' }).pipe(take(1));
  }

  getCrossReferenceSheet(respondentId: string) {
    const url = `${this.apiUrl}/CollaborativeContracts/DownloadCrossReferenceSheet/${respondentId}`;
    return this.http.get(url, { responseType: 'blob' }).pipe(take(1));
  }

  getRespondentContract(respondentId: string, forOnlineSigning?: boolean) {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const params = new URLSearchParams({ userTimeZoneId: timeZone });
    if (forOnlineSigning === true || forOnlineSigning === false) {
      params.set('forOnlineSigning', forOnlineSigning.toString());
    }
    const url = `${this.apiUrl}/RespondentContracts/${respondentId}?${params.toString()}`;
    return this.http.get(url, { responseType: 'blob' }).pipe(take(1));
  }

  getRespondentDocumentsAvailability(projectId: string) {
    return this.http
      .get<
        RespondentDocumentAvailability[]
      >(`${this.apiUrl}/CollaborativeContracts/RespondentDocumentsAvailability/${projectId}`)
      .pipe(take(1));
  }

  downloadSignedDocument(respondentId: string) {
    const url = `${this.apiUrl}/RespondentContracts/DownloadSignedDocument/${respondentId}`;
    return this.http.get(url, { responseType: 'blob' }).pipe(take(1));
  }

  uploadSignedDocument(respondentId: string, file: File) {
    const url = `${this.apiUrl}/RespondentContracts/UploadSignedDocument`;
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('respondentId', respondentId);
    return this.http
      .post(url, formData, { responseType: 'text' })
      .pipe(take(1));
  }
}
