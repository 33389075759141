<div class="modal-header border-0 p-3">
  <h5>Save and process changes</h5>
  <button
    aria-label="Close"
    class="btn-close"
    (click)="closeModal()"
    type="button"
  >
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body p-3">
  <div class="mb-4">
    Please confirm that you would like to save the changes to this page.
  </div>
  <div class="text-muted">
    The system will process the changes and detect affected respondent
    questionnaires. You can update the questionnaires and notify each respondent
    from the Distribute & Collect page.
  </div>
</div>
<div class="modal-footer border-0">
  <button class="btn cx-btn--ghost" type="button" (click)="closeModal()">
    Cancel
  </button>
  <button
    class="btn cx-btn cx-btn--ghost text-primary"
    type="button"
    (click)="discard(); closeModal()"
  >
    Discard changes
    <svg cdsIcon="reset" fill="currentColor" size="16"></svg>
  </button>
  <button
    class="btn btn-primary cx-btn flex-grow-1"
    type="button"
    (click)="apply(); closeModal()"
  >
    Save <svg cdsIcon="save" fill="currentColor" size="16"></svg>
  </button>
</div>
