import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyValueChangeEvent } from '@ngx-formly/core/lib/models';
import { isNil } from 'lodash';
import { debounceTime, delay, filter, tap } from 'rxjs';
import { QuestionnaireFieldWrapperComponent } from 'src/app/features/questionnaire';
import { QuestionnaireFormModel } from 'src/app/models/aliases';
import { QuestionnaireVariable } from 'src/app/models/interfaces';
import { evalExpression } from '../contract-express.functions';

export function getDefaultFieldConfig(
  key: number | string,
  variable: QuestionnaireVariable,
  required: boolean,
  formModel?: QuestionnaireFormModel,
): FieldTypeConfig {
  const guidance = variable.guidanceexpression
    ? evalExpression<string[]>(variable.guidanceexpression, formModel)?.join('')
    : variable.guidance;
  const label = variable.promptexpression
    ? evalExpression<string[]>(variable.promptexpression, formModel)?.join('')
    : variable.prompt;
  const preamble = variable.preambleexpression
    ? evalExpression<string[]>(variable.preambleexpression, formModel)?.join('')
    : variable.preamble;
  const promptexpression = variable.promptexpression;
  return {
    key,
    type: 'input',
    className: 'col',
    modelOptions: {
      updateOn: 'blur',
    },
    props: {
      ceConfig: variable,
      guidance,
      label,
      layout: variable.layout,
      preamble,
      promptexpression,
      required,
    },
    expressions: {
      hide: ({ model }: FormlyFieldConfig) =>
        Object.values(variable.usage).some(
          (usage) =>
            !evalExpression<boolean | null>(usage, {
              ...formModel,
              ...model,
            }),
        ),
    },
    hooks: {
      onInit: (fieldConfig: FormlyFieldConfig) => {
        // if variable is empty and has a prefill value expression, we need to apply it
        // either as a default value, or triggered by another value change.
        initPrefillLogic(fieldConfig, variable.prefillvalueexpression);
        return listenToFieldPropertyChanges(fieldConfig);
      },
    },
    wrappers: [QuestionnaireFieldWrapperComponent],
  } as any;
}

function initPrefillLogic(config: FormlyFieldConfig, expression?: string) {
  const { formControl, model } = config;
  const hasValue =
    formControl && (formControl.value || formControl.value === false);
  if (formControl && !hasValue && expression) {
    let defaultValue = transformPrefillToValue(
      evalExpression(expression, model),
    );
    if (!isNil(defaultValue) && defaultValue !== '') {
      formControl.markAsDirty();
      formControl.setValue(defaultValue);
    }

    formControl.root.valueChanges
      .pipe(
        debounceTime(250),
        delay(100),
        filter(() => formControl.untouched),
      )
      .subscribe(() => {
        const value = transformPrefillToValue(
          evalExpression(expression, model),
        );
        if (formControl.untouched && !isNil(value) && value !== defaultValue) {
          defaultValue = value;
          formControl.markAsDirty();
          formControl.setValue(value);
        }
      });
  }
}

// Clear field value when it becomes disabled/hidden.
function listenToFieldPropertyChanges(field: FormlyFieldConfig) {
  return field.options?.fieldChanges?.pipe(
    filter((event: FormlyValueChangeEvent) => {
      return (
        event.type === 'expressionChanges' &&
        event.field === field &&
        event['property'] === 'hide' &&
        event.value === true
      );
    }),
    tap(() => {
      const { formControl } = field;
      formControl?.markAsDirty();
      formControl?.setValue('');
    }),
  );
}

function transformPrefillToValue(prefill?: any): string {
  if (
    prefill?.hasOwnProperty('year') &&
    prefill?.hasOwnProperty('month') &&
    prefill?.hasOwnProperty('day')
  ) {
    const { year, month, day } = prefill;
    const date = [
      year,
      (month as number).toString().padStart(2, '0'),
      (day as number).toString().padStart(2, '0'),
    ];
    return date.join('-');
  } else if (Array.isArray(prefill)) {
    return prefill.join('');
  }
  return prefill;
}
