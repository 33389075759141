<div class="hide-labels mb-3">
  <div class="d-none" [class.d-xl-block]="!enableSummary">
    <table class="table table-sm">
      <tbody class="border-bottom" style="background-color: var(--bs-table-bg)">
        @if (showTableHeader) {
          <tr>
            @for (column of columns; track column.key) {
              <td
                class="align-bottom py-2"
                scope="col"
                [style.width]="column.width"
              >
                {{ column.label }}
              </td>
            }
            <td style="width: 4rem"></td>
            @if (preventColumnGrowth) {
              <td></td>
            }
          </tr>
        }
        @for (field of field.fieldGroup; track field; let i = $index) {
          <tr>
            @for (column of columns; track column.key) {
              <td
                *ngIf="getField(column.key, i) as subfield"
                class="align-top pt-3"
              >
                <formly-field [field]="subfield"></formly-field>
              </td>
            }
            <td class="align-top pt-3" style="width: 4rem">
              <button
                class="btn cx-btn cx-btn--ghost cx-btn--icon"
                type="button"
                (click)="removeGroup(i)"
              >
                <svg
                  cdsIcon="trash-can"
                  class="text-danger"
                  fill="currentColor"
                  size="16"
                ></svg>
              </button>
            </td>
            @if (preventColumnGrowth) {
              <td></td>
            }
          </tr>
        }
      </tbody>
    </table>

    <button
      class="btn btn-outline-primary cx-btn"
      [class.w-100]="!field.fieldGroup?.length"
      [ngStyle]="field.fieldGroup?.length ? {} : { borderStyle: 'dashed' }"
      type="button"
      (click)="addGroup()"
    >
      {{ field.fieldGroup?.length ? labels.add_more : labels.add_first }}
      <svg cdsIcon="add" fill="currentColor" size="16"></svg>
    </button>
  </div>

  <div [class.d-xl-none]="!enableSummary">
    @for (record of formControl.value; track record; let i = $index) {
      <div class="card position-relative" style="margin-top: 2px">
        <div class="card-body p-3">
          <div class="mb-3">{{ i + 1 }} of {{ formControl.value.length }}</div>
          @if (summary) {
            <div class="row">
              <div class="col d-flex flex-column gap-2">
                <div class="fw-bold">
                  @if (summary.primary) {
                    <ng-container
                      *ngTemplateOutlet="
                        fieldDisplay;
                        context: {
                          format: summary.primary.format,
                          suffix: summary.primary.suffix,
                          value: record[summary.primary.field],
                        }
                      "
                    ></ng-container>
                  }
                </div>
                <div>
                  @if (summary.secondary) {
                    <ng-container
                      *ngTemplateOutlet="
                        fieldDisplay;
                        context: {
                          format: summary.secondary.format,
                          suffix: summary.secondary.suffix,
                          value: record[summary.secondary.field],
                        }
                      "
                    ></ng-container>
                  }
                </div>
              </div>
              @if (summary.additional) {
                <div class="col-xl-6">
                  <hr class="d-xl-none" />
                  <ng-container
                    *ngTemplateOutlet="
                      fieldDisplay;
                      context: {
                        format: summary.additional.format,
                        suffix: summary.additional.suffix,
                        value: record[summary.additional.field],
                      }
                    "
                  ></ng-container>
                </div>
                <div class="col-xl-2"></div>
              }
            </div>
          } @else {
            @for (
              item of record | keyvalue: keepOrder;
              track item;
              let j = $index
            ) {
              @if (j < 2) {
                <div [ngClass]="{ 'small text-muted': j }">
                  {{ item.value }}
                </div>
              }
            }
          }
        </div>
        <div class="position-absolute top-0 end-0">
          <button
            class="btn btn-primary cx-btn cx-btn--ghost ms-2"
            type="button"
            (click)="openRepeatForm(i)"
          >
            Edit
            <svg cdsIcon="edit" fill="currentColor" size="16"></svg>
          </button>
          <button
            class="btn cx-btn cx-btn--ghost cx-btn--icon text-danger"
            type="button"
            (click)="removeGroup(i)"
          >
            <svg cdsIcon="trash-can" fill="currentColor" size="16"></svg>
          </button>
        </div>
      </div>
    }

    <button
      class="btn btn-outline-primary cx-btn my-3 w-100"
      [ngStyle]="field.fieldGroup?.length ? {} : { borderStyle: 'dashed' }"
      type="button"
      (click)="openRepeatForm()"
    >
      {{ field.fieldGroup?.length ? labels.add_more : labels.add_first }}
      <svg cdsIcon="add" fill="currentColor" size="16"></svg>
    </button>
  </div>

  @if ((showError$ | async) && field.formControl.value.length === 0) {
    <div class="missing-answer d-flex gap-2 py-2 text-danger">
      <svg cdsIcon="warning--filled" fill="currentColor" size="16"></svg>
      This field is required
    </div>
  }
</div>

<ng-template
  #fieldDisplay
  let-format="format"
  let-suffix="suffix"
  let-value="value"
>
  @if (value) {
    @switch (format) {
      @case ("currency") {
        <div>
          {{ value | currency: "USD" : "symbol" : "1.0-4" }} {{ suffix }}
        </div>
      }
      @case ("date") {
        <div>{{ value | date: "mediumDate" }} {{ suffix }}</div>
      }
      @case ("number") {
        <div>{{ value | number }} {{ suffix }}</div>
      }
      @default {
        <div>{{ value }} {{ suffix }}</div>
      }
    }
  }
</ng-template>
