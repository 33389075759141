import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  combineLatest,
  delayWhen,
  finalize,
  map,
  of,
  startWith,
  tap,
} from 'rxjs';
import { IconsModule } from 'src/app/core/icons.module';
import { SafeHtmlPipe } from 'src/app/core/pipes';
import {
  ActiveProjectService,
  RespondentsService,
} from 'src/app/core/services';
import { GlossaryTermDirective } from 'src/app/features/questionnaire';
import {
  DistributionStatus,
  DistributionStatusType,
  Modal,
} from 'src/app/models/enums';
import { Respondent, RespondentAnswerChange } from 'src/app/models/interfaces';

interface ButtonState {
  class: string;
  icon: string;
  label: string;
}

interface AnswerChange {
  questionPrompt?: string | null;
  previousValue: string | string[];
  newValue: string | string[];
  variableName: string;
}

interface Row {
  pageName: string | null;
  answer?: AnswerChange;
  repeatGroup?: {
    rows: Record<string, AnswerChange[]>;
    variableName: string;
  };
}

@Component({
  selector: 'app-review-changes-modal',
  standalone: true,
  imports: [
    CommonModule,
    GlossaryTermDirective,
    IconsModule,
    ReactiveFormsModule,
    SafeHtmlPipe,
  ],
  templateUrl: './review-changes-modal.component.html',
  styles: [':host { display: flex; flex-direction: column; overflow: auto; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewChangesModalComponent implements OnInit {
  @Input({ required: true }) respondent!: Respondent;
  @Output() submitted = new EventEmitter<boolean>();

  buttonState$!: Observable<ButtonState>;
  changesMap = new Map<string, Row>();
  form: FormGroup = this.fb.group({});
  failed$ = new Subject();
  hasIgnoredChanges$!: Observable<boolean>;
  ignoreCount$!: Observable<number>;
  ignoredQuestions = new Set<string>();
  loading$ = new BehaviorSubject<boolean>(true);
  status = DistributionStatus;
  submitting$ = new BehaviorSubject<boolean>(false);
  updateCount$!: Observable<number>;

  // TODO: break this out to a reusable component
  get distributionStatus(): DistributionStatusType {
    const { questionnaireStatus } = this.respondent;
    if (
      questionnaireStatus === 'New' ||
      questionnaireStatus === 'ReadyToDistribute'
    ) {
      return DistributionStatus.Ready;
    } else if (
      questionnaireStatus === 'InProgress' ||
      questionnaireStatus === 'InProgress-NotYetStarted' ||
      questionnaireStatus === 'Complete'
    ) {
      return DistributionStatus.Distributed;
    } else if (questionnaireStatus === 'CompleteSigned') {
      return DistributionStatus.Complete;
    }
    return DistributionStatus.Empty;
  }

  constructor(
    private activeProjectservice: ActiveProjectService,
    private bsModalService: BsModalService,
    private fb: FormBuilder,
    private respondentsService: RespondentsService,
  ) {}

  ngOnInit(): void {
    this.loadChanges();
    this.initState();
  }

  closeModal(): void {
    this.bsModalService.hide(Modal.ReviewChanges);
  }

  originalOrder() {
    return 0;
  }

  submit(): void {
    this.failed$.next(false);
    this.submitting$.next(true);

    // Add varaibles to the ignored questions set
    // that were specifically marked as "ignore".
    Object.entries(this.form.value).forEach(([key, decision]) => {
      if (decision === 'ignore') {
        this.ignoredQuestions.add(key);
        const change = this.changesMap.get(key);
        if (change?.repeatGroup) {
          Object.values(change.repeatGroup.rows).forEach((row) =>
            row.forEach(({ variableName }) =>
              this.ignoredQuestions.add(variableName),
            ),
          );
        }
      }
    });

    // Ensure that variables that were marked as "update"
    // are not in the ignored questions set.
    Object.entries(this.form.value).forEach(([key, decision]) => {
      if (decision === 'update' && this.ignoredQuestions.has(key)) {
        this.ignoredQuestions.delete(key);
      }
    });

    this.activeProjectservice
      .applyChanges(
        this.respondent.id,
        this.ignoredQuestions.size
          ? Array.from(this.ignoredQuestions)
          : undefined,
      )
      .pipe(
        delayWhen(() =>
          this.respondentsService.fetchProjectRespondents(
            this.respondent.projectId,
          ),
        ),
        catchError((error) => {
          console.error(error);
          this.failed$.next(true);
          return of();
        }),
        finalize(() => this.submitting$.next(false)),
      )
      .subscribe(() => {
        this.closeModal();
        this.submitted.emit(true);
      });
  }

  private initForm(): void {
    Array.from(this.changesMap.keys()).forEach((key) =>
      this.form.addControl(
        key,
        new FormControl<string | null>(null, Validators.required),
      ),
    );
  }

  private initState(): void {
    this.updateCount$ = this.form.valueChanges.pipe(
      startWith(this.form.value),
      map(
        (values) => Object.values(values).filter((v) => v === 'update').length,
      ),
    );

    this.ignoreCount$ = this.form.valueChanges.pipe(
      startWith(this.form.value),
      map(
        (values) => Object.values(values).filter((v) => v === 'ignore').length,
      ),
    );

    this.hasIgnoredChanges$ = this.ignoreCount$.pipe(map((count) => count > 0));

    this.buttonState$ = combineLatest([
      this.updateCount$,
      this.ignoreCount$,
    ]).pipe(
      map(([updateCount, ignoreCount]): ButtonState => {
        const hasOnlyIgnores = ignoreCount > 0 && updateCount === 0;
        const hasOnlyUpdates = updateCount > 0 && ignoreCount === 0;
        const hasBoth = updateCount > 0 && ignoreCount > 0;

        if (hasOnlyUpdates) {
          return {
            class: 'btn-primary',
            icon: 'checkmark',
            label: 'Apply changes and notify respondent',
          };
        }

        if (hasOnlyIgnores) {
          return {
            class: 'btn-secondary',
            icon: 'misuse--outline',
            label: 'Ignore changes',
          };
        }

        if (hasBoth) {
          return {
            class: 'btn-primary',
            icon: 'checkmark',
            label: `Apply ${updateCount} and Ignore ${ignoreCount}`,
          };
        }

        return {
          class: 'btn-primary',
          icon: 'checkmark',
          label: 'Apply changes and notify respondent',
        };
      }),
    );
  }

  private loadChanges(): void {
    this.failed$.next(false);
    this.loading$.next(true);

    this.respondentsService
      .getAnswerChanges(this.respondent.id)
      .pipe(
        tap((changes) => this.processChanges(changes)),
        finalize(() => this.loading$.next(false)),
      )
      .subscribe(() => this.initForm());
  }

  private processChanges(changes: RespondentAnswerChange[]): void {
    changes.forEach((change) => {
      if (!change.isRelevant) {
        this.ignoredQuestions.add(change.variableName);
        return;
      }

      const {
        respondentDetailsAnswer,
        respondentDetailsValue,
        respondentInfoAnswer,
        respondentInfoValue,
        variableName,
      } = change;
      if (
        respondentInfoAnswer.repeatGroupName &&
        respondentInfoAnswer.repeatContext
      ) {
        // This is a repeat group answer.
        // Group if with other answers from the same group and context.
        const {
          pageName = '',
          questionPrompt,
          repeatContext,
          repeatGroupName,
          repeatGroupTitle,
        } = respondentInfoAnswer;
        const key = repeatGroupName.replace('_repeat', '');
        const row = `${repeatGroupTitle || ''}: Row ${repeatContext}`;
        const repeatGroup: Row['repeatGroup'] = this.changesMap.get(key)
          ?.repeatGroup || {
          rows: {},
          variableName: key,
        };
        if (!repeatGroup.rows[row]) {
          repeatGroup.rows[row] = [];
        }
        repeatGroup.rows[row].push({
          questionPrompt: questionPrompt ?? '',
          previousValue:
            respondentDetailsAnswer?.answerLabel ?? respondentDetailsValue,
          newValue: respondentInfoAnswer.answerLabel ?? respondentInfoValue,
          variableName,
        });
        this.changesMap.set(key, {
          pageName,
          repeatGroup,
        });
      } else {
        const {
          pageName = '',
          questionPrompt,
          repeatContext,
        } = respondentInfoAnswer;
        const newValue =
          respondentInfoAnswer?.answerLabel ?? respondentInfoValue;
        const previousValue =
          respondentDetailsAnswer?.answerLabel ?? respondentDetailsValue;

        const answer = this.changesMap.get(variableName)?.answer || {
          newValue: repeatContext ? [] : newValue,
          previousValue: repeatContext ? [] : previousValue,
          questionPrompt,
          variableName,
        };
        if (Array.isArray(answer.newValue)) {
          answer.newValue.push(newValue);
        }
        if (Array.isArray(answer.previousValue)) {
          answer.previousValue.push(previousValue);
        }

        this.changesMap.set(variableName, { answer, pageName });
      }
    });
  }
}
