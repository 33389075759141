import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { QuestionnaireService } from 'src/app/core/services';

@Component({
  selector: 'app-director-option-holdings',
  templateUrl: './director-option-holdings.component.html',
})
export class DirectorOptionHoldingsComponent
  extends FieldArrayType
  implements OnInit
{
  editingMap = new Map<number, boolean>();
  showError$ = this.questionnaireService.showErrors$;

  constructor(private questionnaireService: QuestionnaireService) {
    super();
  }

  ngOnInit(): void {
    // If we only have one repeat row and all its values are blank,
    // prevent it from showing.
    if (this.field.fieldGroup?.length === 1) {
      const [firstRow] = this.formControl.value as Record<string, undefined>[];
      const hasValues = Object.values(firstRow).filter(Boolean).length;
      if (!hasValues) {
        this.remove(0);
      }
    }
  }

  addGroup() {
    this.add();
    const i = this.field.fieldGroup?.length || 1;
    this.editingMap.set(i - 1, true);
  }

  toggleEdit(i: number) {
    const flag = this.editingMap.get(i);
    this.editingMap.set(i, !flag);
  }

  getField(name: string, index: number) {
    if (Array.isArray(this.field.fieldGroup)) {
      const row = this.field.fieldGroup[index];
      const fieldConfig = row.fieldGroup?.find(
        ({ key }) => key === name.toLowerCase(),
      );
      return fieldConfig;
    }

    return null;
  }

  removeGroup(index: number): void {
    if (this.formControl.length === 1) {
      Object.keys(this.formControl.at(0).getRawValue()).forEach((key) => {
        this.formControl.at(0).get(key)?.setValue('', { emitEvent: false });
      });
      this.formControl.markAsDirty();
      this.formControl.updateValueAndValidity();
    }
    this.remove(index, { markAsDirty: true });
    this.formControl.updateValueAndValidity();
  }
}
