import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  delayWhen,
  map,
  Observable,
  tap,
  throwError,
} from 'rxjs';
import { Roles, UserType } from 'src/app/models/enums';
import {
  AddTeamMember,
  EditTeamMember,
  Person,
  Project,
  ProjectListItem,
  ProjectPerson,
  ProjectSummary,
  Respondent,
} from 'src/app/models/interfaces';

import {
  ActiveProjectService,
  CurrentUserService,
  OdataBackendService,
} from '..';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  constructor(
    private activeProjectService: ActiveProjectService,
    private currentUserService: CurrentUserService,
    private odataBackendService: OdataBackendService,
  ) {}

  private _myProjects = new BehaviorSubject<Project[]>([]);
  get myProjects$(): Observable<Project[]> {
    return this._myProjects.asObservable();
  }
  get myProjects(): Project[] {
    return this._myProjects.getValue();
  }
  set myProjects(value: Project[]) {
    this._myProjects.next(value);
  }

  get projectHeader$() {
    return combineLatest([
      this.myProjects$,
      this.activeProjectService.project$,
      this.currentUserService.currentUser$,
    ]).pipe(
      map(([myProjects, selectedProject, currentUser]) => {
        if (selectedProject) {
          const { client, projectName } = selectedProject;
          return {
            clientName: client?.clientName || '',
            clientNumber: client?.clientNumber,
            clientUrl: client?.url || '',
            projectName,
          };
        } else if (
          myProjects.length &&
          currentUser?.userType === UserType.external
        ) {
          const [latestProject] = myProjects;
          const { client, projectName } = latestProject;
          return {
            clientName: client?.clientName || '',
            clientNumber: client?.clientNumber,
            clientUrl: client?.url || '',
            projectName,
          };
        } else {
          return null;
        }
      }),
    );
  }

  get sameClientProjects$(): Observable<boolean> {
    return this.myProjects$.pipe(
      map((projects) =>
        projects.every(
          (project) =>
            project.client?.clientName === projects[0].client?.clientName,
        ),
      ),
    );
  }

  get projectList$(): Observable<ProjectListItem[]> {
    return combineLatest([
      this.myProjects$,
      this.currentUserService.currentUser$,
    ]).pipe(
      map(([projects, currentUser]) =>
        this.mapProjectsToProjectList(projects, currentUser),
      ),
    );
  }

  addProject(project: Partial<Project>): Observable<Project> {
    return this.odataBackendService.postEntity<Project>('Projects', project);
  }

  copyProjectData(fromProjectId: string, toProjectId: string) {
    return this.odataBackendService.postEntity<unknown>(
      'Projects/DuplicateFromProject',
      { fromProjectId, toProjectId },
    );
  }

  fetchMyProjects(userId: string): Observable<Project[]> {
    return this.odataBackendService
      .getEntitySet<Project>('Projects', {
        expand: {
          client: {},
          projectPeople: {
            expand: ['person', 'respondent', 'role'],
            orderBy: 'role/roleName asc',
          },
        },
        filter: {
          or: [
            {
              projectPeople: {
                any: {
                  or: [
                    {
                      personId: { eq: { type: 'guid', value: userId } },
                      role: {
                        roleName: Roles.Respondent,
                      },
                      respondent: {
                        questionnaireStatus: {
                          in: [
                            'InProgress-NotYetStarted',
                            'InProgress',
                            'Complete',
                            'CompleteSigned',
                          ],
                        },
                      },
                    },
                    {
                      personId: { eq: { type: 'guid', value: userId } },
                      role: {
                        roleName: {
                          in: [
                            Roles.CooleyUser,
                            Roles.ClientAdmin,
                            Roles.ClientCollaborator,
                          ],
                        },
                      },
                    },
                  ],
                },
              },
            },
            {
              delegates: {
                any: {
                  personId: { eq: { type: 'guid', value: userId } },
                  respondent: {
                    questionnaireStatus: {
                      in: [
                        'InProgress-NotYetStarted',
                        'InProgress',
                        'Complete',
                        'CompleteSigned',
                      ],
                    },
                  },
                },
              },
            },
          ],
        },
        orderBy: [['created', 'desc']],
      })
      .pipe(
        tap((projects) => (this.myProjects = projects)),
        catchError((error) => throwError(error)),
      );
  }

  getClientsCount(startDate: Date): Observable<number> {
    const userId = this.currentUserService.currentUser?.id;
    return this.odataBackendService
      .getEntitySet('Projects', {
        transform: {
          filter: {
            projectPeople: {
              any: {
                personId: { eq: { type: 'guid', value: userId } },
              },
            },
            created: { ge: startDate },
          },
          groupBy: {
            properties: ['client/clientNumber'],
            transform: {
              aggregate: {
                id: {
                  with: 'countdistinct',
                  as: 'total',
                },
              },
            },
          },
        },
      })
      .pipe(map((clients) => clients.length));
  }

  getDocumentsSignedCount(startDate: Date): Observable<number> {
    const userId = this.currentUserService.currentUser?.id;
    return this.odataBackendService.getEntitySetCount('Respondents', {
      filter: {
        questionnaireStatus: 'CompleteSigned',
        project: {
          projectPeople: {
            any: {
              personId: { eq: { type: 'guid', value: userId } },
            },
          },
        },
        modified: { ge: startDate },
      },
    });
  }

  getPagesCompletedCount(startDate: Date): Observable<number> {
    const userId = this.currentUserService.currentUser?.id;
    return this.odataBackendService
      .getEntitySet('Pages', {
        transform: {
          filter: {
            collaborator: {
              personId: { eq: { type: 'guid', value: userId } },
            },
            respondentPages: {
              all: {
                or: [
                  { cePageStatus: 'irrelevant' },
                  { respondentPageStatus: 'Complete' },
                ],
              },
            },
            modified: { ge: startDate },
          },
          groupBy: {
            properties: ['id'],
            transform: {
              aggregate: {
                id: {
                  with: 'countdistinct',
                  as: 'total',
                },
              },
            },
          },
        },
      })
      .pipe(map((pages) => pages.length));
  }

  getPagesTotalCount(startDate: Date): Observable<number> {
    const userId = this.currentUserService.currentUser?.id;
    return this.odataBackendService
      .getEntitySet('Pages', {
        transform: {
          filter: {
            collaborator: {
              personId: { eq: { type: 'guid', value: userId } },
            },
            modified: { ge: startDate },
          },
          groupBy: {
            properties: ['id'],
            transform: {
              aggregate: {
                id: {
                  with: 'countdistinct',
                  as: 'total',
                },
              },
            },
          },
        },
      })
      .pipe(map((pages) => pages.length));
  }

  getProjectChangesCount(projectId: string): Observable<number> {
    return this.odataBackendService.getEntitySetCount(
      `Projects/${projectId}/GetChanges()`,
      {
        filter: {
          isRelevant: true,
          wasPropagationIgnored: false,
          not: {
            respondent: {
              questionnaireStatus: {
                in: ['New', 'ReadyToDistribute'],
              },
            },
          },
        },
      },
    );
  }

  getProjectsCount(startDate: Date): Observable<number> {
    const userId = this.currentUserService.currentUser?.id;
    return this.odataBackendService.getEntitySetCount('Projects', {
      filter: {
        projectPeople: {
          any: {
            personId: { eq: { type: 'guid', value: userId } },
          },
        },
        created: { ge: startDate },
      },
    });
  }

  getProjectsSummary(clientNumber: string): Observable<ProjectSummary[]> {
    return this.odataBackendService.getEntitySet<ProjectSummary>(
      `Projects/Summary(clientNumber='${clientNumber}')`,
      {
        filter: {
          isTest: false,
        },
        orderBy: [['created', 'desc']],
      },
    );
  }

  hasProjectDuplicate(
    clientNumber: string,
    projectTypeId: string,
  ): Observable<ProjectSummary> {
    return this.odataBackendService
      .getEntitySet<ProjectSummary>(
        `Projects/Summary(clientNumber='${clientNumber}')`,
        {
          filter: {
            isTest: false,
            projectTypeId: { eq: { type: 'guid', value: projectTypeId } },
          },
          orderBy: [['created', 'desc']],
        },
      )
      .pipe(
        map((summary) => {
          const [mostRecentProject] = summary || [];
          return mostRecentProject;
        }),
      );
  }

  saveExistingTeamMember(
    projectId: string,
    clientTeamMember: EditTeamMember,
  ): Observable<Project> {
    return this.odataBackendService
      .postEntity<any>(`projects/${projectId}/EditTeamMember`, {
        teamMember: {
          personId: clientTeamMember.personId,
          roleId: clientTeamMember.roleId,
        },
      })
      .pipe(
        delayWhen(() => this.activeProjectService.loadActiveProject(projectId)),
        catchError((error) => throwError(error)),
      );
  }

  deleteTeamMember(
    projectId: string,
    { personId, roleId }: ProjectPerson,
  ): Observable<null> {
    return this.odataBackendService
      .postEntity<any>(`projects/${projectId}/RemoveTeamMember`, {
        teamMember: { personId, roleId },
      })
      .pipe(
        delayWhen(() => this.activeProjectService.loadActiveProject(projectId)),
        catchError((error) => throwError(error)),
      );
  }

  addTeamMember(
    projectId: string,
    teamMember: AddTeamMember,
  ): Observable<Project> {
    return this.odataBackendService
      .postEntity<any>(`projects/${projectId}/AddTeamMember`, { teamMember })
      .pipe(
        delayWhen(() => this.activeProjectService.loadActiveProject(projectId)),
        catchError((error) => throwError(error)),
      );
  }

  editTeamMember(
    projectId: string,
    teamMember: Partial<ProjectPerson>,
  ): Observable<Project> {
    return this.odataBackendService.postEntity<any>(
      `projects/${projectId}/EditTeamMember`,
      { teamMember },
    );
  }

  private mapProjectsToProjectList(projects: Project[], user: Person | null) {
    const showRole = this.hasMultipleRoles(projects, user);
    return projects.reduce<ProjectListItem[]>((listItems, project) => {
      const lastActivity =
        user?.userType === UserType.internal
          ? `Created ${format(new Date(project.created || ''), 'MMM d, yyyy')}`
          : '';

      // TODO: move this logic to odata filter.
      const projectRoles = project.projectPeople.filter(
        ({ personId, respondent, role }) =>
          personId === user?.id &&
          ((role?.roleName !== Roles.Respondent &&
            role?.roleName !== Roles.RespondentDelegate) ||
            (respondent?.questionnaireStatus !== 'New' &&
              respondent?.questionnaireStatus !== 'ReadyToDistribute')),
      );
      projectRoles.forEach(({ person, respondent, role }, index) => {
        const listItem: ProjectListItem = {
          clientUrl: project.client?.url?.trim() || '',
          clientName: project.client?.clientName || '',
          isAdditionalRole: false,
          lastActivity,
          projectId: project.id,
          projectName: project.projectName,
          projectTypeId: project.projectTypeId,
          respondentId: '',
          role: this.formatRoleName(role?.roleName),
          showRole,
          status: project.status,
        };

        if (index) {
          listItem.isAdditionalRole = true;
          listItem.respondentName =
            respondent?.respondentEntityName || person.name;
        }

        if (
          role?.roleName === Roles.Respondent ||
          role?.roleName === Roles.RespondentDelegate
        ) {
          if (
            respondent?.questionnaireStatus === 'Complete' ||
            respondent?.questionnaireStatus === 'CompleteSigned'
          ) {
            listItem.status = 'Complete';
          } else if (
            respondent?.questionnaireStatus === 'InProgress' ||
            respondent?.questionnaireStatus === 'InProgress-NotYetStarted'
          ) {
            listItem.status = 'Distributed';
          }
        }

        if (role?.roleName === Roles.Respondent) {
          listItem.respondentId = respondent?.id || '';
          listItem.respondentName =
            respondent?.respondentEntityName || person.name;
        } else if (role?.roleName === Roles.RespondentDelegate) {
          const delegateRole = user?.projectPeople.find(
            ({ projectId, role }) =>
              projectId === project.id &&
              role?.roleName === Roles.RespondentDelegate,
          );
          delegateRole?.delegates
            .filter((delegate) =>
              this.isQuestionnaireDistributed(delegate.respondent),
            )
            .forEach((delegate) => {
              listItem.respondentId = delegate.respondent?.id || '';
              listItem.respondentName =
                delegate.respondent?.respondentEntityName ||
                delegate.respondent?.person?.name;
              listItems.push({ ...listItem });
            });
          return;
        }

        listItems.push({ ...listItem });
      });

      return listItems;
    }, []);
  }

  private formatRoleName(role?: Roles): string {
    switch (role) {
      case Roles.ClientAdmin:
        return 'Admin';
      case Roles.ClientCollaborator:
        return 'Collaborator';
      case Roles.Respondent:
        return 'Respondent';
      case Roles.RespondentDelegate:
        return 'Delegate';
      default:
        return role || '';
    }
  }

  private hasMultipleRoles(projects: Project[], user: Person | null): boolean {
    const projectSet = new Set();
    for (const project of projects) {
      const projectRoles = project.projectPeople.filter(
        ({ personId, respondent, role }) =>
          personId === user?.id &&
          ((role?.roleName !== Roles.Respondent &&
            role?.roleName !== Roles.RespondentDelegate) ||
            (respondent?.questionnaireStatus !== 'New' &&
              respondent?.questionnaireStatus !== 'ReadyToDistribute')),
      );
      for (const projectRole of projectRoles) {
        if (projectSet.has(project.id)) {
          return true;
        }
        projectSet.add(projectRole.projectId);
      }
    }

    return false;
  }

  private isQuestionnaireDistributed(respondent?: Respondent): boolean {
    return (
      respondent?.questionnaireStatus === 'InProgress-NotYetStarted' ||
      respondent?.questionnaireStatus === 'InProgress' ||
      respondent?.questionnaireStatus === 'Complete' ||
      respondent?.questionnaireStatus === 'CompleteSigned'
    );
  }
}
