export const environment = {
  // BEGIN: commonUIenvConfig
  commonUIenvConfig: {
    // CommonUI specifics. Do not edit as these are modified by our update process.
    templateVersion: '1.0.0', // The version of the template being used in this solution.
    templateLibVersions: {
      // Minimum and max compatible library versions with this version of the template.
      current: {
        coreservices: 'cooley-cxservices-0.9.1.tgz',
        cx1ui: 'cooley-cxui-0.9.1.tgz',
      },
      min: {
        coreservices: 'cooley-cxservices-0.9.1.tgz',
        cx1ui: 'cooley-cxui-0.9.1.tgz',
      },
      max: {
        coreservices: 'cooley-cxservices-0.9.1.tgz',
        cx1ui: 'cooley-cxui-0.9.1.tgz',
      },
    },
    matomoURL: '//u1logpiwik01.cooley.com/', // Matomo server.
    userServiceURL: 'it.cooley.com', // User profile service endpoint
    cache: {
      containerName: 'commonuiCache', // Local storage for caching of CDN essentials.
      retainCDNItemsFor: '1', // How long in days before the cache is stale and should be updated.
    },
  },
  // END: commonUIenvConfig
  production: false,
  appSpecifics: {
    // Modify as guided.
    title: 'Connect', // REQUIRED: string: Replace the brief title here. Publicly visible.
    description:
      'Here you can find examples and guidances on our consistent user experience.', // REQUIRED: string: A brief description of what this is. Publicly visible at a minimum in the required About section.
    scope: 'internal', // REQUIRED: string: What is the target audience? Choices are "internal" or "external" with "external" implicitly including "internal".
    contact: 'Alan kelleher', // REQUIRED: string: Whom to contact if there are any questions. Publicly visible. Leave blank if Help Desk is primary.
    sponsorDept: 'Information Services', // REQUIRED: string: This should be the sponsoring department.
    sponsorName: 'Ann Moca', // OPTIONAL: string: This should be the sponsoring individual.
    matomoId: '', // OPTIONAL: string: DO NOT SELF CREATE. If you have not been provided one please contact Alan Kelleher for one.
    enableLeftMenu: true, // OPTIONAL: boolean: turns on and off the left menu.
    errorString:
      'Please contact Alan Kelleher if you have any concerns. Take a screenshot and copy the URL to facilitate troubleshooting.', // string: OPTIONAL: additional text to display on 404 pages.
    environments: {
      envShow: true, // REQUIRED: boolean: determines if environment labels should be displayed. Set to false for PROD env.
      /**
       * autoEnvs: This is a ";" separated listing of all web urls by type domain.
       * It is leveraged by the autoset process on the environment label in the header.
       * Each URL should have the protocol // domain : port without the last slash. E.G. "'http://localhost:4200"
       */
      autoEnvs: [
        { dev: 'http://localhost:4200;https://connectdev.cooley.com' },
        { uat: 'https://connectuat.cooley.com' },
        { prod: 'https://connect.cooley.com' },
      ],
    },
    homePath: '/welcome',
    searchAction: 'displayLinks' /* REQUIRED:
    Overview: Text searching in the top search box results in the search string being passed to one of three processing and display mechanisms.
    Options:
    'displayLinks' = This will display the results in a dropdown format below the search input in the header.
    'displayComponent' = This will display a customizable component that will displace teh current displayed content.
    'passThrough' = This will only pass the search string to the template search service with which you can use to filter on page components.
    */,
    searchKeepStringOnClickOut: true /* REQUIRED: boolean:
      If set to true then the text is NOT cleared out when a click outside the input is detected.
      If set to false the input field will be cleared on click out as will the value that is being used/leveraged to filter/search any dependent services or components.
    */,
    searchPlaceHolderString: 'Search...', // REQUIRED: string: What placeholder string appears in the top OTB search box. Keep it brief and under 15 characters.
    displayTopSearch: false, // REQUIRED: boolean: turns on and off the top search component
    displayTopNavMenu: false, // REQUIRED: boolean: turns on and off the top menu
    displayTopNavExtra: true, // REQUIRED: boolean: determines if the OTB component (fully customizable) is displayed.
    displayTopNavUser: true, // REQUIRED: boolean: determines if the OTB component (fully customizable) is displayed.
    // Help display in top nav
    displayTopHelp: {
      display: true, // REQUIRED: boolean: determines if the help icon is displayed in the top nav. If true, this will also display the help components contents in the About section.
      action: 'link', // REQUIRED: two possible values "email" or "link" - If email uses the next two values and will open an email on click. If "link" on click opens the base-help component.
      emailAddr: 'akelleher@cooley.com', // REQUIRED: email address for anyone who has questions to use. Either directly linked in mailto or displayed in Help component.
      emailSub: 'CommonUI: Email', // REQUIRED: Pre generated email subject text. Either directly linked in mailto or displayed in Help component.
    },
    displaySecondaryHeader: false, // REQUIRED: boolean: determines if the secondary header is displayed.
    displaySupportIcon: true,
    stylingImports: {
      // REQUIRED: boolean: The main CommonUI styling asset does not fully encompass custom styling of our approved additional libraries. If you are using any of those libraries toggle the boolean to "true" and the custom style will be automatically imported for you.
      importPrimeng: false,
      importKendoUI: false,
    },
    fonts: {
      // REQUIRED: boolean: If you are using any of these icon fonts set to true. This is an audit flag and will not import anything for you.
      IBMCarbon: false,
      FontAwesome: false,
    },
  },
  appLeveragedApiEndpoints: {
    apiUrl: 'https://api.connectuat.cooley.com',
    //apiUrl: 'https://localhost:5011/v1',
    // Any end point domains your application uses must be stored here. Do not define them in your controllers or services.
    // Rule of thumb is that these should be changeable to target different environments (so minimal roots paths are expected at least)
    // and your servcies etc. should "just" work.
  },
  appCustomConfig: {
    // Additional custom configuration options must be stored here.
    b2c: {
      apiAppRegistrationId: '11010e31-d121-43a6-84d9-778d4b2a4dfb',
      authorityDomain: 'login.connectuat.cooley.com',
      clientId: '67378269-17a8-426d-a6fa-ce9adbf319fe',
      tenant: 'pubcoengageuat.onmicrosoft.com',
    },
    intercom: {
      app_id: 'bsg9tfn0',
    },
  },
  appEntities: {
    // Cooley entities leveraged in this solution.
    // If you use any of the items specifically listed in "assets/commonui/cooleyentities" they need to be identified here. DO NOT ADD YOUR OWN.
    //
  },
};
